import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@atlaskit/button'
import modules from 'modules'
import Page from '@atlaskit/page'
import PageHeader from 'components/page-header.component'
import React, { useEffect, useState } from 'react'
import { downloadPath } from './ftp-documents.service'

const FtpDocumentsScreen: React.FunctionComponent = (): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()

  const isAdminUser = useSelector(modules.auth.selectors.selectIsAdminUser)

  const userPermissions = useSelector(
    modules.auth.selectors.selectUserPermissions,
  )

  useEffect(() => {
    if (!userPermissions?.includes('controllingViewPermission')) {
      if (isAdminUser) {
        //console.log("access ok.")
      } else {
        history.replace('')
      }
    }
  }, [isAdminUser, userPermissions])

  const isLoading = useSelector(modules.ftp.selectors.selectIsLoading)
  const list = useSelector(modules.ftp.selectors.selectList)

  console.log(isLoading)
  console.log(list)

  useEffect(() => {
    dispatch(modules.ftp.actions.ListDic('/'))
  }, [dispatch])

  const handleFileClick = (filename: string) => {
    const file = downloadPath(filename);
    console.log(file)
  }

  return (
    <Page>
      <PageHeader title="Betriebsdokumente" breadcrump="Betriebsdokumente" />
      {isLoading ? (
        <div>Daten werden geladen..</div>
      ) : 
      (
        <div>
          {list.length > 0 ? (
            list.map((item: any, index) => {
              if (item.type === 2) {
                return (
                  <div className='py-2 my-2 border-b border-gray-light' key={index}>
                    <div className=''>{item.name}</div>
                  </div>
                );
              } else {
                return (
                  <div className='py-2 my-2 border-b border-gray-light' key={index}>
                    <div className='inline-block hover:cursor-pointer' onClick={() => handleFileClick(item.name)}>{item.name}</div>
                  </div>
                );
              }
            })
          ) : (
            <div>Zu viele Verbindungen an den FTP-Server oder es sind keine Daten vorhanden.</div>
          )}
        </div>
      )
      }
    </Page>
  )
}

export default FtpDocumentsScreen
