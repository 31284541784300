import React from 'react'
import { Flex } from 'reflexbox'
import styled from 'styled-components'

import colors from 'config/colors.config'

const Container = styled.div`
  padding: 16px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  border-top: 4px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  :hover {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  }
`

const Title = styled.h4`
  color: ${colors.primary};
  margin: 0;
`

const Description = styled.span`
  margin: 8px 0;
  font-size: 13px;
`

interface Props {
  title: string
  description: string
  isDisabled?: boolean
  icon?: JSX.Element | string
  onClick: () => void
}

const ExplanationButton: React.FunctionComponent<Props> = (
  props,
): JSX.Element => {
  const { title, description, icon, onClick, isDisabled } = props

  return (
    <Container
      onClick={onClick}
      style={{
        opacity: isDisabled ? 0.4 : 1,
        border: isDisabled ? 'none' : '',
      }}
    >
      <Flex mb={3} justifyContent="space-between">
        <Title>{title}</Title>
        <span style={{color: colors.cdGreen }}>{icon}</span>
      </Flex>
      <Flex flexDirection="column" alignItems="flex-end" mb={2}>
        <Description>{description}</Description>
      </Flex>
    </Container>
  )
}

export default ExplanationButton
