import React from 'react'
import styled from 'styled-components'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { FormFooter } from '@atlaskit/form'
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import { ChecklistTypes, CreateChecklistRequestData } from './checklist.types'

import { CHECKLISTS } from 'config/checklists.config'

const FooterButton = styled(Button)`
  margin-bottom: 20px;
`

interface Props {
  checklistType: ChecklistTypes
  modalName: string
  isOpen: boolean
  onClose: () => void
  onSubmit: (checklist: CreateChecklistRequestData) => void
}

const CreateChecklistModal: React.FunctionComponent<Props> = (
  props,
): JSX.Element => {
  const { checklistType, modalName, isOpen, onClose, onSubmit } = props

  const onSubmitClick = () => {
    onSubmit({
      checklistType: checklistType,
      checklistData: CHECKLISTS[checklistType],
    })
    onClose()
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} shouldCloseOnOverlayClick={false}>
          <Form onSubmit={() => onSubmitClick()}>
            {({ formProps }) => (
              <form {...formProps}>
                <ModalHeader>
                  <ModalTitle>{modalName}</ModalTitle>
                </ModalHeader>

                <ModalBody>
                  <p>Möchten Sie eine neue Prüfung anlegen und starten?</p>

                  <FormFooter>
                    <ButtonGroup>
                      <FooterButton onClick={onClose}>Abbrechen</FooterButton>
                      <FooterButton type="submit" appearance="primary">
                        jetzt prüfen
                      </FooterButton>
                    </ButtonGroup>
                  </FormFooter>
                </ModalBody>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default CreateChecklistModal
