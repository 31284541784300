import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingButton } from '@atlaskit/button'
import TextField from '@atlaskit/textfield'
import styled from 'styled-components'
import { Flex } from 'reflexbox'

import Form, { Field, FormFooter } from '@atlaskit/form'
import Logo from 'assets/images/logo.svg'

import modules from 'modules'

interface FormProps {
  username: string
  password: string
}

const AuthWrapper = styled.div`
  width: 400px;
  margin: 0 auto;
  margin-top: 20vh;
`

const AuthScreen: React.FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch()
  const isLoading = useSelector(modules.auth.selectors.selectIsLoading)

  const authenticate = ({ username, password }: FormProps) => {
    dispatch(
      modules.auth.actions.authenticate({
        username,
        password,
      }),
    )
  }

  return (
    <AuthWrapper>
      <Flex mb={5} justifyContent="center">
        <img src={Logo} width="256px" alt="Logo" />
      </Flex>
      <Form<FormProps> onSubmit={(v) => authenticate(v)}>
        {({ formProps }) => (
          <form {...formProps}>
            <Field name="username" label="Benutzername">
              {({ fieldProps }) => <TextField {...fieldProps} />}
            </Field>

            <Field name="password" label="Passwort">
              {({ fieldProps }) => (
                <TextField type="password" {...fieldProps} />
              )}
            </Field>

            <FormFooter>
              <LoadingButton
                type="submit"
                appearance="primary"
                isLoading={isLoading}
              >
                Anmelden
              </LoadingButton>
            </FormFooter>
          </form>
        )}
      </Form>
    </AuthWrapper>
  )
}

export default AuthScreen
