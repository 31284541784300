// @ts-nocheck
import React, { Fragment, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up'
import TextField from '@atlaskit/textfield'
import { CheckboxSelect, components } from '@atlaskit/select'
import DropdownMenu, { DropdownItem } from '@atlaskit/dropdown-menu'
import CheckIcon from '@atlaskit/icon/glyph/check'
import Button, { ButtonGroup } from '@atlaskit/button'
import InlineEdit from '@atlaskit/inline-edit'
import styled from 'styled-components'
import { Flex } from 'reflexbox'
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table'

import { formatUserRole, formatDateTime } from 'utils/format.utils'
import modules from 'modules'
import { User, UserRole, Permissions } from './users.types'
import { useConfirmation } from 'components/confirmation-modal.service'

const roleOptions = [
  { label: 'Admin', value: UserRole.Admin },
  { label: 'Benutzer', value: UserRole.Employee },
]

const permissionsOptions = [
  { label: 'Wagonverladung', value: Permissions.EntrainmentViewPermission },
  { label: 'Benutzerverwaltung', value: Permissions.UsersViewPermission },
  { label: 'Fahrzeugverwaltung', value: Permissions.VehiclesViewPermission },
  { label: 'Fahrzeugprüfung', value: Permissions.VehicleViewPermission },
  { label: 'Baggerprüfung', value: Permissions.ExcavatorViewPermission },
  { label: 'Abrechnungsprüfung', value: Permissions.ControllingViewPermission },
  { label: 'Behälterverwaltung', value: Permissions.ContainersViewPermission },
  { label: 'Behälterprüfung', value: Permissions.ContainerViewPermission },
  { label: 'Prüfmittelverwaltung', value: Permissions.InstrumentsViewPermission },
]

const ReadViewContainer = styled.div`
  cursor: pointer;
`

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: {
  preGlobalFilteredRows: any
  globalFilter: any
  setGlobalFilter: any
}) => {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <TextField
      isCompact
      width={300}
      placeholder={`${count} Einträge durchsuchen`}
      elemBeforeInput={<EditorSearchIcon label="Suchen" />}
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
    />
  )
}

interface Props {
  users: User[]
  onShowDetails: (userId: string) => void
}

const Table = ({ columns, data }: { columns: any; data: any }) => {
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state,

    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 25,
        sortBy: [{ id: 'createdAt', desc: true }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const { pageIndex, globalFilter } = state

  return (
    <Fragment>
      <Flex justifyContent="flex-end">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Flex>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <Flex>
                    {column.render('Header')}

                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ChevronDownIcon label="Absteigend sortieren" />
                      ) : (
                        <ChevronUpIcon label="Aufsteigend sortieren" />
                      )
                    ) : (
                      /**
                       * Placeholder for sort icons. Was this empty or null,
                       * adding an icon would always shift the whole table
                       * due to readjustments in header width and height.
                       *
                       * By using a placeholder with the same size as the icons,
                       * we can completely get rid of this effect.
                       */
                      <div style={{ height: 24, width: 24 }}></div>
                    )}
                  </Flex>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((page: any) => {
            prepareRow(page)
            return (
              <tr {...page.getRowProps()}>
                {page.cells.map((cell: any) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <br />
      <Flex justifyContent="space-between">
        <ButtonGroup appearance="default">
          <Button onClick={() => gotoPage(0)} isDisabled={!canPreviousPage}>
            {'<<'} Erste
          </Button>
          <Button onClick={() => previousPage()} isDisabled={!canPreviousPage}>
            {'<'} Vorherige
          </Button>
          <Button onClick={() => nextPage()} isDisabled={!canNextPage}>
            Nächste {'>'}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            isDisabled={!canNextPage}
          >
            Letzte {'>>'}
          </Button>
        </ButtonGroup>
        <span>
          Seite{' '}
          <strong>
            {pageIndex + 1} von {pageOptions.length}
          </strong>{' '}
        </span>
      </Flex>
      <br />
      <br />
    </Fragment>
  )
}

const UserTable: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { users, onShowDetails } = props

  /* console.log(users) */

  const dispatch = useDispatch()
  const confirm = useConfirmation()

  const mapPermissions = (permissions) => {
    if (permissions) {
      return permissionsOptions.filter((item) =>
        permissions.includes(item.value),
      )
    }
  }

  const editUser = async (
    userId: string,
    changeField: string,
    newValue: string | UserRole,
  ) => {
    const user = users.find((user) => {
      return user._id === userId
    })

    if (newValue === user[changeField]) return
    user[changeField] = newValue

    return dispatch(modules.users.actions.editUser(user))
  }

  const editUserPermission = async (userId: string, permissions: Array) => {
    const user = users.find((user) => {
      return user._id === userId
    })

    const setPermissions = permissions.map((item: any) => item.value)

    user.permissions = setPermissions

    return dispatch(modules.users.actions.editUser(user))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Benutzername',
        Cell: ({
          row: {
            original: { userId, username },
          },
        }) => (
          <InlineEdit
            defaultValue={username}
            editView={(fieldProps) => <TextField {...fieldProps} autoFocus />}
            readView={() => <ReadViewContainer>{username}</ReadViewContainer>}
            onConfirm={(newValue) => {
              editUser(userId, 'username', newValue)
            }}
          />
        ),
        accessor: 'username',
      },
      {
        Header: 'Vorname',
        Cell: ({
          row: {
            original: { userId, firstName },
          },
        }) => (
          <InlineEdit
            defaultValue={firstName}
            editView={(fieldProps) => <TextField {...fieldProps} autoFocus />}
            readView={() => (
              <ReadViewContainer>
                {firstName ? firstName : 'n.a.'}
              </ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              editUser(userId, 'firstName', newValue)
            }}
          />
        ),
        accessor: 'firstName',
      },
      {
        Header: 'Nachname',
        Cell: ({
          row: {
            original: { userId, lastName },
          },
        }) => (
          <InlineEdit
            defaultValue={lastName}
            editView={(fieldProps) => <TextField {...fieldProps} autoFocus />}
            readView={() => (
              <ReadViewContainer>
                {lastName ? lastName : 'n.a.'}
              </ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              editUser(userId, 'lastName', newValue)
            }}
          />
        ),
        accessor: 'lastName',
      },
      {
        Header: 'Erstellungsdatum',
        accessor: 'createdAt',
        Cell: ({ value }) => value.split('###')[1],
      },
      {
        Header: 'Bearbeitet',
        accessor: 'updatedAt',
      },
      {
        Header: 'Rolle',
        Cell: ({
          row: {
            original: { userId, role },
          },
        }) => (
          <div style={{ cursor: 'pointer' }} title="Rolle bearbeiten">
            <DropdownMenu trigger={role}>
              {roleOptions.map((option) => {
                return (
                  <DropdownItem
                    elemAfter={
                      option.label === role ? (
                        <CheckIcon label="Ausgewählt" />
                      ) : null
                    }
                    onClick={() => editUser(userId, 'role', option.value)}
                  >
                    {option.label}
                  </DropdownItem>
                )
              })}
            </DropdownMenu>
          </div>
        ),
        accessor: 'role',
      },
      {
        Header: 'Zugriffsrechte',
        Cell: ({
          row: {
            original: { userId, role, permissions },
          },
        }) => (
          <div>
            {role === 'Benutzer' ? (
              <CheckboxSelect
                components={{
                  MultiValue: (props) => {
                    return (
                      <components.SingleValue {...props}>
                        <span>
                          {props.selectProps.value.length +
                            ' Recht/e ausgewählt'}
                        </span>
                      </components.SingleValue>
                    )
                  },
                }}
                defaultValue={mapPermissions(permissions)}
                options={permissionsOptions}
                placeholder="Auswählen"
                onChange={(selectedOptions) =>
                  editUserPermission(userId, selectedOptions)
                }
                isMulti
              />
            ) : null}
          </div>
        ),
        accessor: 'permissions',
      },
      {
        Header: '',
        accessor: 'password',
        Cell: ({
          row: {
            original: { userId },
          },
        }) => (
          <Flex justifyContent="flex-end">
            <Button
              appearance="link"
              onClick={() =>
                confirm({
                  title: 'Passwort Zurücksetzen?',
                  description:
                    'Das neue Passwort lautet: unsicher1 und muss aus Sicherheitsgründen ' +
                    'umgehend vom jeweiligen Benutzer in ein sicheres und nur ihm bekanntes ' +
                    'Passwort geändert werden.',
                })
                  .then(() =>
                    dispatch(
                      modules.users.actions.resetPassword(userId, 'unsicher1'),
                    ),
                  )
                  .catch(() => null)
              }
            >
              Passwort zurücksetzen
            </Button>
          </Flex>
        ),
      },
      {
        Header: '',
        accessor: 'delete',
        Cell: ({
          row: {
            original: { userId },
          },
        }) => (
          <Flex justifyContent="flex-end">
            <Button
              appearance="link"
              onClick={() =>
                confirm({
                  title: 'Benutzer löschen?',
                  description:
                    'Der Benutzer wird vollständig gelöscht und verliert somit Zugang zur ' +
                    'Applikation. Sämtliche Daten, die vom jeweiligen Benutzer erstellt ' +
                    'oder verändert wurden bleiben allerdings erhalten.',
                })
                  .then(() =>
                    dispatch(modules.users.actions.deleteUser(userId)),
                  )
                  .catch(() => null)
              }
            >
              Benutzer löschen
            </Button>
          </Flex>
        ),
      },
    ],
    [dispatch, onShowDetails],
  )

  const rows = users.map((user) => {
    const {
      _id,
      role,
      permissions,
      username,
      firstName,
      lastName,
      createdAt,
      updatedAt,
    } = user

    return {
      userId: _id,
      role: formatUserRole(role),
      permissions,
      username,
      firstName,
      lastName,
      /**
       * Search and sort depend on the accessor key. This means, we can either base
       * this on the ISO string or the formatted string, by passing either of them.
       * Here we want to search by the formatted string, but sort by the ISO string,
       * so we pass both as delimited string and display the formatted part only,
       * while still being able to sort correctly.
       */
      createdAt: `${createdAt}###${formatDateTime(createdAt)}`,
      updatedAt: formatDateTime(updatedAt),
    }
  })

  return <Table columns={columns} data={rows} />
}

export default UserTable
