import core from 'modules/core/core.redux'
import auth from 'modules/auth/auth.redux'
import users from 'modules/users/users.redux'
import entrainment from 'modules/entrainment/entrainment.redux'
import settings from 'modules/settings/settings.redux'
import contacts from 'modules/contacts/contacts.redux'
import dailyReports from 'modules/daily-reports/daily-reports.redux'
import checklist from 'modules/checklists/checklist.redux'
import vehicles from 'modules/vehicles/vehicles.redux'
import containers from 'modules/containers/containers.redux'
import instruments from 'modules/instruments/instruments.redux'
import ftp from 'modules/ftp-documents/ftp-documents.redux'

export default {
  core,
  auth,
  users,
  entrainment,
  settings,
  contacts,
  dailyReports,
  checklist,
  vehicles,
  containers,
  instruments,
  ftp,
}
