import React from 'react'
import styled from 'styled-components'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { Field, FormFooter } from '@atlaskit/form'
import Select, { ValueType } from '@atlaskit/select'
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'

import { ChecklistTypes, CreateChecklistRequestData } from './checklist.types'
import { CHECKLISTS } from 'config/checklists.config'
import { formatChecklistType } from 'utils/format.utils'

const FooterButton = styled(Button)`
  margin-bottom: 20px;
`

interface Props {
  modalName: string
  isOpen: boolean
  onClose: () => void
  onSubmit: (checklist: CreateChecklistRequestData) => void
}

interface RoleOptionType {
  label: string
  value: ChecklistTypes
}

interface FormProps {
  checklistType: RoleOptionType
}

const checklistOptions: RoleOptionType[] = [
  { label: formatChecklistType('Container'), value: ChecklistTypes.Container },
  { label: formatChecklistType('Skip'), value: ChecklistTypes.Skip },
]

const CreateContainerChecklistModal: React.FunctionComponent<Props> = (
  props,
): JSX.Element => {
  const { modalName, isOpen, onClose, onSubmit } = props

  const onSubmitClick = (fields: FormProps) => {
    const { checklistType } = fields

    onSubmit({
      checklistType: checklistType.value,
      checklistData: CHECKLISTS[checklistType.value],
    })
    onClose()
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} shouldCloseOnOverlayClick={false}>
          <Form<FormProps>  onSubmit={(v) => onSubmitClick(v)}>
            {({ formProps }) => (
              <form {...formProps}>
                <ModalHeader>
                  <ModalTitle>{modalName}</ModalTitle>
                </ModalHeader>

                <ModalBody>
                  <p>Möchten Sie eine neue Prüfung anlegen und starten?</p>

                  <Field<ValueType<RoleOptionType, false>>
                    name="checklistType"
                    label="Prüfung wählen"
                    defaultValue={checklistOptions[1]}
                  >
                    {({ fieldProps }) => (
                      <Select<RoleOptionType>
                        options={checklistOptions}
                        placeholder="Bitte auswählen"
                        isSearchable={false}
                        menuPlacement="bottom"
                        {...fieldProps}
                      />
                    )}
                  </Field>

                  <FormFooter>
                    <ButtonGroup>
                      <FooterButton onClick={onClose}>Abbrechen</FooterButton>
                      <FooterButton type="submit" appearance="primary">
                        jetzt prüfen
                      </FooterButton>
                    </ButtonGroup>
                  </FormFooter>
                </ModalBody>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default CreateContainerChecklistModal
