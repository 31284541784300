
export const CHECKLISTS = {
  Vehicle: [
    {
      blockId: 'checkblock-dguv-214-016-1',
      checkBlockDescription: 'Beleuchtung vorn',
      checks: [
        {
          checkId: 'check-dguv-214-016-1',
          checkDescription: 'Abblendlicht li/re',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-2',
          checkDescription: 'Fernlicht li/re',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-3',
          checkDescription: 'Begrenzungs-/Parkleuchten li/re',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-4',
          checkDescription: 'Nebelscheinwerfer li/re',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-5',
          checkDescription: 'Fahrtrichtungsanzeiger li/re',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-2',
      checkBlockDescription: 'Beleuchtung hinten',
      checks: [
        {
          checkId: 'check-dguv-214-016-6',
          checkDescription: 'Schlussleuchten li/re',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-7',
          checkDescription: 'Bremsleuchten li/re',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-8',
          checkDescription: 'Fahrtrichtungsanzeiger li/re',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-9',
          checkDescription: 'Rückstrahler li/re',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-10',
          checkDescription: 'Nebelschlussleuchte',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-11',
          checkDescription: 'Rückfahrscheinwerfer',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-12',
          checkDescription: 'Kennzeichenleuchte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-3',
      checkBlockDescription: 'Beleuchtung Anhänger',
      checks: [
        {
          checkId: 'check-dguv-214-016-13',
          checkDescription: 'Schlussleuchten li/re',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-14',
          checkDescription: 'Bremsleuchten li/re',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-15',
          checkDescription: 'Fahrtrichtungsanzeiger li/re',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-16',
          checkDescription: 'Rückstrahler li/re',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-17',
          checkDescription: 'Nebelschlussleuchte',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-18',
          checkDescription: 'Rückfahrscheinwerfer',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-19',
          checkDescription: 'Kennzeichenleuchte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-4',
      checkBlockDescription: 'Räder',
      checks: [
        {
          checkId: 'check-dguv-214-016-20',
          checkDescription: 'Felgen/Radschlüssel',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-21',
          checkDescription: 'Radmuttern/-bolzen',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-22',
          checkDescription: 'Reifenzustand (Schäden)',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-23',
          checkDescription: 'Reifenprofil',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-24',
          checkDescription: 'Luftdruck',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-5',
      checkBlockDescription: 'Bremsen',
      checks: [
        {
          checkId: 'check-dguv-214-016-25',
          checkDescription: 'Pedalweg/Dichtheit/Druckabfall',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-26',
          checkDescription: 'Luftbehälter-Entwässerung',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-27',
          checkDescription: 'Bremsflüssigkeitsstand',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-6',
      checkBlockDescription: 'Motor und Antrieb',
      checks: [
        {
          checkId: 'check-dguv-214-016-28',
          checkDescription: 'Ölstand Motor',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-29',
          checkDescription: 'Kühlwasserstand',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-7',
      checkBlockDescription: 'Führerhaus',
      checks: [
        {
          checkId: 'check-dguv-214-016-30',
          checkDescription: 'Außenspiegel',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-31',
          checkDescription: 'Innenspiegel',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-32',
          checkDescription: 'Scheibenwaschanlage',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-33',
          checkDescription: 'Anzeigen/Störungsmeldung',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-8',
      checkBlockDescription: 'Fahrzeugaufbau',
      checks: [
        {
          checkId: 'check-dguv-214-016-34',
          checkDescription: 'Hub-/Kippeinrichtung',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-35',
          checkDescription: 'Befehlseinrichtungen',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-36',
          checkDescription: 'Anschläge vorn',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-37',
          checkDescription: 'Anschläge seitlich',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-38',
          checkDescription: 'Fanghakensicherung',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-39',
          checkDescription: 'Aufstiege',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-40',
          checkDescription: 'Ladungssicherung',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-41',
          checkDescription: 'Hydraulik',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-9',
      checkBlockDescription: 'Anhängerbetrieb',
      checks: [
        {
          checkId: 'check-dguv-214-016-42',
          checkDescription: 'Zuggabel',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-42',
          checkDescription: 'Höheneinstelleinrichtung',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-43',
          checkDescription: 'elektrische Verbindung',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-44',
          checkDescription: 'Druckluftverbindung',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-45',
          checkDescription: 'Kupplungseinrichtung',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-10',
      checkBlockDescription: 'Zubehör',
      checks: [
        {
          checkId: 'check-dguv-214-016-46',
          checkDescription: 'Unterlegkeile',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-47',
          checkDescription: 'Hilfsmittel Ladungssicherung',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-48',
          checkDescription: 'Feuerlöscher',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-49',
          checkDescription: 'Verbandkasten',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-50',
          checkDescription: 'Warndreieck, Warnleuchte',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-51',
          checkDescription: 'Warntafeln',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-52',
          checkDescription: 'Warnkleidung',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-53',
          checkDescription: 'Pers. Schutzausrüstung',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-54',
          checkDescription: 'Betriebsanleitungen',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-55',
          checkDescription: 'Scheibenreinigung',
          faults: null,
        },
      ],
      blockComment: null
    },
    /* {
      blockId: 'checkblock-dguv-214-016-11',
      checkBlockDescription: 'Winterbetrieb',
      checks: [
        {
          checkId: 'check-dguv-214-016-56',
          checkDescription: 'Bereifung',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-57',
          checkDescription: 'Schneeketten',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-58',
          checkDescription: 'Frostschutzmittel',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-59',
          checkDescription: 'Hilfsmittel (z. B. Schaufel)',
          faults: null,
        },
      ],
    }, */
    {
      blockId: 'checkblock-dguv-214-016-11',
      checkBlockDescription: 'Prüfungen',
      checks: [
        {
          checkId: 'check-dguv-214-016-60',
          checkDescription: 'HU/AU',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-61',
          checkDescription: 'SP',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-62',
          checkDescription: 'UVV',
          faults: null,
        },
      ],
      blockComment: null
    },
  ],
  Excavator: [
    {
      blockId: 'checkblock-bgbau-baumaschinen-1',
      checkBlockDescription: 'Grundgerät',
      checks: [
        {
          checkId: 'check-bgbau-baumaschinen-1',
          checkDescription: 'Rahmen',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-2',
          checkDescription: 'Lagerungen',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-3',
          checkDescription: 'Gegengewichte',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-4',
          checkDescription: 'Anhängervorrichtung',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-5',
          checkDescription: 'Fahrwerk',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-6',
          checkDescription: 'Bereifung/Ketten',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-7',
          checkDescription: 'Transportösen',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-bgbau-baumaschinen-2',
      checkBlockDescription: 'Antrieb',
      checks: [
        {
          checkId: 'check-bgbau-baumaschinen-8',
          checkDescription: 'Dichtheit',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-9',
          checkDescription: 'Abgase',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-10',
          checkDescription: 'Schalldämpfung',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-bgbau-baumaschinen-3',
      checkBlockDescription: 'Arbeitseinrichtungen',
      checks: [
        {
          checkId: 'check-bgbau-baumaschinen-11',
          checkDescription: 'Schaufel, Schild',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-12',
          checkDescription: 'Anbaugeräte',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-13',
          checkDescription: 'Lasthaken',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-14',
          checkDescription: 'Greifer',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-15',
          checkDescription: 'gesicherte Anschlüsse und Verbindungen',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-bgbau-baumaschinen-4',
      checkBlockDescription: 'Sichere Zugänge',
      checks: [
        {
          checkId: 'check-bgbau-baumaschinen-16',
          checkDescription: 'Haltegriffe/Stangen',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-17',
          checkDescription: 'Auftritte',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-18',
          checkDescription: 'Trittflächen („trittsicher“)',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-bgbau-baumaschinen-5',
      checkBlockDescription: 'Fahrerkabine',
      checks: [
        {
          checkId: 'check-bgbau-baumaschinen-19',
          checkDescription: 'Tür, Fensterverriegelung',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-20',
          checkDescription: 'Scheibenwischer',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-21',
          checkDescription: 'Sitz, Sicherheitsgurte',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-22',
          checkDescription: 'Heizung, Lüftung',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-23',
          checkDescription: 'Schalldämmung',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-bgbau-baumaschinen-6',
      checkBlockDescription: 'Sicht',
      checks: [
        {
          checkId: 'check-bgbau-baumaschinen-24',
          checkDescription: 'Spiegel (außen, innen)',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-25',
          checkDescription: 'Kamera-, Monitorsysteme',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-26',
          checkDescription: 'Vereinfachtes Verfahren zur Überprüfung des Sichtfeldes: Kann der Fahrer eine im Abstand von 1,0 m vor, hinter bzw. neben der Maschine arbeitende Person sehen?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-bgbau-baumaschinen-7',
      checkBlockDescription: 'Bedienungseinrichtungen',
      checks: [
        {
          checkId: 'check-bgbau-baumaschinen-27',
          checkDescription: 'Gas, Kupplung, Schaltung',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-28',
          checkDescription: 'Hebelarretierung',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-29',
          checkDescription: 'Pedale (Griffigkeit)',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-bgbau-baumaschinen-8',
      checkBlockDescription: 'Elektrische Anlage',
      checks: [
        {
          checkId: 'check-bgbau-baumaschinen-30',
          checkDescription: 'Batterien',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-31',
          checkDescription: 'Beleuchtung',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-32',
          checkDescription: 'Hupe',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-33',
          checkDescription: 'Kontrollinstrumente',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-bgbau-baumaschinen-9',
      checkBlockDescription: 'Hydraulik-/ Druckluftanlage',
      checks: [
        {
          checkId: 'check-bgbau-baumaschinen-34',
          checkDescription: 'Ventile',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-35',
          checkDescription: 'Leitungen',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-36',
          checkDescription: 'Schläuche',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-37',
          checkDescription: 'Zylinder',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-bgbau-baumaschinen-10',
      checkBlockDescription: 'Bremsanlage',
      checks: [
        {
          checkId: 'check-bgbau-baumaschinen-38',
          checkDescription: 'Fahrbremse',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-39',
          checkDescription: 'Feststellbremse',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-bgbau-baumaschinen-11',
      checkBlockDescription: 'Lenkung',
      checks: [
        {
          checkId: 'check-bgbau-baumaschinen-40',
          checkDescription: 'Lenkung',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-bgbau-baumaschinen-12',
      checkBlockDescription: 'Schutzeinrichtung',
      checks: [
        {
          checkId: 'check-bgbau-baumaschinen-41',
          checkDescription: 'Verkleidungen',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-42',
          checkDescription: 'Abdeckungen',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-43',
          checkDescription: 'Schutzdach',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-44',
          checkDescription: 'Zylinderarretierung',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-45',
          checkDescription: 'Warnanstrich',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-bgbau-baumaschinen-13',
      checkBlockDescription: 'Zubehör',
      checks: [
        {
          checkId: 'check-bgbau-baumaschinen-46',
          checkDescription: 'Betriebsanleitung',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-47',
          checkDescription: 'Warnschilder',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-48',
          checkDescription: 'Verbandzeug',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-49',
          checkDescription: 'Feuerlöscher',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-50',
          checkDescription: 'Unterlegkeil',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-51',
          checkDescription: 'Lasthaken/-sicherung',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-bgbau-baumaschinen-14',
      checkBlockDescription: 'bei Hebezeugen',
      checks: [
        {
          checkId: 'check-bgbau-baumaschinen-52',
          checkDescription: 'Ausleger',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-53',
          checkDescription: 'Drahtseile',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-54',
          checkDescription: 'Überlast (Warnschaltung)',
          faults: null,
        },
        {
          checkId: 'check-bgbau-baumaschinen-55',
          checkDescription: 'Notendhalteeinrichtung',
          faults: null,
        },
      ],
      blockComment: null
    },
  ],
  /* Container: [
    {
      blockId: 'checkblock-dguv-214-017-1',
      checkBlockDescription: 'Prüfpunkt',
      checks: [
        {
          checkId: 'check-dguv-214-017-1',
          checkDescription: 'Kennzeichnung',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-2',
          checkDescription: 'Gesamtzustand',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-3',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-4',
          checkDescription: 'Aufnahmebügel/Seilhaken',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-5',
          checkDescription: 'Verriegelungsösen',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-6',
          checkDescription: 'Ladungssicherung',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-017-2',
      checkBlockDescription: 'Bewegliche Anbauteile',
      checks: [
        {
          checkId: 'check-dguv-214-017-7',
          checkDescription: 'Deckel/Türen',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-8',
          checkDescription: 'Verschlüsse/Griffe',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-017-3',
      checkBlockDescription: 'Sonstige Ausrüstung',
      checks: [
        {
          checkId: 'check-dguv-214-017-9',
          checkDescription: 'Aufstiege',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-10',
          checkDescription: 'Rollen',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-017-4',
      checkBlockDescription: 'Kennzeichnung',
      checks: [
        {
          checkId: 'check-dguv-214-017-11',
          checkDescription: 'Angabe Hersteller (Fabrikschild)',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-12',
          checkDescription: 'Angabe Baujahr (Fabrikschild)',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-13',
          checkDescription: 'Angabe Typenbezeichnung (Fabrikschild)',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-14',
          checkDescription: 'Angabe Fabriknummer (Fabrikschild)',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-15',
          checkDescription: 'Angabe Fassungsvermögen in m³ (Fabrikschild)',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-16',
          checkDescription: 'Angabe Leergewicht in kg (Fabrikschild)',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-17',
          checkDescription: 'Angabe zulässiges Gesamtgewicht in kg',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-18',
          checkDescription: 'Bei Abrollbehältern mit Kranösen: CE-Kennzeichnung vorhanden?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-19',
          checkDescription: 'Bei Verwendung im öffentlichen Verkehrsraum: Hat der Behälter eine reflektierende Sicherheitskennzeichnung (mindestens 8 Normflächen auf jeder Behälterseite)?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-20',
          checkDescription: 'Ist die Betriebsanschrift auf dem Behälter vorhanden?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-21',
          checkDescription: 'z. B. bei Behälter mit Deckel: Sind Hinweise auf die Bedienung angebracht und unbeschädigt, z. B. Kurzbedienungsanleitung?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-22',
          checkDescription: 'Sind Warnhinweise für die Bedienung angebracht und unbeschädigt?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-017-5',
      checkBlockDescription: 'Gesamtzustand',
      checks: [
        {
          checkId: 'check-dguv-214-017-23',
          checkDescription: 'Sind die Behälter unbeschädigt ohne Verformungen, Ausbeulungen, Löcher, Risse?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-24',
          checkDescription: 'Ist die Lackierung (als Korrosionsschtuz) unbeschädigt?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-017-6',
      checkBlockDescription: 'Schweißnähte',
      checks: [
        {
          checkId: 'check-dguv-214-017-25',
          checkDescription: 'Sind die Schweißnähte unbeschädigt, ohne An- bzw. Einrisse?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-017-7',
      checkBlockDescription: 'Aufnahmebügel/Seilhaken',
      checks: [
        {
          checkId: 'check-dguv-214-017-26',
          checkDescription: 'Sind die Aufnahmebügel unbeschädigt? (Bei Deformierung ist eine Abweichung von max. 10 % vom Sollmaß zulässig.)',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-27',
          checkDescription: 'Beträgt die Abnutzung der Aufnahmebügel weniger als 5 % vom Sollmaß?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-28',
          checkDescription: 'Entspricht der Abstand des Behälteranschlages (Mitte Aufnahmebügel bis zur Anschlagvorderkante) dem Sollmaß von 175 mm?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-29',
          checkDescription: 'Entspricht das Höhenmaß (Mitte Aufnahmebügel bis Unterkante I-Träger) dem Sollmaß von 1.570 mm?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-30',
          checkDescription: 'Sind die Seilhaken unbeschädigt (nicht aufgebogen oder aufgerissen)?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-017-8',
      checkBlockDescription: 'Behälterverriegelung (Verriegelungsösen oder -lager)',
      checks: [
        {
          checkId: 'check-dguv-214-017-31',
          checkDescription: 'Sind Verriegelungsösen und -lager unbeschädigt? (Bei Deformierung ist eine Abweichung von max. 10 % vom Sollmaß zulässig.)',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-32',
          checkDescription: 'Beträgt die Abnutzung bei Verriegelungsösen und -lagern weniger 5 % vom Sollmaß?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-017-9',
      checkBlockDescription: 'Ladungssicherung',
      checks: [
        {
          checkId: 'check-dguv-214-017-33',
          checkDescription: 'Sind Einrichtungen zur Sicherung der Ladung am und im Behälter (Haken für Netze, Zurrösen) vorhanden und funktionstüchtig?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-34',
          checkDescription: 'Sind sichere Einrichtungen (Standplätze) zum Anbringen und Entfernen von Planen oder Netzen vorhanden?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-35',
          checkDescription: 'Sind untere Längsträger (I-Träger) unbeschädigt (kein Verschleiß, keine Verformungen)?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-017-10',
      checkBlockDescription: 'Bewegliche Anbauteile',
      checks: [
        {
          checkId: 'check-dguv-214-017-36',
          checkDescription: 'Deckel/Türen',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-37',
          checkDescription: 'Sind die Deckel unbeschädigt?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-38',
          checkDescription: 'Lassen sich die Deckel leicht öffnen und schließen?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-39',
          checkDescription: 'Lassen sich die Deckel gegen unbeabsichtigtes Zuschlagen sichern?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-40',
          checkDescription: 'Sind die Deckel gegen unbeabsichtigtes Hochschlagen gesichert? Z. B. durch Verschluss mit Öffnungsdruckerkennung (mit Federspeicher / Gasdruckfeder)',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-41',
          checkDescription: 'Sind Drehstab- oder Gasdruckfedern funktionstüchtig?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-42',
          checkDescription: 'Sind die Türen unbeschädigt?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-43',
          checkDescription: 'Lassen sich die Türen leicht öffnen und schließen?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-44',
          checkDescription: 'Lassen sich die Türen gegen unbeabsichtigtes Zuschlagen sichern?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-45',
          checkDescription: 'Sind Türen mit Verschlüssen ausgestattet, die sich außerhalb des Gefahrenbereiches bedienen lassen (Zentralverschluss, automatischer Verschluss)?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-017-11',
      checkBlockDescription: 'Verschlüsse/Griffe',
      checks: [
        {
          checkId: 'check-dguv-214-017-46',
          checkDescription: 'Lassen sich Verschlüsse / Griffe leicht handhaben?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-47',
          checkDescription: 'Sind Verschlüsse / Griffe so beschaffen, dass sie keine Quetsch- oder Scherstellen haben?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-48',
          checkDescription: 'Sind Verschlüsse / Griffe ergonomisch gestaltet?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-49',
          checkDescription: 'Sind Verschlüsse / Griffe unbeschädigt (nicht deformiert oder scharfkantig)?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-50',
          checkDescription: 'Sind Sicherungen gegen unbeabsichtigtes Öffnen unbeschädigt?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-51',
          checkDescription: 'Sind Handhebel unbeschädigt (nicht deformiert oder scharfkantig)?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-017-12',
      checkBlockDescription: 'Aufstiege (Sonstige Ausrüstung)',
      checks: [
        {
          checkId: 'check-dguv-214-017-52',
          checkDescription: 'Sind Aufstiege unbeschädigt?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-53',
          checkDescription: 'Haben Aufstiege eine Fußraumtiefe von mind. 150 mm?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-54',
          checkDescription: 'Haben Aufstiege eine Auftrittstiefe (Sprossenbreite) von mind. 20 mm?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-55',
          checkDescription: 'Haben Aufstiege eine Auftrittsbreite (lichten Holmabstand) von mind. 300 mm?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-56',
          checkDescription: 'Haben Aufstiege Sprossenabstände von mind. 280 mm?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-57',
          checkDescription: 'Haben Aufstiege einen Schutz gegen seitliches Abrutschen von den Sprossen?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-58',
          checkDescription: 'Haben Aufstiege eine rutschhemmende Oberfläche?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-017-13',
      checkBlockDescription: 'Rollen',
      checks: [
        {
          checkId: 'check-dguv-214-017-59',
          checkDescription: 'Sind Rollen im Bewegungsablauf störungsfrei?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-017-60',
          checkDescription: 'Sind Rollen unbeschädigt / nicht abgenutzt?',
          faults: null,
        },
      ],
      blockComment: null
    },
  ], */
  Container: [
    {
      blockId: 'checkblock-dguv-114-010-1',
      checkBlockDescription: 'Kennzeichnung',
      checks: [
        {
          checkId: 'check-dguv-114-010-1',
          checkDescription: 'Typenschild',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-2',
          checkDescription: 'Sicherheits-/Warnhinweise',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-3',
          checkDescription: 'Warnmarkierung (StVo)',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-2',
      checkBlockDescription: 'Lackierung',
      checks: [
        {
          checkId: 'check-dguv-114-010-4',
          checkDescription: 'Zustand',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-3',
      checkBlockDescription: 'Aufnahmebügel',
      checks: [
        {
          checkId: 'check-dguv-114-010-5',
          checkDescription: 'Bügeldurchmesser (10%)',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-6',
          checkDescription: 'Knotenbleche',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-7',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-4',
      checkBlockDescription: 'Vorderer Längsträger',
      checks: [
        {
          checkId: 'check-dguv-114-010-8',
          checkDescription: 'Zustand',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-9',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-5',
      checkBlockDescription: 'Stirnwand',
      checks: [
        {
          checkId: 'check-dguv-114-010-10',
          checkDescription: 'Bleche',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-11',
          checkDescription: 'Oberprofil',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-12',
          checkDescription: 'Anschlag',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-13',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-6',
      checkBlockDescription: 'Unterer Längsträger',
      checks: [
        {
          checkId: 'check-dguv-114-010-14',
          checkDescription: 'Zustand',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-15',
          checkDescription: 'Verstreifungsblech',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-16',
          checkDescription: 'Bodenbleche',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-17',
          checkDescription: 'Bodenprofile',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-18',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-7',
      checkBlockDescription: 'Verriegelungsösen',
      checks: [
        {
          checkId: 'check-dguv-114-010-19',
          checkDescription: 'Zustand',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-20',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-21',
          checkDescription: 'Verriegelungsmaß',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-8',
      checkBlockDescription: 'Rollen',
      checks: [
        {
          checkId: 'check-dguv-114-010-22',
          checkDescription: 'Zustand',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-23',
          checkDescription: 'Funktion',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-24',
          checkDescription: 'Halterung',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-25',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-9',
      checkBlockDescription: 'Seitenwand rechts (Fahrtrichtung)',
      checks: [
        {
          checkId: 'check-dguv-114-010-26',
          checkDescription: 'Seitenwandbleche',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-27',
          checkDescription: 'Seitenwandprofile',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-28',
          checkDescription: 'Oberprofil',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-29',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-10',
      checkBlockDescription: 'Seitenwand links (Fahrtrichtung)',
      checks: [
        {
          checkId: 'check-dguv-114-010-30',
          checkDescription: 'Seitenwandbleche',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-31',
          checkDescription: 'Seitenwandprofile',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-32',
          checkDescription: 'Oberprofil',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-33',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-11',
      checkBlockDescription: 'Türen',
      checks: [
        {
          checkId: 'check-dguv-114-010-34',
          checkDescription: 'Funktion',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-35',
          checkDescription: 'Scharniere',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-36',
          checkDescription: 'Türverschlusswelle-/lagerung',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-37',
          checkDescription: 'Türverschlusshaken (oben+unten)',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-38',
          checkDescription: 'Türverschlussicherung',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-39',
          checkDescription: 'Schmiernippel',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-40',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-12',
      checkBlockDescription: 'Zentralverschluss',
      checks: [
        {
          checkId: 'check-dguv-114-010-41',
          checkDescription: 'Funktion',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-42',
          checkDescription: 'Bedienhebel',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-43',
          checkDescription: 'Verschlusshaken',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-13',
      checkBlockDescription: 'Boden',
      checks: [
        {
          checkId: 'check-dguv-114-010-44',
          checkDescription: 'Bleche',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-45',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-14',
      checkBlockDescription: 'Aufstiege',
      checks: [
        {
          checkId: 'check-dguv-114-010-46',
          checkDescription: 'Abmaße',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-47',
          checkDescription: 'Rutschfest',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-15',
      checkBlockDescription: 'Dach',
      checks: [
        {
          checkId: 'check-dguv-114-010-48',
          checkDescription: 'Dachanschlag in FR',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-49',
          checkDescription: 'Lagerung',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-50',
          checkDescription: 'Schienenführung',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-51',
          checkDescription: 'Deckelsicherung',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-52',
          checkDescription: 'Hydraulikpumpe/Schläuche',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-53',
          checkDescription: 'Rohrbruchsicherung',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-54',
          checkDescription: 'Handwinde',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-55',
          checkDescription: 'Funktionsprobe',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-16',
      checkBlockDescription: 'Sonderausrüstung',
      checks: [
        {
          checkId: 'check-dguv-114-010-56',
          checkDescription: 'Dichtungen',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-57',
          checkDescription: 'Ablasshahn',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-58',
          checkDescription: 'Augenschrauben',
          faults: null,
        },
      ],
      blockComment: null
    },
  ],
  /* Skip: [
    {
      blockId: 'checkblock-dguv-214-016-1',
      checkBlockDescription: 'Prüfpunkt',
      checks: [
        {
          checkId: 'check-dguv-214-016-1',
          checkDescription: 'Kennzeichnung',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-2',
          checkDescription: 'Gesamtzustand',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-3',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-4',
          checkDescription: 'Kipplager',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-5',
          checkDescription: 'Aufhängezapfen',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-6',
          checkDescription: 'Gleitschiene',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-7',
          checkDescription: 'Profil für Stapelsicherung',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-8',
          checkDescription: 'Bewegliche Anbauteile',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-9',
          checkDescription: 'Türen/Klappen',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-10',
          checkDescription: 'Verschlüsse',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-2',
      checkBlockDescription: 'Kennzeichnung',
      checks: [
        {
          checkId: 'check-dguv-214-016-11',
          checkDescription: 'Ist ein Fabrikschild vorhanden?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-12',
          checkDescription: 'Sind die Angaben auf dem Fabrikschild vollständig?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-13',
          checkDescription: 'Hersteller',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-14',
          checkDescription: 'Baujahr',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-15',
          checkDescription: 'Typenbezeichnung',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-16',
          checkDescription: 'Seriennummer',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-17',
          checkDescription: 'Fassungsvermögen in m³',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-18',
          checkDescription: 'Leergewicht in kg',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-19',
          checkDescription: 'Zulässiges Gesamtgewicht in kg',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-20',
          checkDescription: 'CE-Kennzeichnung bei kranbaren Absetzbehältern',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-21',
          checkDescription: 'Ist der Behälter mit reflektierender Sicherheitskennzeichnung und Betriebsanschrift versehen? (Verwendung im öffentlichen Straßenraum)',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-22',
          checkDescription: 'Sind Bedienhinweise vorhanden? (z.B. bei Deckelbehältern)',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-3',
      checkBlockDescription: 'Gesamtzustand',
      checks: [
        {
          checkId: 'check-dguv-214-016-23',
          checkDescription: 'Hält der Behälter den betriebsmäßigen Beanspruchungen stand?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-24',
          checkDescription: 'Gibt es Verformungen, Ausbeulungen, Löcher, Risse?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-25',
          checkDescription: 'Sind alle Bauteile ausreichend dimensioniert?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-26',
          checkDescription: 'Werden geeignete Materialien verwendet?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-27',
          checkDescription: 'Ist die Lackierung unbeschädigt?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-4',
      checkBlockDescription: 'Schweißnähte',
      checks: [
        {
          checkId: 'check-dguv-214-016-28',
          checkDescription: 'Sind die Schweißnähte fei von An- bzw. Einrissen?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-29',
          checkDescription: 'Sind die Schweißnähte ordnungsgemäß hergestellt?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-5',
      checkBlockDescription: 'Kipplager',
      checks: [
        {
          checkId: 'check-dguv-214-016-30',
          checkDescription: 'Sind die Kipplager deformiert?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-31',
          checkDescription: 'Sind die Kipplager eingerissen?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-32',
          checkDescription: 'Sind die Kipplager abgenutzt?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-33',
          checkDescription: 'Sind die Kipplager mind. aus dem Werkstoff S355J2G3 gefertigt? (alte Werkstoffbezeichnung St 52-3)',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-34',
          checkDescription: 'Sind die Kipplager mit einem Sicherheitsblech ausgerüstet? (Abstand zwischen Mitte und Kipplagerbolzen und der Behälterbodenkante ≤ 100 mm, betrifft nur Behälter bis 10-2009 Herstellerdatum)',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-6',
      checkBlockDescription: 'Aufhängezapfen',
      checks: [
        {
          checkId: 'check-dguv-214-016-35',
          checkDescription: 'Sind die Aufhängezapfen deformiert?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-36',
          checkDescription: 'Sind die Aufhängezapfen eingerissen?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-37',
          checkDescription: 'Sind die Aufhängezapfen abgenutzt?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-38',
          checkDescription: 'Sind die Aufhängezapfen mind. aus dem Werkstoff S355J2G3 gefertigt? (alte Werkstoffbezeichnung St 52-3)',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-39',
          checkDescription: 'Sind die Aufhängezapfen so geformt, dass ein unbeabsichtigtes Aushängen des Tragmittels verhindert wird?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-7',
      checkBlockDescription: 'Deckel (Bewegliche Anbauteile)',
      checks: [
        {
          checkId: 'check-dguv-214-016-40',
          checkDescription: 'Sind die Deckel beschädigt?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-41',
          checkDescription: 'Lassen sich die Deckel leicht öffnen und schließen? (Bedienkräfte < 100 N)',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-42',
          checkDescription: 'Lassen sich die Deckel gegen unbeabsichtigtes Zuschlagen sichern?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-43',
          checkDescription: 'Lassen sich die Deckel gegen unbeabsichtigtes Hochschlagen sichern?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-44',
          checkDescription: 'Sind Bauteile wie Verschlüsse, Drehstabfeder oder Gasdruckfedern in ordnungsgemäßem Zustand?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-8',
      checkBlockDescription: 'Türen (Bewegliche Anbauteile)',
      checks: [
        {
          checkId: 'check-dguv-214-016-45',
          checkDescription: 'Sind die Türen beschädigt?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-46',
          checkDescription: 'Lassen sich die Türen leicht öffnen und schließen? (Bedienkräfte < 100 N)',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-47',
          checkDescription: 'Lassen sich die Türen gegen unbeabsichtigtes Zuschlagen sichern?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-48',
          checkDescription: 'Lassen sich die Türen außerhalb des Gefahrenbereiches öffnen?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-9',
      checkBlockDescription: 'Klappen (Bewegliche Anbauteile)',
      checks: [
        {
          checkId: 'check-dguv-214-016-49',
          checkDescription: 'Sind die Klappen beschädigt?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-50',
          checkDescription: 'Können die Klappen unbeabsichtigt abgeklappt werden?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-51',
          checkDescription: 'Lassen sich die Klappen leicht öffnen und schließen?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-52',
          checkDescription: 'Sind schwere Klappen mit einem Gewichtsausgleich versehen?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-53',
          checkDescription: 'Sind die Klappen ausreichend dimensioniert?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-10',
      checkBlockDescription: 'Verschlüsse/Griffe (Bewegliche Anbauteile)',
      checks: [
        {
          checkId: 'check-dguv-214-016-54',
          checkDescription: 'Lassen sich die Verschlüsse leicht öffnen und schließen?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-55',
          checkDescription: 'Haben die Verschlüsse Quetsch- und Scherstellen?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-56',
          checkDescription: 'Sind die Verschlüsse ergonomisch gestaltet, nicht deformiert oder scharfkantig?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-57',
          checkDescription: 'Wird ein unbeabsichtigtes Öffnen durch Sicherungen verhindert?',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-214-016-11',
      checkBlockDescription: 'Ladungssicherung',
      checks: [
        {
          checkId: 'check-dguv-214-016-58',
          checkDescription: 'Sind Einrichtungen zur Sicherung der Ladung am und im Behälter vorhanden und funktionstüchtig, z.B. Haken für Netze, Zurrösen?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-59',
          checkDescription: 'Können Netze von sicheren Standplätzen aus angebracht und entfernt werden?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-60',
          checkDescription: 'Ist das Profil für die Stapelsicherung im ordnungsgemäßen Zustand?',
          faults: null,
        },
        {
          checkId: 'check-dguv-214-016-61',
          checkDescription: 'Ist die Gleitschiene in ordnungsgemäßem Zustand?',
          faults: null,
        },
      ],
      blockComment: null
    },
  ], */
  Skip: [
    {
      blockId: 'checkblock-dguv-114-010-1',
      checkBlockDescription: 'Kennzeichnung',
      checks: [
        {
          checkId: 'check-dguv-114-010-1',
          checkDescription: 'Typenschild',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-2',
          checkDescription: 'Sicherheits-/Warnhinweise',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-3',
          checkDescription: 'Warnmarkierung (StVo)',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-2',
      checkBlockDescription: 'Lackierung',
      checks: [
        {
          checkId: 'check-dguv-114-010-4',
          checkDescription: 'Zustand',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-3',
      checkBlockDescription: 'Kipplager',
      checks: [
        {
          checkId: 'check-dguv-114-010-5',
          checkDescription: 'Kipplagerdurchmesser (10%)',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-6',
          checkDescription: 'Knotenbleche',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-7',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-4',
      checkBlockDescription: 'Vorderer Längsträger',
      checks: [
        {
          checkId: 'check-dguv-114-010-8',
          checkDescription: 'Zustand',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-9',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-5',
      checkBlockDescription: 'Stirnwand',
      checks: [
        {
          checkId: 'check-dguv-114-010-10',
          checkDescription: 'Bleche',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-11',
          checkDescription: 'Oberprofil',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-12',
          checkDescription: 'Anschlag',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-13',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-6',
      checkBlockDescription: 'Unterer Längsträger',
      checks: [
        {
          checkId: 'check-dguv-114-010-14',
          checkDescription: 'Zustand',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-15',
          checkDescription: 'Verstreifungsblech',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-16',
          checkDescription: 'Bodenbleche',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-17',
          checkDescription: 'Bodenprofile',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-18',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-7',
      checkBlockDescription: 'Verriegelungsösen (falls vorhanden)',
      checks: [
        {
          checkId: 'check-dguv-114-010-19',
          checkDescription: 'Zustand',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-20',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-21',
          checkDescription: 'Verriegelungsmaß',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-8',
      checkBlockDescription: 'Aufnahmezapfen',
      checks: [
        {
          checkId: 'check-dguv-114-010-22',
          checkDescription: 'Zustand',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-23',
          checkDescription: 'Funktion',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-24',
          checkDescription: 'Sicherungsblech',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-25',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-9',
      checkBlockDescription: 'Seitenwand rechts (Fahrtrichtung)',
      checks: [
        {
          checkId: 'check-dguv-114-010-26',
          checkDescription: 'Seitenwandbleche',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-27',
          checkDescription: 'Seitenwandprofile',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-28',
          checkDescription: 'Oberprofil',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-29',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-10',
      checkBlockDescription: 'Seitenwand links (Fahrtrichtung)',
      checks: [
        {
          checkId: 'check-dguv-114-010-30',
          checkDescription: 'Seitenwandbleche',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-31',
          checkDescription: 'Seitenwandprofile',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-32',
          checkDescription: 'Oberprofil',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-33',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-11',
      checkBlockDescription: 'Türen (falls vorhanden)',
      checks: [
        {
          checkId: 'check-dguv-114-010-34',
          checkDescription: 'Funktion',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-35',
          checkDescription: 'Scharniere',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-36',
          checkDescription: 'Türverschlusswelle-/lagerung',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-37',
          checkDescription: 'Türverschlusshaken (oben+unten)',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-38',
          checkDescription: 'Türverschlussicherung',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-39',
          checkDescription: 'Schmiernippel',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-40',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-12',
      checkBlockDescription: 'Zentralverschluss (falls vorhanden)',
      checks: [
        {
          checkId: 'check-dguv-114-010-41',
          checkDescription: 'Funktion',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-42',
          checkDescription: 'Bedienhebel',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-43',
          checkDescription: 'Verschlusshaken',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-13',
      checkBlockDescription: 'Boden',
      checks: [
        {
          checkId: 'check-dguv-114-010-44',
          checkDescription: 'Bleche',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-45',
          checkDescription: 'Schweißnähte',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-14',
      checkBlockDescription: 'Aufstiege (falls vorhanden)',
      checks: [
        {
          checkId: 'check-dguv-114-010-46',
          checkDescription: 'Abmaße',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-47',
          checkDescription: 'Rutschfest',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-15',
      checkBlockDescription: 'Dach',
      checks: [
        {
          checkId: 'check-dguv-114-010-48',
          checkDescription: 'Dachanschlag in FR',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-49',
          checkDescription: 'Lagerung',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-50',
          checkDescription: 'Schienenführung',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-51',
          checkDescription: 'Deckelsicherung',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-52',
          checkDescription: 'Hydraulikpumpe/Schläuche',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-53',
          checkDescription: 'Rohrbruchsicherung',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-54',
          checkDescription: 'Handwinde',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-55',
          checkDescription: 'Funktionsprobe',
          faults: null,
        },
      ],
      blockComment: null
    },
    {
      blockId: 'checkblock-dguv-114-010-16',
      checkBlockDescription: 'Sonderausrüstung',
      checks: [
        {
          checkId: 'check-dguv-114-010-56',
          checkDescription: 'Dichtungen',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-57',
          checkDescription: 'Ablasshahn',
          faults: null,
        },
        {
          checkId: 'check-dguv-114-010-58',
          checkDescription: 'Augenschrauben',
          faults: null,
        },
      ],
      blockComment: null
    },
  ],
}
