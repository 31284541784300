import React, { useState } from 'react'
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import dayjs from 'dayjs'
import Button from '@atlaskit/button'
import { DatePicker } from '@atlaskit/datetime-picker'

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: (date: string) => void
}

const CreateWagonModal: React.FunctionComponent<Props> = (
  props,
): JSX.Element => {
  const { isOpen, onClose, onSubmit } = props

  const [selectedDate, setSelectedDate] = useState<string>(
    dayjs().format('YYYY-MM-DD'),
  )

  const onSubmitClick = () => {
    onSubmit(selectedDate)
    onClose()
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} shouldCloseOnOverlayClick={false}>
          <ModalHeader>
            <ModalTitle>Tagesbericht anlegen</ModalTitle>
          </ModalHeader>

          <ModalBody>
            Der <b>Meldetag</b> ist standardmäßig auf <b>heute</b> eingestellt,
            kann jedoch - falls abweichend - auf ein beliebiges Datum gesetzt
            werden:
            <DatePicker
              locale="de-DE"
              value={selectedDate}
              onChange={setSelectedDate}
              autoFocus={false}
            />
          </ModalBody>

          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Schließen
            </Button>
            <Button appearance="primary" onClick={onSubmitClick} autoFocus>
              Bestätigen
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default CreateWagonModal
