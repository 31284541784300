import React, { useState } from 'react'
import ChecklistCheck from './checklist-check.component'
import { Status } from './checklist.types'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import modules from 'modules'
import { useDispatch } from 'react-redux';
import TextArea from '@atlaskit/textarea'
import cloneDeep from 'lodash/cloneDeep';

interface Props {
  checklistId: string,
  checklistBlock: any,
  status: Status,
  blockNumber: number,
}

const ChecklistCheckBlock: React.FunctionComponent<Props> = (props): JSX.Element => {

  const {
    checklistId,
    checklistBlock,
    status,
    blockNumber,
  } = props
  
  const [checks, setChecks] = useState<[]>(checklistBlock.checks);
  const [counter, setCounter] = useState(0);
  const [blockComment, setBlockComment] = useState(checklistBlock.blockComment);

  const checkBlockFaults = (check: any) => {
    if(check.blockFaults !== undefined) {
      return check.blockFaults
    }
    return false
  }

  const renderBlockChecks = () => {
    return checks.map(function(check: any, index: number) {
      return <ChecklistCheck key={index} checklistId={checklistId} status={status} checkId={check.checkId} checkDescription={check.checkDescription} checkFaults={check.faults} blockFaults={checkBlockFaults(check)} counter={counter} />
    })
  }

  const dispatch = useDispatch()

  const setCompleteBlock = (setAllFaults: boolean|null) => {
    setCounter(counter + 1)
    const newArr = cloneDeep(checks);

    newArr.forEach((item: any) => {
      item.faults = setAllFaults
      item.blockFaults = true
      item.counter = counter + 1
    })

    setChecks(newArr);

    dispatch(modules.checklist.actions.updateBlockFromChecklist(checklistId, checklistBlock.blockId, setAllFaults))
  }

  const saveComment = (event: any) => {
    dispatch(modules.checklist.actions.updateBlockCommentFromChecklist(checklistId, checklistBlock.blockId, event.target.value))
  }

  return (
    <>
      <div className="flex flex-col p-3 my-4 md:mr-2 md:ml-2 bg-gray-white">
        <div className="flex items-center justify-between py-2 font-bold">
          <div className='text-gray'>Mängel</div>
          {status === 'started' ?
          <div>
            <DropdownMenu
              trigger='Menü'
            >
              <DropdownItemGroup>
                <DropdownItem onClick={() => setCompleteBlock(false)}>keine Mängel</DropdownItem>
                <DropdownItem onClick={() => setCompleteBlock(null)}>Zurücksetzen</DropdownItem>
              </DropdownItemGroup>
            </DropdownMenu>
          </div>
          : null }
        </div>
        <div className="mt-4 mb-4 font-bold">{checklistBlock.checkBlockDescription}</div>
        
        {checks.length > 0 ? (
          <>
            {renderBlockChecks()}
          </>
        ):(null)}

        <div className='mt-auto justify-self-end'>
          <div className='text-gray'>Kommentar:</div>
          <TextArea
            style={{backgroundColor: "white"}}
            resize="auto"
            maxHeight="20vh"
            name="checklist_message"
            isDisabled={status === 'finished' || status === 'cancelled'}
            onBlur={(e) => saveComment(e)}
            defaultValue={blockComment}
          />
        </div>
      </div>
    </>
  )
}

export default ChecklistCheckBlock