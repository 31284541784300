import './index.css'
import '@atlaskit/css-reset'
import { ConfirmationServiceProvider } from 'components/confirmation-modal.service'
import { createGlobalStyle } from 'styled-components'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { store, persistor } from 'redux/setup.redux'
import FlagContainer from 'modules/core/flag.container'
import modules from 'modules'
import pack from '../package.json'
import React from 'react'
import ReactDOM from 'react-dom'
import Routes from './routes'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Verdana, Geneva, sans-serif;
  }
`

export default ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
        onBeforeLift={() => {
          store.dispatch(modules.core.actions.setVersion(pack.version))
        }}
      >
        <ConfirmationServiceProvider>
          <Routes />
        </ConfirmationServiceProvider>
        <FlagContainer />
      </PersistGate>
    </Provider>

    <GlobalStyle />
  </React.StrictMode>,
  document.getElementById('root'), // eslint-disable-line
)
