import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from '@atlaskit/page'
import { useParams } from 'react-router-dom'
import PageHeader from 'components/page-header.component'
import VehicleChecklist from './vehicle-checklist.component'
import ContainerChecklist from './container-checklist.component'
import modules from 'modules'

interface RouterParams {
  checklistId: string,
}

const ChecklistScreen: React.FunctionComponent = (): JSX.Element | null => {
  const dispatch = useDispatch()
  const params: RouterParams = useParams()

  /* const isAdminUser = useSelector(modules.auth.selectors.selectIsAdminUser)
  const user = useSelector(modules.auth.selectors.selectUser) */

  const checklist = useSelector(
    modules.checklist.selectors.selectChecklistById(params.checklistId),
  )
  
  useEffect(() => {
    if (!checklist) {
      dispatch(modules.checklist.actions.fetchChecklistById(params.checklistId))
    }
  }, [])

  if (!checklist) return null

  const { _id: checklistId, checklistType, checklistData, status, vehiclePlate, containerNr, checklistMessage } = checklist

  return (
    <Page>
      <PageHeader
        breadcrump="Checkliste"
        title={'Checkliste ID ' + checklistId}
      />
      {checklistType === 'Vehicle' || checklistType === 'Excavator' ? 
        <VehicleChecklist
          key={checklistId}
          checklistId={checklistId}
          checklistType={checklistType}
          checklistData={checklistData}
          status={status}
          vehiclePlate={vehiclePlate}
          checklistMessage={checklistMessage}
        />
        :
        null
      }
      {checklistType === 'Container' || checklistType === 'Skip' ?
        <ContainerChecklist
          key={checklistId}
          checklistId={checklistId}
          checklistType={checklistType}
          checklistData={checklistData}
          status={status}
          containerNr={containerNr}
          checklistMessage={checklistMessage}
        />
        :
        null
      }
    </Page>
  )
}

export default ChecklistScreen
