import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { PageLayout, TopNavigation, Content, Main } from '@atlaskit/page-layout'
import CoreNavigation from './core.navigation'
import modules from 'modules'

interface Props {
  children: React.ReactNode
}

const ScreenWrapper = styled.div`
  margin: 0 24px;
`

const CoreLayout: React.FunctionComponent<Props> = (props): JSX.Element => {
  const dispatch = useDispatch()
  const userName = useSelector(modules.auth.selectors.selectUserName)

  const signOut = () => dispatch({ type: 'RESET_STATE' })

  return (
    <PageLayout>
      <TopNavigation>
        <CoreNavigation userName={userName} onSignOut={signOut} />
      </TopNavigation>
      <Content>
        <Main>
          <ScreenWrapper>{props.children}</ScreenWrapper>
        </Main>
      </Content>
    </PageLayout>
  )
}

export default CoreLayout
