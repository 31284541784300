import { AxiosResponse } from 'axios'

import { CHANGE_PASSWORD_ENDPOINT } from 'config/server.config'
import { request } from 'utils/http.utils'
import { ChangePasswordRequestData } from './settings.types'

export const changePassword = (
  credentials: ChangePasswordRequestData,
): Promise<AxiosResponse<boolean>> => {
  return request({
    method: 'post',
    url: CHANGE_PASSWORD_ENDPOINT,
    data: credentials,
  })
}
