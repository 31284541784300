export enum ActionTypes {
  LIST_DIC_PENDING = 'LOADING/LIST_DIC_PENDING',
  LIST_DIC_ERROR = 'LOADING/LIST_DIC_ERROR',
  LIST_DIC_SUCCESS = 'LOADING/LIST_DIC_SUCCESS',
  SAVE_LIST = 'LOADING/SAVE_LIST',
}

export interface State {
  isLoading: boolean
  data: []
}

export interface ListDicPendingAction {
  type: ActionTypes.LIST_DIC_PENDING
}

export interface ListDicErrorAction {
  type: ActionTypes.LIST_DIC_ERROR
  error: Error
}

export interface ListDicSuccessAction {
  type: ActionTypes.LIST_DIC_SUCCESS
}

export interface SaveListAction {
  type: ActionTypes.SAVE_LIST
  meta: {
    shouldReplace: boolean
  }
  payload: {
    data: []
  }
}

export type Action =
  | ListDicPendingAction
  | ListDicErrorAction
  | ListDicSuccessAction
  | SaveListAction
