import React, { useState } from 'react'
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button'

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
}

const DeleteChecklistsModal: React.FunctionComponent<Props> = (
  props,
): JSX.Element => {
  const { isOpen, onClose, onSubmit } = props

  const onSubmitClick = () => {
    onSubmit()
    onClose()
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} shouldCloseOnOverlayClick={false}>
          <ModalHeader>
            <ModalTitle>Auswahl löschen</ModalTitle>
          </ModalHeader>

          <ModalBody>Möchten Sie Ihre Auswahl wirklich löschen?</ModalBody>

          <ModalFooter>
            <Button appearance="primary" onClick={onSubmitClick} autoFocus>
              Bestätigen
            </Button>
            <Button appearance="subtle" onClick={onClose}>
              Schließen
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default DeleteChecklistsModal
