import { AxiosResponse } from 'axios'

import { CONTAINERS_ENDPOINT } from 'config/server.config'
import { request } from 'utils/http.utils'
import { CreateContainerParameters, Container } from './containers.types'

export const createContainer = (
  container: CreateContainerParameters,
): Promise<AxiosResponse<Container>> => {
  return request({
    method: 'post',
    url: CONTAINERS_ENDPOINT,
    data: container,
  })
}

export const editContainer = (
  container: Container,
): Promise<AxiosResponse<Container>> => {
  return request({
    method: 'put',
    url: `${CONTAINERS_ENDPOINT}/${container._id}`,
    data: container,
  })
}

export const fetchContainers = (): Promise<AxiosResponse<Container[]>> => {
  return request({
    method: 'get',
    url: CONTAINERS_ENDPOINT,
  })
}

export const deleteContainer = (containerId: string): Promise<AxiosResponse<boolean>> => {
  return request({
    method: 'delete',
    url: `${CONTAINERS_ENDPOINT}/${containerId}`,
  })
}