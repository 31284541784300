import * as yup from 'yup'
import { ChecklistTypes, Status } from './checklist.types'

const userSchema = yup.object({
  _id: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
})

export const checklistSchema = yup.object({
  _id: yup.string().required(),
  checklistType: yup.string().oneOf(Object.values(ChecklistTypes)).required(),
  checklistData: yup.array().nullable() /* .required() */, // TODO: required nach seeden!
  status: yup.string().oneOf(Object.values(Status)).required(),
  vehiclePlate: yup.string().nullable(),
  containerNr: yup.string().nullable(),
  checkResult: yup.boolean().nullable(),
  checklistMessage: yup.string().nullable(),
  createdAt: yup.date().required(),
  updatedAt: yup.date().required(),
  finishedAt: yup.date().nullable(),
  createdBy: userSchema.nullable(),
})
