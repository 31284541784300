import { Reducer, Dispatch } from 'redux'
import { createSelector } from 'reselect'

import { AppState, GenericThunk } from 'typescript/common.types'
import { userSchema } from './users.schemas'
import * as api from './users.service'
import * as types from './users.types'
import {
  State,
  User,
  ActionTypes,
  Action,
  CreateUserParameters,
} from './users.types'

/**
 * Initial State
 */
const initialState: State = {
  users: {
    byId: {},
    allIds: [],
  },
  isLoading: false,
  isLoadingEdit: false,
}

/**
 * Actions & Thunks
 */
const createUserPending = (): Action => ({
  type: ActionTypes.CREATE_USER_PENDING,
})
const createUserError = (error: Error): Action => ({
  type: ActionTypes.CREATE_USER_ERROR,
  error,
})
const createUserSuccess = (): Action => ({
  type: ActionTypes.CREATE_USER_SUCCESS,
})
const saveUsers = (users: User[], shouldReplace: boolean): Action => ({
  type: ActionTypes.SAVE_USERS,
  meta: { shouldReplace },
  payload: { users },
})

const createUser = (
  newUser: CreateUserParameters,
): GenericThunk<Promise<User | null>> => {
  return async (dispatch: Dispatch): Promise<User | null> => {
    dispatch(createUserPending())

    try {
      const { data: user } = await api.createUser(newUser)

      userSchema.validateSync(user)
      dispatch(saveUsers([user], false))
      dispatch(createUserSuccess())

      return user
    } catch (error) {
      if (error instanceof Error) {
        dispatch(createUserError(error))
      }
    }
    return null
  }
}

const editUserPending = (): Action => ({
  type: ActionTypes.EDIT_USER_PENDING,
})
const editUserError = (error: Error): Action => ({
  type: ActionTypes.EDIT_USER_ERROR,
  error,
})
const editUserSuccess = (): Action => ({
  type: ActionTypes.EDIT_USER_SUCCESS,
})
const editUser = (editUser: User): GenericThunk<Promise<User | null>> => {
  return async (dispatch: Dispatch): Promise<User | null> => {
    dispatch(editUserPending())

    try {
      const { data: user } = await api.editUser(editUser)

      userSchema.validateSync(user)
      dispatch(saveUsers([user], true))
      dispatch(editUserSuccess())

      return user
    } catch (error) {
      if (error instanceof Error) {
        dispatch(editUserError(error))
      }
    }
    return null
  }
}

const fetchUsersPending = (): Action => ({
  type: ActionTypes.FETCH_USERS_PENDING,
})
const fetchUsersError = (error: Error): Action => ({
  type: ActionTypes.FETCH_USERS_ERROR,
  error,
})
const fetchUsersSuccess = (): Action => ({
  type: ActionTypes.FETCH_USERS_SUCCESS,
})

const fetchUsers = (): GenericThunk<Promise<User[]>> => {
  return async (dispatch: Dispatch): Promise<User[]> => {
    dispatch(fetchUsersPending())

    try {
      const { data: users } = await api.fetchUsers()

      users.forEach((user) => userSchema.validateSync(user))

      dispatch(saveUsers(users, true))
      dispatch(fetchUsersSuccess())

      return users
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchUsersError(error))
      }
    }
    return []
  }
}

const resetPasswordPending = (): Action => ({
  type: ActionTypes.RESET_PASSWORD_PENDING,
})
const resetPasswordError = (error: Error): Action => ({
  type: ActionTypes.RESET_PASSWORD_ERROR,
  error,
})
const resetPasswordSuccess = (): Action => ({
  type: ActionTypes.RESET_PASSWORD_SUCCESS,
})

const resetPassword = (
  userId: string,
  newPassword: string,
): GenericThunk<Promise<boolean>> => {
  return async (dispatch: Dispatch): Promise<boolean> => {
    dispatch(resetPasswordPending())

    try {
      const { data: response } = await api.resetPassword(userId, newPassword)

      dispatch(resetPasswordSuccess())

      return response
    } catch (error) {
      if (error instanceof Error) {
        dispatch(resetPasswordError(error))
      }
    }
    return false
  }
}

const deleteUserPending = (): Action => ({
  type: ActionTypes.DELETE_USER_PENDING,
})
const deleteUserError = (error: Error): Action => ({
  type: ActionTypes.DELETE_USER_ERROR,
  error,
})
const deleteUserSuccess = (userId: string): Action => ({
  type: ActionTypes.DELETE_USER_SUCCESS,
  payload: { userId },
})

const deleteUser = (userId: string): GenericThunk<Promise<boolean>> => {
  return async (dispatch: Dispatch): Promise<boolean> => {
    dispatch(deleteUserPending())

    try {
      const { data: response } = await api.deleteUser(userId)

      dispatch(deleteUserSuccess(userId))

      return response
    } catch (error) {
      if (error instanceof Error) {
        dispatch(deleteUserError(error))
      }
    }
    return false
  }
}

/**
 * Selectors
 */
const selectIsLoading = (state: AppState): boolean => state.users.isLoading
const selectIsLoadingEdit = (state: AppState): boolean => state.users.isLoadingEdit

const selectUsers = (state: AppState): User[] =>
  Object.values(state.users.users.byId)

const selectUserById = (id: string) =>
  createSelector(selectUsers, (users) => {
    const [user] = users.filter((user) => user._id === id)

    return user
  })

/**
 * Reducer
 */
const reducer: Reducer<State, Action> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case ActionTypes.CREATE_USER_PENDING:
    case ActionTypes.FETCH_USERS_PENDING:
    case ActionTypes.RESET_PASSWORD_PENDING:
    case ActionTypes.DELETE_USER_PENDING:
      return { ...state, isLoading: true }

    case ActionTypes.CREATE_USER_ERROR:
    case ActionTypes.FETCH_USERS_ERROR:
    case ActionTypes.RESET_PASSWORD_ERROR:
    case ActionTypes.DELETE_USER_ERROR:
    case ActionTypes.RESET_PASSWORD_SUCCESS:
      return { ...state, isLoading: false }

    case ActionTypes.DELETE_USER_SUCCESS: {
      const { userId } = action.payload
      const newById = { ...state.users.byId }
      delete newById[userId]

      return {
        ...state,
        users: {
          byId: newById,
          allIds: Object.keys(newById),
        },
        isLoading: false,
      }
    }

    case ActionTypes.SAVE_USERS: {
      const { users } = action.payload

      const base = action.meta.shouldReplace ? {} : { ...state.users.byId }

      const newById = users.reduce((acc, user) => {
        return { ...acc, [user._id]: user }
      }, base)

      return {
        ...state,
        isLoading: false,
        users: {
          byId: newById,
          allIds: Object.keys(newById),
        },
      }
    }

    case ActionTypes.EDIT_USER_PENDING:
      return { ...state, isLoading: true, isLoadingEdit: true }

    case ActionTypes.EDIT_USER_ERROR:
      return { ...state, isLoading: false, isLoadingEdit: false }

    case ActionTypes.EDIT_USER_SUCCESS:
      return { ...state, isLoading: false, isLoadingEdit: false }

    default:
      return state
  }
}

export default {
  types,
  actions: {
    createUser,
    createUserPending,
    createUserError,
    createUserSuccess,
    editUser,
    editUserPending,
    editUserError,
    editUserSuccess,
    saveUsers,
    fetchUsers,
    fetchUsersPending,
    fetchUsersError,
    fetchUsersSuccess,
    deleteUser,
    deleteUserPending,
    deleteUserError,
    deleteUserSuccess,
    resetPassword,
    resetPasswordPending,
    resetPasswordError,
    resetPasswordSuccess,
  },
  selectors: {
    selectIsLoading,
    selectIsLoadingEdit,
    selectUserById,
    selectUsers,
  },
  reducer,
}
