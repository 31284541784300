import { Dispatch } from 'redux'

import modules from 'modules'
import { AppAction } from 'typescript/common.types'
import { FlagConfig, FlagAppearance } from 'modules/core/core.types'
import { AuthenticateSuccessAction } from 'modules/auth/auth.types'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const middleware = () => (next: Dispatch) => (action: AppAction) => {
  const { type } = action

  const showFlag = (config: FlagConfig) =>
    next(
      modules.core.actions.showFlagAction({
        title: config.title,
        description: config.description,
        appearance: config.appearance,
      }),
    )

  /**
   * Authentication
   */
  if (type === modules.auth.types.ActionTypes.AUTHENTICATE_ERROR) {
    showFlag({
      title: 'Fehler bei der Anmeldung',
      description: 'Benutzername oder Passwort falsch.',
      appearance: FlagAppearance.Error,
    })
  }

  if (type === modules.auth.types.ActionTypes.AUTHENTICATE_SUCCESS) {
    const authenticateSuccessAction = action as AuthenticateSuccessAction

    showFlag({
      title: 'Anmeldung erfolgreich',
      description: `Willkommen zurück, ${authenticateSuccessAction.payload.user.firstName}.`,
      appearance: FlagAppearance.Success,
    })
  }

  /**
   * Entrainment
   */
  if (
    type === modules.entrainment.types.ActionTypes.CREATE_WAGON_ERROR ||
    type === modules.entrainment.types.ActionTypes.FETCH_WAGONS_ERROR ||
    type === modules.entrainment.types.ActionTypes.UPDATE_WAGON_ERROR
  ) {
    showFlag({
      title: 'Ein Fehler ist aufgetreten',
      description: 'Bitten überprüfen Sie Ihre Internetverbindung.',
      appearance: FlagAppearance.Error,
    })
  }

  if (type === modules.entrainment.types.ActionTypes.CREATE_WAGON_SUCCESS) {
    showFlag({
      title: 'Aktion erfolgreich',
      description: 'Der Wagon wurde erstellt.',
      appearance: FlagAppearance.Success,
    })
  }

  if (type === modules.entrainment.types.ActionTypes.UPDATE_WAGON_SUCCESS) {
    showFlag({
      title: 'Aktion erfolgreich',
      description: 'Die Wagondetails wurden aktualisiert.',
      appearance: FlagAppearance.Success,
    })
  }

  /**
   * Settings
   */
  if (type === modules.settings.types.ActionTypes.CHANGE_PASSWORD_ERROR) {
    showFlag({
      title: 'Ein Fehler ist aufgetreten',
      description: 'Bitten überprüfen Sie Ihre Eingaben.',
      appearance: FlagAppearance.Error,
    })
  }

  if (type === modules.settings.types.ActionTypes.CHANGE_PASSWORD_SUCCESS) {
    showFlag({
      title: 'Aktion erfolgreich',
      description: 'Das Benutzerpasswort wurde geändert.',
      appearance: FlagAppearance.Success,
    })
  }

  /**
   * Users
   */
  if (
    type === modules.users.types.ActionTypes.CREATE_USER_ERROR ||
    type === modules.users.types.ActionTypes.FETCH_USERS_ERROR ||
    type === modules.users.types.ActionTypes.DELETE_USER_ERROR ||
    type === modules.users.types.ActionTypes.RESET_PASSWORD_ERROR
  ) {
    showFlag({
      title: 'Ein Fehler ist aufgetreten',
      description: 'Bitten überprüfen Sie Ihre Internetverbindung.',
      appearance: FlagAppearance.Error,
    })
  }

  if (type === modules.users.types.ActionTypes.CREATE_USER_SUCCESS) {
    showFlag({
      title: 'Aktion erfolgreich',
      description: 'Der Benutzer wurde angelegt.',
      appearance: FlagAppearance.Success,
    })
  }

  if (type === modules.users.types.ActionTypes.RESET_PASSWORD_SUCCESS) {
    showFlag({
      title: 'Aktion erfolgreich',
      description: 'Das Passwort wurde zurückgesetzt.',
      appearance: FlagAppearance.Success,
    })
  }

  if (type === modules.users.types.ActionTypes.DELETE_USER_SUCCESS) {
    showFlag({
      title: 'Aktion erfolgreich',
      description: 'Der Benutzer wurde gelöscht.',
      appearance: FlagAppearance.Success,
    })
  }
  /**
   * Daily Reports
   */
  if (
    type === modules.dailyReports.types.ActionTypes.CREATE_DAILY_REPORT_ERROR ||
    type === modules.dailyReports.types.ActionTypes.FETCH_DAILY_REPORTS_ERROR ||
    type === modules.dailyReports.types.ActionTypes.DELETE_DAILY_REPORT_ERROR
  ) {
    showFlag({
      title: 'Ein Fehler ist aufgetreten',
      description: 'Bitten überprüfen Sie Ihre Internetverbindung.',
      appearance: FlagAppearance.Error,
    })
  }

  if (
    type === modules.dailyReports.types.ActionTypes.CREATE_DAILY_REPORT_SUCCESS
  ) {
    showFlag({
      title: 'Aktion erfolgreich',
      description: 'Der Tagesbericht wurde angelegt.',
      appearance: FlagAppearance.Success,
    })
  }

  if (
    type === modules.dailyReports.types.ActionTypes.DELETE_DAILY_REPORT_SUCCESS
  ) {
    showFlag({
      title: 'Aktion erfolgreich',
      description: 'Der Tagesbericht wurde gelöscht.',
      appearance: FlagAppearance.Success,
    })
  }

  /**
   * Checklist
   */
  if (
    type === modules.checklist.types.ActionTypes.CREATE_CHECKLIST_ERROR
  ) {
    showFlag({
      title: 'Ein Fehler ist aufgetreten',
      description: 'Bitten überprüfen Sie Ihre Internetverbindung.',
      appearance: FlagAppearance.Error,
    })
  }

  if (
    type === modules.checklist.types.ActionTypes.CREATE_CHECKLIST_SUCCESS
  ) {
    showFlag({
      title: 'Aktion erfolgreich',
      description: 'Eine neue Prüfung wurde erstellt.',
      appearance: FlagAppearance.Success,
    })
  }

  if (
    type === modules.checklist.types.ActionTypes.DELETE_CHECKLISTS_SUCCESS
  ) {
    showFlag({
      title: 'Aktion erfolgreich',
      description: 'Ausgewählte Datensätze wurden gelöscht.',
      appearance: FlagAppearance.Success,
    })
  }

  next(action)
}

export default middleware
