import { AxiosResponse } from 'axios'

import { DAILY_REPORTS_ENDPOINT } from 'config/server.config'
import { request } from 'utils/http.utils'
import { CreateDailyReportParameters, DailyReport } from './daily-reports.types'

export const createDailyReport = (
  dailyReport: CreateDailyReportParameters,
): Promise<AxiosResponse<DailyReport>> => {
  return request({
    method: 'post',
    url: DAILY_REPORTS_ENDPOINT,
    data: dailyReport,
  })
}

export const fetchDailyReports = (): Promise<AxiosResponse<DailyReport[]>> => {
  return request({
    method: 'get',
    url: DAILY_REPORTS_ENDPOINT,
  })
}

export const deleteDailyReport = (
  dailyReportId: string,
): Promise<AxiosResponse<boolean>> => {
  return request({
    method: 'delete',
    url: `${DAILY_REPORTS_ENDPOINT}/${dailyReportId}`,
  })
}

export const downloadDailyReport = (dailyReportId: string): Promise<void> => {
  return request({
    method: 'get',
    responseType: 'blob',
    url: `${DAILY_REPORTS_ENDPOINT}/${dailyReportId}/download`,
  }).then((blob) => {
    const url = window.URL.createObjectURL(new Blob([blob.data]))
    const a = document.createElement('a')
    a.href = url
    a.download = `Tagesbericht.pdf`
    a.click()
    a.remove()
    setTimeout(() => window.URL.revokeObjectURL(url), 100)
  })
}

export const transmitDailyReport = (
  dailyReportId: string,
): Promise<AxiosResponse<boolean>> => {
  return request({
    method: 'get',
    url: `${DAILY_REPORTS_ENDPOINT}/${dailyReportId}/transmit`,
  })
}
