import { Reducer, Dispatch } from 'redux'
import { createSelector } from 'reselect'

import { AppState, GenericThunk } from 'typescript/common.types'
import { contactSchema } from './contacts.schemas'
import * as api from './contacts.service'
import * as types from './contacts.types'
import {
  State,
  Contact,
  ActionTypes,
  Action,
  CreateContactParameters,
} from './contacts.types'

/**
 * Initial State
 */
const initialState: State = {
  contacts: {
    byId: {},
    allIds: [],
  },
  isLoading: false,
}

/**
 * Actions & Thunks
 */
const createContactPending = (): Action => ({
  type: ActionTypes.CREATE_CONTACT_PENDING,
})
const createContactError = (error: Error): Action => ({
  type: ActionTypes.CREATE_CONTACT_ERROR,
  error,
})
const createContactSuccess = (): Action => ({
  type: ActionTypes.CREATE_CONTACT_SUCCESS,
})
const saveContacts = (contacts: Contact[], shouldReplace: boolean): Action => ({
  type: ActionTypes.SAVE_CONTACTS,
  meta: { shouldReplace },
  payload: { contacts },
})

const createContact = (
  newContact: CreateContactParameters,
): GenericThunk<Promise<Contact | null>> => {
  return async (dispatch: Dispatch): Promise<Contact | null> => {
    dispatch(createContactPending())

    try {
      const { data: contact } = await api.createContact(newContact)

      contactSchema.validateSync(contact)
      dispatch(saveContacts([contact], false))
      dispatch(createContactSuccess())

      return contact
    } catch (error) {
      if (error instanceof Error) {
        dispatch(createContactError(error))
      }
    }
    return null
  }
}

const fetchContactsPending = (): Action => ({
  type: ActionTypes.FETCH_CONTACTS_PENDING,
})
const fetchContactsError = (error: Error): Action => ({
  type: ActionTypes.FETCH_CONTACTS_ERROR,
  error,
})
const fetchContactsSuccess = (): Action => ({
  type: ActionTypes.FETCH_CONTACTS_SUCCESS,
})

const fetchContacts = (): GenericThunk<Promise<Contact[]>> => {
  return async (dispatch: Dispatch): Promise<Contact[]> => {
    dispatch(fetchContactsPending())

    try {
      const { data: contacts } = await api.fetchContacts()

      contacts.forEach((contact) => contactSchema.validateSync(contact))

      dispatch(saveContacts(contacts, true))
      dispatch(fetchContactsSuccess())

      return contacts
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchContactsError(error))
      }
    }
    return []
  }
}

const deleteContactPending = (): Action => ({
  type: ActionTypes.DELETE_CONTACT_PENDING,
})
const deleteContactError = (error: Error): Action => ({
  type: ActionTypes.DELETE_CONTACT_ERROR,
  error,
})
const deleteContactSuccess = (contactId: string): Action => ({
  type: ActionTypes.DELETE_CONTACT_SUCCESS,
  payload: { contactId },
})

const deleteContact = (contactId: string): GenericThunk<Promise<boolean>> => {
  return async (dispatch: Dispatch): Promise<boolean> => {
    dispatch(deleteContactPending())

    try {
      const { data: response } = await api.deleteContact(contactId)

      dispatch(deleteContactSuccess(contactId))

      return response
    } catch (error) {
      if (error instanceof Error) {
        dispatch(deleteContactError(error))
      }
    }
    return false
  }
}

/**
 * Selectors
 */
const selectIsLoading = (state: AppState): boolean => state.contacts.isLoading

const selectContacts = (state: AppState): Contact[] =>
  Object.values(state.contacts.contacts.byId)

const selectContactById = (id: string) =>
  createSelector(selectContacts, (contacts) => {
    const [contact] = contacts.filter((contact) => contact._id === id)

    return contact
  })

/**
 * Reducer
 */
const reducer: Reducer<State, Action> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case ActionTypes.CREATE_CONTACT_PENDING:
    case ActionTypes.FETCH_CONTACTS_PENDING:
    case ActionTypes.DELETE_CONTACT_PENDING:
      return { ...state, isLoading: true }

    case ActionTypes.CREATE_CONTACT_ERROR:
    case ActionTypes.FETCH_CONTACTS_ERROR:
    case ActionTypes.DELETE_CONTACT_ERROR:
      return { ...state, isLoading: false }

    case ActionTypes.DELETE_CONTACT_SUCCESS: {
      const { contactId } = action.payload
      const newById = { ...state.contacts.byId }
      delete newById[contactId]

      return {
        ...state,
        contacts: {
          byId: newById,
          allIds: Object.keys(newById),
        },
        isLoading: false,
      }
    }

    case ActionTypes.SAVE_CONTACTS: {
      const { contacts } = action.payload

      const base = action.meta.shouldReplace ? {} : { ...state.contacts.byId }

      const newById = contacts.reduce((acc, contact) => {
        return { ...acc, [contact._id]: contact }
      }, base)

      return {
        ...state,
        isLoading: false,
        contacts: {
          byId: newById,
          allIds: Object.keys(newById),
        },
      }
    }

    default:
      return state
  }
}

export default {
  types,
  actions: {
    createContact,
    createContactPending,
    createContactError,
    createContactSuccess,
    saveContacts,
    fetchContacts,
    fetchContactsPending,
    fetchContactsError,
    fetchContactsSuccess,
    deleteContact,
    deleteContactPending,
    deleteContactError,
    deleteContactSuccess,
  },
  selectors: {
    selectIsLoading,
    selectContactById,
    selectContacts,
  },
  reducer,
}
