export enum ActionTypes {
  CHANGE_PASSWORD_PENDING = 'SETTINGS/CHANGE_PASSWORD_PENDING',
  CHANGE_PASSWORD_ERROR = 'SETTINGS/CHANGE_PASSWORD_ERROR',
  CHANGE_PASSWORD_SUCCESS = 'SETTINGS/CHANGE_PASSWORD_SUCCESS',
}

export interface State {
  isLoading: boolean
}

export interface ChangePasswordRequestData {
  oldPassword: string
  newPassword: string
}

export interface ChangePasswordPendingAction {
  type: ActionTypes.CHANGE_PASSWORD_PENDING
}

export interface ChangePasswordErrorAction {
  type: ActionTypes.CHANGE_PASSWORD_ERROR
  error: Error
}

export interface ChangePasswordSuccessAction {
  type: ActionTypes.CHANGE_PASSWORD_SUCCESS
}

export type Action =
  | ChangePasswordPendingAction
  | ChangePasswordErrorAction
  | ChangePasswordSuccessAction
