import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Page from '@atlaskit/page'
import AddIcon from '@atlaskit/icon/glyph/add'

import modules from 'modules'
import PageHeader from 'components/page-header.component'
import InstrumentsTable from './instruments-table.component'
import { CreateInstrumentParameters } from './instruments.types'
import CreateInstrumentModal from './create-instrument.modal'
import { LoadingButton } from '@atlaskit/button'

const InstrumentsScreen: React.FunctionComponent = (): JSX.Element => {
  const [isCreateInstrumentModalOpen, setIsCreateInstrumentModalOpen] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const isAdminUser = useSelector(
    modules.auth.selectors.selectIsAdminUser,
  )

  const userPermissions = useSelector(
    modules.auth.selectors.selectUserPermissions,
  )

  const instruments = useSelector(modules.instruments.selectors.selectInstruments)
  const isLoading = useSelector(modules.instruments.selectors.selectIsLoading)
  const isLoadingEdit = useSelector(modules.instruments.selectors.selectIsLoadingEdit)

  useEffect(() => {
    if (!userPermissions?.includes('instrumentsViewPermission')) {
      if(!isAdminUser) {
        history.replace('')
      }
    }
  }, [isAdminUser, userPermissions])

  useEffect(() => {
    dispatch(modules.instruments.actions.fetchInstruments())
  }, [dispatch, isLoadingEdit])

  const createInstrument = async (instrument: CreateInstrumentParameters) => {
    return dispatch(modules.instruments.actions.createInstrument(instrument))
  }

  return (
    <Page>
      <CreateInstrumentModal
        isOpen={isCreateInstrumentModalOpen}
        onClose={() => setIsCreateInstrumentModalOpen(false)}
        onSubmit={createInstrument}
      />
      <PageHeader
        title="Prüfmittelverwaltung"
        breadcrump="Prüfmittelverwaltung"
        actions={
          <LoadingButton
            onClick={() => setIsCreateInstrumentModalOpen(true)}
            isLoading={isLoading}
            appearance="primary"
            iconBefore={<AddIcon label="Prüfmittel anlegen" />}
          >
            Prüfmittel anlegen
          </LoadingButton>
        }
      />
      <InstrumentsTable instruments={instruments} onShowDetails={() => null} />
    </Page>
  )
}

export default InstrumentsScreen
