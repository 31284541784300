const cdGreen = '#1b9238' // Joos website green
const primary = '#2F3642'
const secondary = '#1A54C5'
const subtle = '#E6E8EB'
const critical = '#EC2F1A'
const warning = '#FFA02E'
const black = '#000000'
const white = '#ffffff'
const darkgrey = 'darkgrey'

export default {
  cdGreen,
  primary,
  secondary,
  subtle,
  critical,
  warning,
  black,
  white,
  darkgrey,
}
