// @ts-nocheck
import React, { Fragment, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up'
import TrashIcon from '@atlaskit/icon/glyph/trash'
import TextField from '@atlaskit/textfield'
import Button, { ButtonGroup } from '@atlaskit/button'
import { Flex } from 'reflexbox'
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
} from 'react-table'

import {
  formatChecklistStatus,
  formatDateTime,
} from 'utils/format.utils'
import modules from 'modules'
import { Checklist } from './checklist.types'
import DeleteChecklistsModal from './delete-checklists.modal'
import { boolean } from 'yup/lib/locale'

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: {
  preGlobalFilteredRows: any
  globalFilter: any
  setGlobalFilter: any
}) => {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <TextField
      isCompact
      width={300}
      placeholder={`${count} Einträge durchsuchen`}
      elemBeforeInput={<EditorSearchIcon label="Suchen" />}
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
    />
  )
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])
    
    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  },
)

interface Props {
  checklists: Checklist[]
  onShowDetails: (checklistId: string) => void
  deleteChecklists: (checklists: []) => void
}

interface TableProps {
  options: {
    columns: any
    data: any
  }
  onDeleteChecklists: (checklists: []) => void
}

const Table = ({ columns, data, onSelectionUpdate, onDeleteChecklists }: TableProps) => {
  const isAdminUser = useSelector(modules.auth.selectors.selectIsAdminUser)
  const isSuperadminUser = useSelector(modules.auth.selectors.selectIsSuperadminUser)

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state,

    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        sortBy: [{ id: 'createdAt', desc: true }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if(isAdminUser) {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ])
      }
    },
  )

  const { pageIndex, globalFilter, selectedRowIds } = state
  const dispatch = useDispatch()
  
  const [
    isDeleteChecklistsModalOpen,
    setIsDeleteChecklistsModalOpen,
  ] = useState(false)

  return (
    <Fragment>
      <DeleteChecklistsModal
        isOpen={isDeleteChecklistsModalOpen}
        onClose={() => setIsDeleteChecklistsModalOpen(false)}
        onSubmit={() => onDeleteChecklists(selectedFlatRows.map((row) => row.original.checklistId))}
      />
      <Flex justifyContent="flex-end">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        {isAdminUser&&
        <>
          &nbsp;
          <Button
            appearance="primary"
            isDisabled={!Object.keys(selectedRowIds).length}
            onClick={() => setIsDeleteChecklistsModalOpen(true)}
            iconBefore={<TrashIcon label="Auswahl löschen" />}
          >
            Auswahl löschen
          </Button>
        </>
        }
      </Flex>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <Flex>
                    {column.render('Header')}

                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ChevronDownIcon label="Absteigend sortieren" />
                      ) : (
                        <ChevronUpIcon label="Aufsteigend sortieren" />
                      )
                    ) : (
                      <div style={{ height: 24, width: 24 }}></div>
                    )}
                  </Flex>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((page: any) => {
            prepareRow(page)
            return (
              <tr {...page.getRowProps()}>
                {page.cells.map((cell: any) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      
      <br />
      <br />
      <Flex justifyContent="space-between">
        <ButtonGroup appearance="default">
          <Button onClick={() => gotoPage(0)} isDisabled={!canPreviousPage}>
            {'<<'} Erste
          </Button>
          <Button onClick={() => previousPage()} isDisabled={!canPreviousPage}>
            {'<'} Vorherige
          </Button>
          <Button onClick={() => nextPage()} isDisabled={!canNextPage}>
            Nächste {'>'}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            isDisabled={!canNextPage}
          >
            Letzte {'>>'}
          </Button>
        </ButtonGroup>
        <span>
          Seite{' '}
          <strong>
            {pageIndex + 1} von {pageOptions.length}
          </strong>{' '}
        </span>
      </Flex>
      <br />
      <br />
    </Fragment>
  )
}

const VehicleChecklistTable: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { checklists, onShowDetails, onSelectionUpdate, deleteChecklists } = props
  const dispatch = useDispatch()

  const formatChecklistResult = (checkResult: boolean) => {
    if(checkResult) {
      return <span className="text-red"><i class="fa-solid fa-circle-xmark"></i> Prüfung mit Mängel</span>
    }
    if(checkResult === false) {
      return <span className="text-green"><i class="fa-solid fa-circle-check"></i> Prüfung erfolgreich</span>
    }
  }

  const columns = useMemo(
    () => [
      /* {
        Header: 'checklistId',
        accessor: 'checklistId',
      }, */
      {
        Header: 'Erstellt von',
        accessor: 'createdBy',
      },
      {
        Header: 'Kennzeichen',
        accessor: 'vehiclePlate',
      },
      {
        Header: 'Erstellungsdatum',
        accessor: 'createdAt',
        Cell: ({ value }) => value.split('###')[1],
      },
      {
        Header: 'Bearbeitet',
        accessor: 'updatedAt',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Ergebnis',
        accessor: 'checkResult',
      },
      {
        Header: '',
        accessor: 'checklistId',
        Cell: ({
          row: {
            original: { checklistId },
          },
        }) => (
          <Flex justifyContent="flex-end">
            <Button appearance="link" onClick={() => onShowDetails(checklistId)}>
              Detailansicht
            </Button>
          </Flex>
        ),
      },
    ],
    [dispatch, onShowDetails],
  )

  const rows = checklists.map((checklist) => {
    const {
      _id,
      checklistType,
      checklistData,
      createdBy,
      status,
      vehiclePlate,
      checkResult,
      createdAt,
      updatedAt,
    } = checklist

    return {
      checklistId: _id,
      checklistType: checklistType,
      checklistData: checklistData,
      createdBy: createdBy?.firstName + ' ' + createdBy?.lastName,
      status: formatChecklistStatus(status),
      vehiclePlate: vehiclePlate,
      checkResult: formatChecklistResult(checkResult),
      createdAt: `${createdAt}###${formatDateTime(createdAt)}`,
      updatedAt: formatDateTime(updatedAt),
    }
  })

  return (
    <Table
      columns={columns}
      data={rows}
      onSelectionUpdate={onSelectionUpdate}
      onDeleteChecklists={deleteChecklists}
    />
  )
}

export default VehicleChecklistTable