import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Flex, Box } from 'reflexbox'
import styled from 'styled-components'
import Page from '@atlaskit/page'
import { useParams } from 'react-router-dom'
import { ButtonGroup, LoadingButton } from '@atlaskit/button'
import CheckIcon from '@atlaskit/icon/glyph/check'
import EmojiTravelIcon from '@atlaskit/icon/glyph/emoji/travel'
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove'
import FileIcon from '@atlaskit/icon/glyph/file'
import SubtaskIcon from '@atlaskit/icon/glyph/subtask'
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu'

import colors from 'config/colors.config'
import PageHeader from 'components/page-header.component'
import { useConfirmation } from 'components/confirmation-modal.service'
import Wagon from 'modules/entrainment/wagon.component'
import Hopper from 'modules/entrainment/hopper.component'
import modules from 'modules'
import { Status, HopperInputs, Damaged } from './entrainment.types'
import { downloadWagonReport } from './entrainment.service'
import LoadHopperModal from './load-hopper.modal'
import DamagedModal from './damaged.modal'

const HopperGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(256px, auto));
  grid-gap: 16px;
`

const InfoTitle = styled.h2`
  color: ${colors.primary};
  margin: 0;
`

const InfoText = styled.p`
  color: ${colors.primary};
  margin: 32px 0 0 0;
`

interface RouterParams {
  wagonId: string
}

const WagonScreen: React.FunctionComponent = (): JSX.Element | null => {
  const [isLoadHopperModalOpen, setIsLoadHopperModalOpen] = useState<
    string | null
  >(null)

  const [isDamagedModalOpen, setIsDamagedModalOpen] = useState(false)

  const confirm = useConfirmation()
  const dispatch = useDispatch()
  const params: RouterParams = useParams()

  const isLoading = useSelector(modules.entrainment.selectors.selectIsLoading)
  const isAdminUser = useSelector(modules.auth.selectors.selectIsAdminUser)
  const wagon = useSelector(
    modules.entrainment.selectors.selectWagonById(params.wagonId),
  )

  /**
   * Opens the damage modal if the damaged state is unchecked, but only
   * if the modal is not yet open.
   */
  useEffect(() => {
    if (!wagon) {
      dispatch(modules.entrainment.actions.fetchWagons(1,30))
    }
  }, [])

  if (!wagon) return null

  const { _id: wagonId, wagonNo, status, damaged } = wagon

  const isDamageModalForced = damaged === Damaged.Unchecked

  const administration = (
    <DropdownMenu trigger="Administration" appearance="tall">
      <DropdownItemGroup title="Allgemein">
        <DropdownItem
          elemBefore={<FileIcon label="Wagonzettel" />}
          onClick={() => downloadWagonReport(wagonId, wagonNo)}
        >
          Wagonzettel
        </DropdownItem>
        <DropdownItem
          elemBefore={<EditorRemoveIcon label="Löschen" />}
          onClick={() =>
            confirm({
              title: 'Wagon löschen?',
              description:
                'Der Wagon wird als gelöscht markiert und aus der Wagonübersicht entfernt. ' +
                'Er kann allerdings weiterhin in der Wagontabelle eingesehen werden.',
            })
              .then(() =>
                dispatch(modules.entrainment.actions.setWagonDeleted(wagonId)),
              )
              .catch(() => null)
          }
        >
          Löschen
        </DropdownItem>
      </DropdownItemGroup>
    </DropdownMenu>
  )

  const reportDamage = (
    <LoadingButton
      iconBefore={<SubtaskIcon label="Schadenmeldung" />}
      onClick={() => setIsDamagedModalOpen(true)}
    >
      Schadenmeldung
    </LoadingButton>
  )

  let actions: any
  if (status === Status.Supplied) {
    actions = (
      <ButtonGroup>
        {reportDamage}
        {isAdminUser ? administration : null}
        <LoadingButton
          isLoading={isLoading}
          onClick={() =>
            confirm({
              title: 'Wagon als abholbereit markieren?',
              description: 'Der Wagon ist damit nicht länger befüllbar.',
            })
              .then(() =>
                dispatch(modules.entrainment.actions.setWagonFinished(wagonId)),
              )
              .catch(() => null)
          }
          appearance="primary"
          iconBefore={<CheckIcon label="Abholbereit" />}
        >
          Abholbereit
        </LoadingButton>
      </ButtonGroup>
    )
  } else if (status === Status.Finished) {
    actions = (
      <ButtonGroup>
        {reportDamage}
        {isAdminUser ? administration : null}
        <LoadingButton
          isLoading={isLoading}
          onClick={() =>
            confirm({
              title: 'Wagon als abgeholt markieren?',
              description:
                'Der Wagon wird damit aus der Wagonübersicht entfernt.',
            })
              .then(() =>
                dispatch(
                  modules.entrainment.actions.setWagonCollected(wagonId),
                ),
              )
              .catch(() => null)
          }
          appearance="primary"
          iconBefore={<EmojiTravelIcon label="Bahnabholung" />}
        >
          Bahnabholung
        </LoadingButton>
      </ButtonGroup>
    )
  } else if (status === Status.Collected) {
    actions = (
      <ButtonGroup>
        {reportDamage}
        {isAdminUser ? administration : null}
      </ButtonGroup>
    )
  }

  const updateHopper = async (hopper: HopperInputs) => {
    const { hopperId, grossWeight, netWeight, position, weightNr } = hopper

    return dispatch(
      modules.entrainment.actions.updateHopper(wagonId, hopperId, {
        grossWeight,
        netWeight,
        position,
        weightNr,
      }),
    )
  }

  return (
    <Page>
      <DamagedModal
        isForced={isDamageModalForced}
        isModalOpen={isDamagedModalOpen}
        onClose={() => setIsDamagedModalOpen(false)}
        onYesClick={() => {
          setIsDamagedModalOpen(false)
          dispatch(
            modules.entrainment.actions.setWagonDamagedDocumented(wagonId),
          )
        }}
        onNoClick={() => {
          setIsDamagedModalOpen(false)
          dispatch(modules.entrainment.actions.setWagonDamagedNone(wagonId))
        }}
      />
      <LoadHopperModal
        isOpenHopperId={isLoadHopperModalOpen}
        onClose={() => setIsLoadHopperModalOpen(null)}
        onSubmit={updateHopper}
      />
      <PageHeader
        breadcrump="Wagonverladung"
        title={'Wagon Nr. ' + wagonNo}
        actions={actions}
      />
      <Flex flexDirection="column">
        <Flex mb={5}>
          <Box width={[4 / 9]}>
            <InfoTitle>Befüllung der einzelnen Mulden</InfoTitle>
            <InfoText>
              Auf der rechten Seite wird der ausgewähle Wagon mit aktuellem
              Stand dargestellt. Darunter befinden sich die acht dazugehörigen
              Mulden. Mittels Tippen oder Klicken auf eine der Mulden, sowie der
              Eingabe des
              <b> Bruttogewichtes</b> und der <b>Position</b>, können deren
              Beladung dokumentiert und damit sämtliche weitere Berechnungen
              automatisiert werden.
            </InfoText>
          </Box>
          <Box width={[1 / 9]} />
          <Box width={[4 / 9]}>
            <Wagon {...wagon} />
          </Box>
        </Flex>
        <HopperGrid>
          {wagon.hoppers.map((hopper, index) => {
            const { _id: hopperId, netWeight, grossWeight, tara, position, weightNr } = hopper

            return (
              <Hopper
                index={index}
                netWeight={netWeight}
                grossWeight={grossWeight}
                tara={tara}
                position={position}
                weightNr={weightNr}
                isEditable={status === Status.Supplied}
                onClick={() => setIsLoadHopperModalOpen(hopperId)}
              />
            )
          })}
        </HopperGrid>
      </Flex>
    </Page>
  )
}

export default WagonScreen
