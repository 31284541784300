import React from 'react'
import { Flex } from 'reflexbox'
import styled from 'styled-components'

import colors from 'config/colors.config'
import { formatNumber } from 'utils/format.utils'

const Container = styled.div`
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  border-top: 4px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  :hover {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  }
`

const Header = styled(Flex)`
  padding: 12px 16px;
`

const Title = styled.h5`
  font-size: 14px;
  color: ${colors.primary};
  margin: 0;
`

const Pos = styled.h5`
  font-size: 14px;
  margin: 0;
`

const Content = styled(Flex)`
  padding: 8px 16px;
`

const NetWeight = styled.h4`
  margin: 8px 0;
`

const Info = styled.span`
  margin: 4px 0;
  font-size: 14px;
`

interface Props {
  index: number
  netWeight: number
  grossWeight: number
  tara: number
  position: string
  weightNr: string
  isEditable: boolean
  onClick: () => void
}

const Hopper: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { index, netWeight, grossWeight, tara, position, weightNr, isEditable, onClick } = props

  //const netWeight = grossWeight > 0 ? grossWeight - tara : 0
  const isEmpty = netWeight <= 0
  const hasDisabledStyle = isEmpty || !isEditable

  return (
    <Container
      onClick={isEditable ? onClick : () => null}
      style={{
        opacity: hasDisabledStyle ? 0.55 : 1,
        border: hasDisabledStyle ? 'none' : '',
        cursor: isEditable ? 'pointer' : 'default',
      }}
    >
      <Header justifyContent="space-between">
        <Title>Mulde {index + 1}</Title>
        <Pos>{isEmpty ? null : position}</Pos>
      </Header>
      <Content flexDirection="column">
        {/* <Flex justifyContent="space-between">
          <Info>Bruttogewicht:</Info>
          <Info>{formatNumber(Math.max(grossWeight, tara))} kg</Info>
        </Flex> */}
        <Flex justifyContent="space-between">
          <Info>Wiegeschein-Nr.:</Info>
          <Info>{weightNr}</Info>
        </Flex>
        <Flex justifyContent="space-between">
          <Info>Nettogewicht:</Info>
          <NetWeight>{formatNumber(netWeight)} kg</NetWeight>
        </Flex>
      </Content>
    </Container>
  )
}

export default Hopper
