import React from 'react'
import styled from 'styled-components'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { Field, FormFooter } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import Select, { ValueType } from '@atlaskit/select'
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'

import { cleanNumber } from 'utils/format.utils'
import { Material, CreateWagonRequestData } from './entrainment.types'

const FooterButton = styled(Button)`
  margin-bottom: 20px;
`

interface OptionType {
  label: Material
  value: Material
}

interface FormProps {
  wagonNo: string
  material: OptionType
  limit: string
}

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: (fields: CreateWagonRequestData) => void
}

const materials: OptionType[] = [
  { label: Material.E8, value: Material.E8 },
  { label: Material.STW, value: Material.STW },
  { label: Material.Misch, value: Material.Misch },
  { label: Material.CroNiMo, value: Material.CroNiMo },
  { label: Material.C10, value: Material.C10 },
]

const CreateWagonModal: React.FunctionComponent<Props> = (
  props,
): JSX.Element => {
  const { isOpen, onClose, onSubmit } = props

  const onSubmitClick = (fields: FormProps) => {
    const { wagonNo, limit, material } = fields

    onSubmit({
      wagonNo,
      limit: cleanNumber(limit),
      material: material.value,
    })
    onClose()
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} shouldCloseOnOverlayClick={false}>
          <Form<FormProps> onSubmit={(v) => onSubmitClick(v)}>
            {({ formProps }) => (
              <form {...formProps}>
                <ModalHeader>
                  <ModalTitle>Wagon anlegen</ModalTitle>
                </ModalHeader>

                <ModalBody>
                  <Field name="wagonNo" label="Wagon Nr." isRequired>
                    {({ fieldProps }) => (
                      <MaskedInput
                        mask={[
                          /[0-9]/,
                          /[0-9]/,
                          '-',
                          /[0-9]/,
                          /[0-9]/,
                          '-',
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          /[0-9]/,
                          '-',
                          /[0-9]/,
                        ]}
                        guide={true}
                        {...fieldProps}
                        render={(ref, props) => (
                          <TextField
                            ref={ref}
                            {...(props as any)}
                            autoComplete="off"
                            placeholder="Bindestriche werden automatisch eingefügt"
                          />
                        )}
                      />
                    )}
                  </Field>

                  <br />

                  <Field name="limit" label="Ladegrenze" isRequired>
                    {({ fieldProps }) => (
                      <MaskedInput
                        mask={createNumberMask({
                          prefix: '',
                          suffix: ' kg',
                          thousandsSeparatorSymbol: '.',
                        })}
                        {...fieldProps}
                        render={(ref, props) => (
                          <TextField
                            ref={ref}
                            {...(props as any)}
                            autoComplete="off"
                            placeholder="In Kilogramm angeben"
                          />
                        )}
                      />
                    )}
                  </Field>

                  <br />

                  <Field<ValueType<OptionType, false>>
                    name="material"
                    label="Material"
                    defaultValue={materials[0]}
                  >
                    {({ fieldProps }) => (
                      <Select<OptionType>
                        options={materials}
                        placeholder="Bitte auswählen"
                        isSearchable={false}
                        menuPlacement="top"
                        {...fieldProps}
                      />
                    )}
                  </Field>

                  <FormFooter>
                    <ButtonGroup>
                      <FooterButton onClick={onClose}>Abbrechen</FooterButton>
                      <FooterButton type="submit" appearance="primary">
                        anlegen
                      </FooterButton>
                    </ButtonGroup>
                  </FormFooter>
                </ModalBody>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default CreateWagonModal
