import { AxiosResponse } from 'axios'

import { USERS_ENDPOINT } from 'config/server.config'
import { request } from 'utils/http.utils'
import { CreateUserParameters, User } from './users.types'

export const createUser = (
  user: CreateUserParameters,
): Promise<AxiosResponse<User>> => {
  return request({
    method: 'post',
    url: USERS_ENDPOINT,
    data: user,
  })
}

export const editUser = (
  user: User,
): Promise<AxiosResponse<User>> => {
  return request({
    method: 'put',
    url: `${USERS_ENDPOINT}/${user._id}`,
    data: user,
  })
}

export const fetchUsers = (): Promise<AxiosResponse<User[]>> => {
  return request({
    method: 'get',
    url: USERS_ENDPOINT,
  })
}

export const deleteUser = (userId: string): Promise<AxiosResponse<boolean>> => {
  return request({
    method: 'delete',
    url: `${USERS_ENDPOINT}/${userId}`,
  })
}

export const resetPassword = (
  userId: string,
  newPassword: string,
): Promise<AxiosResponse<boolean>> => {
  return request({
    method: 'post',
    url: `${USERS_ENDPOINT}/${userId}/reset-password`,
    data: {
      newPassword,
    },
  })
}
