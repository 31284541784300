import { AxiosResponse } from 'axios'
import { FTP_DOCUMENTS_ENDPOINT } from 'config/server.config'
import { request } from 'utils/http.utils'

export const listDirectory = (
  path: string,
): Promise<AxiosResponse<any>> => {
  return request({
    method: 'post',
    url: `${FTP_DOCUMENTS_ENDPOINT}/list`,
    data: { path }
  })
}

export const downloadPath = (
  path: string,
): Promise<AxiosResponse<any>> => {
  return request({
    method: 'post',
    url: `${FTP_DOCUMENTS_ENDPOINT}/download`,
    data: { path }
  })
}