import * as yup from 'yup'
import { UserRole } from './users.types'

export const userSchema = yup.object({
  _id: yup.string().required(),
  role: yup.string().oneOf(Object.values(UserRole)).required(),
  firstName: yup.string(),
  lastName: yup.string(),
  createdAt: yup.date().required(),
  updatedAt: yup.date().required(),
})
