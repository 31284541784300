import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from '@atlaskit/page'

import modules from 'modules'
import PageHeader from 'components/page-header.component'
import VehicleChecklistTable from '../vehicle-checklist-table.component'
import CreateChecklistModal from '../create-checklist.modal'
import { CreateChecklistRequestData, ChecklistTypes } from '../checklist.types'

import { ButtonGroup, LoadingButton } from '@atlaskit/button'
import AddIcon from '@atlaskit/icon/glyph/add'
import { useHistory } from 'react-router-dom'

const ExcavatorChecklistScreen: React.FunctionComponent = (): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isLoading = useSelector(modules.checklist.selectors.selectIsLoading)
  const isLoadingDelete = useSelector(modules.checklist.selectors.selectIsLoadingDelete)

  const isAdminUser = useSelector(modules.auth.selectors.selectIsAdminUser)
  const [isCreateChecklistModalOpen, setIsCreateChecklistModalOpen] = useState(
    false,
  )
  const user = useSelector(modules.auth.selectors.selectUser)

  const userPermissions = useSelector(
    modules.auth.selectors.selectUserPermissions,
  )

  useEffect(() => {
    // TODO: refactor for each screen.
    if (!userPermissions?.includes('excavatorViewPermission')) {
      if (isAdminUser) {
        //console.log("access ok.")
      } else {
        history.replace('')
      }
    }
  }, [isAdminUser, userPermissions])

  const checklists = useSelector(
    modules.checklist.selectors.selectFilteredChecklists(
      modules.checklist.types.Filter.All,
    ),
  )

  useEffect(() => {
    if (isAdminUser && user) {
      dispatch(modules.checklist.actions.fetchChecklists([ChecklistTypes.Excavator]))
    }
    if (!isAdminUser && user) {
      dispatch(modules.checklist.actions.fetchChecklistsByUser(user._id, [ChecklistTypes.Excavator]))
    }
  }, [dispatch, isLoadingDelete])

  const createChecklist = async (checklist: CreateChecklistRequestData) => {
    const { checklistType, checklistData } = checklist

    return dispatch(
      modules.checklist.actions.createChecklist(
        {
          checklistType,
          checklistData,
        },
        history,
      ),
    )
  }

  const showDetails = (checklistId: string) => {
    history.push('/checklists/details/' + checklistId)
  }

  const deleteChecklists = (checklists: []) => {
    dispatch(modules.checklist.actions.deleteChecklists(checklists))
  }

  const createChecklistButton = (
    <LoadingButton
      onClick={() => setIsCreateChecklistModalOpen(true)}
      isLoading={isLoading}
      appearance="primary"
      iconBefore={<AddIcon label="Prüfung durchführen" />}
    >
      Baggerprüfung durchführen
    </LoadingButton>
  )

  return (
    <Page>
      <CreateChecklistModal
        modalName="Baggerprüfung"
        checklistType={ChecklistTypes.Excavator}
        isOpen={isCreateChecklistModalOpen}
        onClose={() => setIsCreateChecklistModalOpen(false)}
        onSubmit={createChecklist}
      />
      <PageHeader
        breadcrump="Checklisten"
        title="Baggerprüfung"
        actions={<ButtonGroup>{createChecklistButton}</ButtonGroup>}
      />
      <Fragment>
        <VehicleChecklistTable
          checklists={checklists}
          onShowDetails={showDetails}
          deleteChecklists={deleteChecklists}
        />
      </Fragment>
    </Page>
  )
}

export default ExcavatorChecklistScreen
