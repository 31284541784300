class JWT {
  private token: string | null = null

  save = async (token: string): Promise<void> => {
    await localStorage.setItem('jwt', token)
    this.token = token
  }

  read = async (): Promise<string | null> => {
    if (this.token) return this.token

    const token = await localStorage.getItem('jwt')
    this.token = token

    return token
  }

  delete = async () => {
    await localStorage.removeItem('jwt')
    this.token = null
  }
}

export default new JWT()
