export enum ActionTypes {
  CREATE_INSTRUMENT_PENDING = 'INSTRUMENTS/CREATE_INSTRUMENT_PENDING',
  CREATE_INSTRUMENT_ERROR = 'INSTRUMENTS/CREATE_INSTRUMENT_ERROR',
  CREATE_INSTRUMENT_SUCCESS = 'INSTRUMENTS/CREATE_INSTRUMENT_SUCCESS',
  EDIT_INSTRUMENT_PENDING = 'INSTRUMENTS/EDIT_INSTRUMENT_PENDING',
  EDIT_INSTRUMENT_ERROR = 'INSTRUMENTS/EDIT_INSTRUMENT_ERROR',
  EDIT_INSTRUMENT_SUCCESS = 'INSTRUMENTS/EDIT_INSTRUMENT_SUCCESS',
  FETCH_INSTRUMENTS_PENDING = 'INSTRUMENTS/FETCH_INSTRUMENTS_PENDING',
  FETCH_INSTRUMENTS_ERROR = 'INSTRUMENTS/FETCH_INSTRUMENTS_ERROR',
  FETCH_INSTRUMENTS_SUCCESS = 'INSTRUMENTS/FETCH_INSTRUMENTS_SUCCESS',
  DELETE_INSTRUMENTS_PENDING = 'INSTRUMENTS/DELETE_INSTRUMENTS_PENDING',
  DELETE_INSTRUMENTS_ERROR = 'INSTRUMENTS/DELETE_INSTRUMENTS_ERROR',
  DELETE_INSTRUMENTS_SUCCESS = 'INSTRUMENTS/DELETE_INSTRUMENTS_SUCCESS',
  SAVE_INSTRUMENTS = 'INSTRUMENTS/SAVE_INSTRUMENTS',
}

/**
 * Instrument
 */
export interface Instrument {
  _id: string
  instrumentName: string
  instrumentTag: string
  lastCheckDate: Date
  nextCheckDate: Date
  instrumentCheckType: string
  instrumentSerial: string
  createdAt: Date
  updatedAt: Date
}

export interface State {
  isLoading: boolean
  isLoadingEdit: boolean
  instruments: {
    byId: {
      [id: string]: Instrument
    }
    allIds: string[]
  }
}

export interface CreateInstrumentParameters {
  instrumentName: string
  instrumentTag: string
  lastCheckDate: Date
  nextCheckDate: Date
  instrumentCheckType: string
  instrumentSerial: string
}

export interface CreateInstrumentPendingAction {
  type: ActionTypes.CREATE_INSTRUMENT_PENDING
}

export interface CreateInstrumentErrorAction {
  type: ActionTypes.CREATE_INSTRUMENT_ERROR
  error: Error
}

export interface CreateInstrumentSuccessAction {
  type: ActionTypes.CREATE_INSTRUMENT_SUCCESS
}

export interface EditInstrumentPendingAction {
  type: ActionTypes.EDIT_INSTRUMENT_PENDING
}

export interface EditInstrumentErrorAction {
  type: ActionTypes.EDIT_INSTRUMENT_ERROR
  error: Error
}

export interface EditInstrumentSuccessAction {
  type: ActionTypes.EDIT_INSTRUMENT_SUCCESS
}

export interface FetchInstrumentsPendingAction {
  type: ActionTypes.FETCH_INSTRUMENTS_PENDING
}

export interface FetchInstrumentsErrorAction {
  type: ActionTypes.FETCH_INSTRUMENTS_ERROR
  error: Error
}

export interface FetchInstrumentsSuccessAction {
  type: ActionTypes.FETCH_INSTRUMENTS_SUCCESS
}

export interface DeleteInstrumentsPendingAction {
  type: ActionTypes.DELETE_INSTRUMENTS_PENDING
}

export interface DeleteInstrumentsErrorAction {
  type: ActionTypes.DELETE_INSTRUMENTS_ERROR
  error: Error
}

export interface DeleteInstrumentsSuccessAction {
  type: ActionTypes.DELETE_INSTRUMENTS_SUCCESS
  payload: {
    instrumentId: string
  }
}

export interface SaveInstrumentsAction {
  type: ActionTypes.SAVE_INSTRUMENTS
  meta: {
    shouldReplace: boolean
  }
  payload: {
    instruments: Instrument[]
  }
}

export type Action =
  | CreateInstrumentPendingAction
  | CreateInstrumentErrorAction
  | CreateInstrumentSuccessAction
  | EditInstrumentPendingAction
  | EditInstrumentErrorAction
  | EditInstrumentSuccessAction
  | FetchInstrumentsPendingAction
  | FetchInstrumentsErrorAction
  | FetchInstrumentsSuccessAction
  | DeleteInstrumentsPendingAction
  | DeleteInstrumentsErrorAction
  | DeleteInstrumentsSuccessAction
  | SaveInstrumentsAction