import { Reducer, Dispatch } from 'redux'
import { createSelector } from 'reselect'

import { AppState, GenericThunk } from 'typescript/common.types'
import { containerSchema } from './containers.schemas'
import * as api from './containers.service'
import * as types from './containers.types'
import {
  State,
  Container,
  ActionTypes,
  Action,
  CreateContainerParameters,
} from './containers.types'

/**
 * Initial State
 */
const initialState: State = {
  containers: {
    byId: {},
    allIds: [],
  },
  isLoading: false,
  isLoadingEdit: false,
}

/**
 * Actions & Thunks
 */
const createContainerPending = (): Action => ({
  type: ActionTypes.CREATE_CONTAINER_PENDING,
})
const createContainerError = (error: Error): Action => ({
  type: ActionTypes.CREATE_CONTAINER_ERROR,
  error,
})
const createContainerSuccess = (): Action => ({
  type: ActionTypes.CREATE_CONTAINER_SUCCESS,
})
const saveContainers = (containers: Container[], shouldReplace: boolean): Action => ({
  type: ActionTypes.SAVE_CONTAINERS,
  meta: { shouldReplace },
  payload: { containers },
})

const createContainer = (
  newContainer: CreateContainerParameters,
): GenericThunk<Promise<Container | null>> => {
  return async (dispatch: Dispatch): Promise<Container | null> => {
    dispatch(createContainerPending())

    try {
      const { data: container } = await api.createContainer(newContainer)

      containerSchema.validateSync(container)
      dispatch(saveContainers([container], false))
      dispatch(createContainerSuccess())

      return container
    } catch (error) {
      if (error instanceof Error) {
        dispatch(createContainerError(error))
      }
    }
    return null
  }
}

const editContainerPending = (): Action => ({
  type: ActionTypes.EDIT_CONTAINER_PENDING,
})
const editContainerError = (error: Error): Action => ({
  type: ActionTypes.EDIT_CONTAINER_ERROR,
  error,
})
const editContainerSuccess = (): Action => ({
  type: ActionTypes.EDIT_CONTAINER_SUCCESS,
})
const editContainer = (editContainer: Container): GenericThunk<Promise<Container | null>> => {
  return async (dispatch: Dispatch): Promise<Container | null> => {
    dispatch(editContainerPending())

    try {
      const { data: container } = await api.editContainer(editContainer)

      containerSchema.validateSync(container)
      dispatch(saveContainers([container], true))
      dispatch(editContainerSuccess())

      return container
    } catch (error) {
      if (error instanceof Error) {
        dispatch(editContainerError(error))
      }
    }
    return null
  }
}

const fetchContainersPending = (): Action => ({
  type: ActionTypes.FETCH_CONTAINERS_PENDING,
})
const fetchContainersError = (error: Error): Action => ({
  type: ActionTypes.FETCH_CONTAINERS_ERROR,
  error,
})
const fetchContainersSuccess = (): Action => ({
  type: ActionTypes.FETCH_CONTAINERS_SUCCESS,
})

const fetchContainers = (): GenericThunk<Promise<Container[]>> => {
  return async (dispatch: Dispatch): Promise<Container[]> => {
    dispatch(fetchContainersPending())

    try {
      const { data: containers } = await api.fetchContainers()

      containers.forEach((container) => containerSchema.validateSync(container))

      dispatch(saveContainers(containers, true))
      dispatch(fetchContainersSuccess())

      return containers
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchContainersError(error))
      }
    }
    return []
  }
}

const deleteContainersPending = (): Action => ({
  type: ActionTypes.DELETE_CONTAINERS_PENDING,
})
const deleteContainersError = (error: Error): Action => ({
  type: ActionTypes.DELETE_CONTAINERS_ERROR,
  error,
})
const deleteContainersSuccess = (containerId: string): Action => ({
  type: ActionTypes.DELETE_CONTAINERS_SUCCESS,
  payload: { containerId },
})

const deleteContainers = (containerId: string): GenericThunk<Promise<boolean>> => {
  return async (dispatch: Dispatch): Promise<boolean> => {
    dispatch(deleteContainersPending())

    try {
      const { data: response } = await api.deleteContainer(containerId)

      dispatch(deleteContainersSuccess(containerId))

      return response
    } catch (error) {
      if (error instanceof Error) {
        dispatch(deleteContainersError(error))
      }
    }
    return false
  }
}

/**
 * Selectors
 */
const selectIsLoading = (state: AppState): boolean => state.containers.isLoading
const selectIsLoadingEdit = (state: AppState): boolean => state.containers.isLoadingEdit

const selectContainers = (state: AppState): Container[] =>
  Object.values(state.containers.containers.byId)

const selectContainerById = (id: string) =>
  createSelector(selectContainers, (containers) => {
    const [container] = containers.filter((container) => container._id === id)

    return container
  })

/**
 * Reducer
 */
const reducer: Reducer<State, Action> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case ActionTypes.CREATE_CONTAINER_PENDING:
    case ActionTypes.FETCH_CONTAINERS_PENDING:
    case ActionTypes.DELETE_CONTAINERS_PENDING:
      return { ...state, isLoading: true }

    case ActionTypes.CREATE_CONTAINER_ERROR:
    case ActionTypes.FETCH_CONTAINERS_ERROR:
    case ActionTypes.DELETE_CONTAINERS_ERROR:
      return { ...state, isLoading: false }

    case ActionTypes.DELETE_CONTAINERS_SUCCESS: {
      const { containerId } = action.payload
      const newById = { ...state.containers.byId }
      delete newById[containerId]

      return {
        ...state,
        containers: {
          byId: newById,
          allIds: Object.keys(newById),
        },
        isLoading: false,
      }
    }

    case ActionTypes.SAVE_CONTAINERS: {
      const { containers } = action.payload

      const base = action.meta.shouldReplace ? {} : { ...state.containers.byId }

      const newById = containers.reduce((acc, container) => {
        return { ...acc, [container._id]: container }
      }, base)

      return {
        ...state,
        isLoading: false,
        containers: {
          byId: newById,
          allIds: Object.keys(newById),
        },
      }
    }

    case ActionTypes.EDIT_CONTAINER_PENDING:
      return { ...state, isLoading: true, isLoadingEdit: true }

    case ActionTypes.EDIT_CONTAINER_ERROR:
      return { ...state, isLoading: false, isLoadingEdit: false }

    case ActionTypes.EDIT_CONTAINER_SUCCESS:
      return { ...state, isLoading: false, isLoadingEdit: false }

    default:
      return state
  }
}

export default {
  types,
  actions: {
    createContainer,
    createContainerPending,
    createContainerError,
    createContainerSuccess,
    editContainer,
    editContainerPending,
    editContainerError,
    editContainerSuccess,
    saveContainers,
    fetchContainers,
    fetchContainersPending,
    fetchContainersError,
    fetchContainersSuccess,
    deleteContainers,
    deleteContainersPending,
    deleteContainersError,
    deleteContainersSuccess,
  },
  selectors: {
    selectIsLoading,
    selectIsLoadingEdit,
    selectContainerById,
    selectContainers,
  },
  reducer,
}
