import React, { useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox'
import Page from '@atlaskit/page'
import EmptyState from '@atlaskit/empty-state'
import { ButtonGroup, LoadingButton } from '@atlaskit/button'
import AddIcon from '@atlaskit/icon/glyph/add'
import CheckIcon from '@atlaskit/icon/glyph/check'
import BitbucketCompareIcon from '@atlaskit/icon/glyph/bitbucket/compare'
import EmojiTravelIcon from '@atlaskit/icon/glyph/emoji/travel'
import FileIcon from '@atlaskit/icon/glyph/file'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import modules from 'modules'
import PageHeader from 'components/page-header.component'
import Wagon from 'modules/entrainment/wagon.component'
import DailyReportTable from 'modules/daily-reports/daily-reports-table.component'
import {
  downloadDailyReport,
  transmitDailyReport,
} from 'modules/daily-reports/daily-reports.service'
import CreateWagonModal from './create-wagon.modal'
import WagonTable from './wagon-table.component'
import { CreateWagonRequestData, Wagon as IWagon } from './entrainment.types'
import colors from 'config/colors.config'

const InfoTitle = styled.h2`
  color: ${colors.primary};
  margin: 0;
`

const InfoText = styled.p`
  color: ${colors.primary};
  margin: 32px 0 0 0;
`

const getWagonGridStyles = () => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(400px, auto))',
  gridGap: '16px',
})

const EntrainmentScreen: React.FunctionComponent = (): JSX.Element => {
  const [isCreateWagonModalOpen, setIsCreateWagonModalOpen] = useState(false)
  const [isArrangeModeOn, setIsArrangeModeOn] = useState(false)
  const [arrangedWagons, setArrangedWagons] = useState<IWagon[]>([])
  const [tableView, setTableView] = useState(0)

  const history = useHistory()
  const dispatch = useDispatch()
  const isLoading = useSelector(modules.entrainment.selectors.selectIsLoading)
  const isAdminUser = useSelector(modules.auth.selectors.selectIsAdminUser)

  const userPermissions = useSelector(
    modules.auth.selectors.selectUserPermissions,
  )

  useEffect(() => {
    // TODO: refactor for each screen.
    if (!userPermissions?.includes('entrainmentViewPermission')) {
      if (isAdminUser) {
        //console.log("access ok.")
      } else {
        history.replace('')
      }
    }
  }, [isAdminUser, userPermissions, history])

  const dailyReports = useSelector(
    modules.dailyReports.selectors.selectDailyReports,
  )
  const wagons = useSelector(
    modules.entrainment.selectors.selectFilteredWagons(
      modules.entrainment.types.Filter.All,
    ),
  )

  //const pagination = useSelector(modules.entrainment.selectors.selectPagination)

  const filteredWagons = useSelector(
    modules.entrainment.selectors.selectFilteredWagons(
      modules.entrainment.types.Filter.Current,
    ),
  )

  const currentWagons = arrangedWagons.length ? arrangedWagons : filteredWagons

  useEffect(() => {
    dispatch(modules.entrainment.actions.fetchWagons(1,30))
    if (isAdminUser) {
      dispatch(modules.contacts.actions.fetchContacts())
      dispatch(modules.dailyReports.actions.fetchDailyReports())
    }
  }, [dispatch, isAdminUser])

  const createWagon = async (wagon: CreateWagonRequestData) => {
    const { wagonNo, limit, material } = wagon

    return dispatch(
      modules.entrainment.actions.createWagon({
        wagonNo,
        limit,
        material,
      }),
    )
  }

  const showDetails = (wagonId: string) =>
    history.push('/entrainment/wagons/' + wagonId)

  const createWagonButton = (
    <LoadingButton
      onClick={() => setIsCreateWagonModalOpen(true)}
      isLoading={isLoading}
      appearance="primary"
      iconBefore={<AddIcon label="Wagon anlegen" />}
    >
      Wagon anlegen
    </LoadingButton>
  )

  const arrangeWagonsToggle = (
    <LoadingButton
      onClick={() => {
        if (isArrangeModeOn && arrangedWagons.length) {
          const wagonUpdates = arrangedWagons.map((wagon, index) => ({
            wagonId: wagon._id,
            updates: {
              position: index,
            },
          }))

          dispatch(modules.entrainment.actions.updateWagons(wagonUpdates))
          setTimeout(() => setArrangedWagons([]), 5000)
        }

        setIsArrangeModeOn(!isArrangeModeOn)
      }}
      isDisabled={isLoading}
      iconBefore={
        isArrangeModeOn ? (
          <CheckIcon label="Beladung fortsetzen" />
        ) : (
          <BitbucketCompareIcon label="Reihenfolge Anpassen" />
        )
      }
    >
      {isArrangeModeOn ? 'Beladung Fortsetzen' : 'Reihenfolge Anpassen'}
    </LoadingButton>
  )

  let arrangableWagonGrid: any = null
  let loadableWagonGrid: any = null

  const reorder = (list: IWagon[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) return

    const rearrangedWagons = reorder(
      currentWagons,
      result.source.index,
      result.destination.index,
    )

    setArrangedWagons(rearrangedWagons)
  }

  if (currentWagons.length) {
    arrangableWagonGrid = (
      <Flex mb={5}>
        <Box width={[4 / 9]}>
          <InfoTitle>Anpassen der Wagonreihenfolge</InfoTitle>
          <InfoText>
            Auf der rechten Seite werden die aktuellen Wagons in vereinfachter
            Form und in der aktuellen Reihenfolge dargestellt. Mittels{' '}
            <b>Drag &amp; Drop</b> kann die Position eines Wagons verändert
            werden. <b>Klicken</b> Sie hierzu mit der Maus oder <b>tippen</b>{' '}
            Sie mit dem Finger und
            <b> halten</b> Sie dies für einen kleinen Moment. <b>Bewegen</b> Sie
            dann, ohne loszulassen, Maus oder Finger und ziehen damit den Wagon
            hoch oder runter. Wiederholen Sie die Schritte, bis die Reihenfolge
            passt und klicken Sie oben auf <b>Beladung Fortsetzen</b>. 
          </InfoText>
        </Box>
        <Box width={[1 / 9]} />
        <Box width={[4 / 9]}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(provided, snapshot) => (
                <Flex
                  flexDirection="column"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {currentWagons.map((wagon, index) => {
                    const isBufferStop = index === 0
                    const isExit = currentWagons.length - index === 1
                    const { _id } = wagon

                    return (
                      <Draggable key={_id} draggableId={_id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={provided.draggableProps.style}
                          >
                            <Wagon
                              isBasic
                              {...wagon}
                              position={currentWagons.length - index}
                              isBufferStop={isBufferStop}
                              isExit={isExit}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </Flex>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Flex>
    )
    loadableWagonGrid = (
      <div style={getWagonGridStyles()}>
        {currentWagons.map((wagon, index) => {
          const isBufferStop = index === 0
          const isExit = currentWagons.length - index === 1

          return (
            <Wagon
              {...wagon}
              position={currentWagons.length - index}
              isBufferStop={isBufferStop}
              isExit={isExit}
            />
          )
        })}
      </div>
    )
  }

  const emptyState = (
    <EmptyState
      header="Aktuell gibt es keine Wagons"
      description="Wählen Sie Wagon hinzufügen, um einen neuen Wagon zu erstellen und diesen zu befüllen"
    />
  )

  let tableViewSwitch: any
  if (tableView === 0) {
    tableViewSwitch = (
      <LoadingButton
        onClick={() => setTableView(1)}
        iconBefore={<FileIcon label="Tagesberichte anzeigen" />}
      >
        Tagesberichte anzeigen
      </LoadingButton>
    )
  }
  if (tableView === 1) {
    tableViewSwitch = (
      <LoadingButton
        onClick={() => setTableView(0)}
        iconBefore={<EmojiTravelIcon label="Wagontabelle anzeigen" />}
      >
        Wagontabelle anzeigen
      </LoadingButton>
    )
  }

  return (
    <Page>
      <CreateWagonModal
        isOpen={isCreateWagonModalOpen}
        onClose={() => setIsCreateWagonModalOpen(false)}
        onSubmit={createWagon}
      />
      <PageHeader
        breadcrump="Aktuell am Gleis"
        title="Wagonverladung"
        actions={
          <ButtonGroup>
            {arrangeWagonsToggle}
            {createWagonButton}
          </ButtonGroup>
        }
      />
      
      {(isArrangeModeOn ? arrangableWagonGrid : loadableWagonGrid) ||
        emptyState}

      {isAdminUser ? (
        <Fragment>
          {tableView === 0 ? (
            <>
              <PageHeader
                breadcrump="Wagonhistorie"
                title="Wagontabelle"
                actions={tableViewSwitch}
              />
              <WagonTable wagons={wagons} /* pagination={pagination} */ onShowDetails={showDetails} />
            </>
          ) : null}
          {tableView === 1 ? (
            <>
              <PageHeader
                breadcrump="Wagonhistorie"
                title="Tagesberichte"
                actions={tableViewSwitch}
              />
              <DailyReportTable
                dailyReports={dailyReports}
                onDownloadClick={downloadDailyReport}
                onTransmitClick={transmitDailyReport}
              />
            </>
          ) : null}
        </Fragment>
      ) : null}
    </Page>
  )
}

export default EntrainmentScreen
