import React from 'react'
import styled from 'styled-components'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { Field, FormFooter } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import Modal, {
  ModalTransition,
  ModalHeader,
  ModalTitle,
  ModalBody,
} from '@atlaskit/modal-dialog'
import { CreateInstrumentParameters } from './instruments.types'
import { DatePicker } from '@atlaskit/datetime-picker'
// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs'

const FooterButton = styled(Button)`
  margin-bottom: 20px;
`

interface FormProps {
  instrumentName: string
  instrumentTag: string
  lastCheckDate: Date
  nextCheckDate: Date
  instrumentCheckType: string
  instrumentSerial: string
}

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: (fields: CreateInstrumentParameters) => void
}

const CreateInstrumentModal: React.FunctionComponent<Props> = (
  props,
): JSX.Element => {
  const { isOpen, onClose, onSubmit } = props

  const date = dayjs().format('YYYY-MM-DD')

  const onSubmitClick = (fields: FormProps) => {

    onSubmit({
      ...fields,
    })
    onClose()
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} shouldCloseOnOverlayClick={false}>
          <Form<FormProps> onSubmit={(v) => onSubmitClick(v)}>
            {({ formProps }) => (
              <form {...formProps}>
                <ModalHeader>
                  <ModalTitle>Prüfmittel anlegen</ModalTitle>
                </ModalHeader>
                <ModalBody>

                  <Field name="instrumentName" label="Arbeitsmittel" isRequired>
                    {({ fieldProps }) => (
                      <TextField {...fieldProps} autoComplete="off" />
                    )}
                  </Field>

                  <Field name="instrumentTag" label="Kennzeichnung">
                    {({ fieldProps }) => (
                      <TextField {...fieldProps} autoComplete="off" />
                    )}
                  </Field>

                  <Field
                    name="lastCheckDate"
                    label="Letzte Prüfung"
                    isRequired={false}
                    defaultValue={date}
                  >
                    {({ fieldProps }) => (
                      <>
                        <DatePicker {...fieldProps} locale="de-DE" weekStartDay={1} dateFormat="DD.MM.YYYY" />
                      </>
                    )}
                  </Field>

                  <Field
                    name="nextCheckDate"
                    label="Nächste Prüfung"
                    isRequired={false}
                    defaultValue={date}
                  >
                    {({ fieldProps }) => (
                      <>
                        <DatePicker {...fieldProps} locale="de-DE" weekStartDay={1} dateFormat="DD.MM.YYYY" />
                      </>
                    )}
                  </Field>

                  <Field name="instrumentCheckType" label="Prüfverfahren">
                    {({ fieldProps }) => (
                      <TextField {...fieldProps} autoComplete="off" />
                    )}
                  </Field>

                  <Field name="instrumentSerial" label="Seriennummer">
                    {({ fieldProps }) => (
                      <TextField {...fieldProps} autoComplete="off" />
                    )}
                  </Field>

                  <FormFooter>
                    <ButtonGroup>
                      <FooterButton onClick={onClose}>Abbrechen</FooterButton>
                      <FooterButton type="submit" appearance="primary">
                        anlegen
                      </FooterButton>
                    </ButtonGroup>
                  </FormFooter>
                </ModalBody>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default CreateInstrumentModal
