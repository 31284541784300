import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from '@atlaskit/page'
import { LoadingButton } from '@atlaskit/button'

import TextField from '@atlaskit/textfield'

import Form, { Field, FormFooter, HelperMessage } from '@atlaskit/form'

import modules from 'modules'
import PageHeader from 'components/page-header.component'

interface FormProps {
  oldPassword: string
  newPassword: string
}

const SettingsScreen: React.FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch()

  const isLoading = useSelector(modules.settings.selectors.selectIsLoading)

  const changePassword = ({ oldPassword, newPassword }: FormProps) => {
    dispatch(
      modules.settings.actions.changePassword({
        oldPassword,
        newPassword,
      }),
    )
  }

  return (
    <Page>
      <PageHeader title="Benutzerpasswort" breadcrump="Einstellungen" />
      <Form<FormProps> onSubmit={(v) => changePassword(v)}>
        {({ formProps }) => (
          <form {...formProps}>
            <Field name="oldPassword" label="Aktuelles Passwort" isRequired>
              {({ fieldProps }) => (
                <Fragment>
                  <TextField type="password" {...fieldProps} />
                  <HelperMessage>
                    Falls Sie Ihr aktuelles Passwort nicht mehr wissen, wenden
                    Sie sich bitte an einen Administrator.
                  </HelperMessage>
                </Fragment>
              )}
            </Field>

            <Field name="newPassword" label="Neues Passwort" isRequired>
              {({ fieldProps }) => (
                <Fragment>
                  <TextField type="password" {...fieldProps} minLength={8} />
                </Fragment>
              )}
            </Field>

            <FormFooter>
              <LoadingButton type="submit" appearance="primary" isLoading={isLoading}>
                Ändern
              </LoadingButton>
            </FormFooter>
          </form>
        )}
      </Form>
    </Page>
  )
}

export default SettingsScreen
