import { Reducer, Dispatch } from 'redux'
import { createSelector } from 'reselect'
import { AppState, GenericThunk } from 'typescript/common.types'
import { checklistSchema } from './checklist.schemas'
import * as api from './checklist.service'
import * as types from './checklist.types'
import {
  Filter,
  State,
  Status,
  Checklist,
  ChecklistUpdates,
  ChecklistTypes,
  ActionTypes,
  Action,
  CreateChecklistRequestData,
} from './checklist.types'

/**
 * Initial State
 */
const initialState: State = {
  checklists: {
    byId: {},
    allIds: [],
  },
  isLoading: false,
  isLoadingDelete: false,
}

/**
 * Actions & Thunks
 */
const createChecklistPending = (): Action => ({
  type: ActionTypes.CREATE_CHECKLIST_PENDING,
})
const createChecklistError = (error: Error): Action => ({
  type: ActionTypes.CREATE_CHECKLIST_ERROR,
  error,
})
const createChecklistSuccess = (): Action => ({
  type: ActionTypes.CREATE_CHECKLIST_SUCCESS,
})
const saveChecklists = (
  checklists: Checklist[],
  shouldReplace: boolean,
): Action => ({
  type: ActionTypes.SAVE_CHECKLISTS,
  meta: { shouldReplace },
  payload: { checklists },
})

const createChecklist = (
  newChecklist: CreateChecklistRequestData,
  history: any, // TODO: Typescript
): GenericThunk<Promise<Checklist | null>> => {
  return async (dispatch: Dispatch): Promise<Checklist | null> => {
    dispatch(createChecklistPending())

    try {
      const { data: checklist } = await api.createChecklist(newChecklist)

      checklistSchema.validateSync(checklist)

      dispatch(saveChecklists([checklist], false))
      dispatch(createChecklistSuccess())

      history.push('/checklists/details/' + checklist._id)
      //return checklist
    } catch (error) {
      if (error instanceof Error) {
        dispatch(createChecklistError(error))
      }
    }
    return null
  }
}

const fetchChecklistsPending = (): Action => ({
  type: ActionTypes.FETCH_CHECKLISTS_PENDING,
})
const fetchChecklistsError = (error: Error): Action => ({
  type: ActionTypes.FETCH_CHECKLISTS_ERROR,
  error,
})
const fetchChecklistsSuccess = (): Action => ({
  type: ActionTypes.FETCH_CHECKLISTS_SUCCESS,
})

const fetchChecklists = (types: ChecklistTypes[]): GenericThunk<Promise<Checklist[]>> => {
  return async (dispatch: Dispatch): Promise<Checklist[]> => {
    dispatch(fetchChecklistsPending())

    try {
      const { data: checklists } = await api.fetchChecklists(types)

      checklists.forEach((checklist) => checklistSchema.validateSync(checklist))

      dispatch(saveChecklists(checklists, true))
      dispatch(fetchChecklistsSuccess())

      return checklists
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchChecklistsError(error))
      }
    }
    return []
  }
}

const fetchChecklistById = (
  checklistId: string,
): GenericThunk<Promise<Checklist[]>> => {
  return async (dispatch: Dispatch): Promise<Checklist[]> => {
    dispatch(fetchChecklistsPending())

    try {
      const { data: checklists } = await api.fetchChecklistById(checklistId)

      checklists.forEach((checklist) => checklistSchema.validateSync(checklist))

      dispatch(saveChecklists(checklists, true))
      dispatch(fetchChecklistsSuccess())

      return checklists
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchChecklistsError(error))
      }
    }
    return []
  }
}

const fetchChecklistsByUser = (
  userId: string,
  types: ChecklistTypes[]
): GenericThunk<Promise<Checklist[]>> => {
  return async (dispatch: Dispatch): Promise<Checklist[]> => {
    dispatch(fetchChecklistsPending())

    try {
      const { data: checklists } = await api.fetchChecklistsByUser(userId, types)

      checklists.forEach((checklist) => checklistSchema.validateSync(checklist))

      dispatch(saveChecklists(checklists, true))
      dispatch(fetchChecklistsSuccess())

      return checklists
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchChecklistsError(error))
      }
    }
    return []
  }
}

const updateChecklistPending = (): Action => ({
  type: ActionTypes.UPDATE_CHECKLIST_PENDING,
})
const updateChecklistError = (error: Error): Action => ({
  type: ActionTypes.UPDATE_CHECKLIST_ERROR,
  error,
})
const updateChecklistSuccess = (): Action => ({
  type: ActionTypes.UPDATE_CHECKLIST_SUCCESS,
})

const updateChecklist = (
  checklistId: string,
  checklistUpdates: ChecklistUpdates,
): GenericThunk<Promise<Checklist | null>> => {
  return async (dispatch: Dispatch): Promise<Checklist | null> => {
    dispatch(updateChecklistPending())

    try {
      const { data: checklist } = await api.updateChecklist(
        checklistId,
        checklistUpdates,
      )

      checklistSchema.validateSync(checklist)
      dispatch(saveChecklists([checklist], false))
      dispatch(updateChecklistSuccess())

      return checklist
    } catch (error) {
      if (error instanceof Error) {
        dispatch(updateChecklistError(error))
      }
    }
    return null
  }
}

const updateCheckFromChecklist = (
  checklistId: string,
  checkId: string,
  checkFaults: boolean | null,
): GenericThunk<Promise<Checklist | null>> => {
  return async (dispatch: Dispatch): Promise<Checklist | null> => {
    dispatch(updateChecklistPending())

    try {
      const { data: checklist } = await api.updateCheckFromChecklist(
        checklistId,
        checkId,
        checkFaults,
      )

      checklistSchema.validateSync(checklist)
      dispatch(saveChecklists([checklist], false))
      dispatch(updateChecklistSuccess())

      return checklist
    } catch (error) {
      if (error instanceof Error) {
        dispatch(updateChecklistError(error))
      }
    }
    return null
  }
}

const updateBlockFromChecklist = (
  checklistId: string,
  blockId: string,
  blockFaults: boolean | null,
): GenericThunk<Promise<Checklist | null>> => {
  return async (dispatch: Dispatch): Promise<Checklist | null> => {
    dispatch(updateChecklistPending())

    try {
      const { data: checklist } = await api.updateBlockFromChecklist(
        checklistId,
        blockId,
        blockFaults,
      )

      checklistSchema.validateSync(checklist)
      dispatch(saveChecklists([checklist], false))
      dispatch(updateChecklistSuccess())

      return checklist
    } catch (error) {
      if (error instanceof Error) {
        dispatch(updateChecklistError(error))
      }
    }
    return null
  }
}

const updateBlockCommentFromChecklist = (
  checklistId: string,
  blockId: string,
  blockComment: string | null,
): GenericThunk<Promise<Checklist | null>> => {
  return async (dispatch: Dispatch): Promise<Checklist | null> => {
    dispatch(updateChecklistPending())

    try {
      const { data: checklist } = await api.updateBlockCommentFromChecklist(
        checklistId,
        blockId,
        blockComment,
      )

      checklistSchema.validateSync(checklist)
      dispatch(saveChecklists([checklist], false))
      dispatch(updateChecklistSuccess())

      return checklist
    } catch (error) {
      if (error instanceof Error) {
        dispatch(updateChecklistError(error))
      }
    }
    return null
  }
}

const deleteChecklistsPending = (): Action => ({
  type: ActionTypes.DELETE_CHECKLISTS_PENDING,
})

const deleteChecklistsError = (error: Error): Action => ({
  type: ActionTypes.DELETE_CHECKLISTS_ERROR,
  error,
})

const deleteChecklistsSuccess = (): Action => ({
  type: ActionTypes.DELETE_CHECKLISTS_SUCCESS,
})

const deleteChecklists = (
  checklists: [],
): GenericThunk<Promise<Checklist | null>> => {
  return async (dispatch: Dispatch): Promise<Checklist | null> => {
    dispatch(deleteChecklistsPending())

    try {
      const { data: response } = await api.deleteChecklists(checklists)
      dispatch(deleteChecklistsSuccess())

      return response
    } catch (error) {
      if (error instanceof Error) {
        dispatch(deleteChecklistsError(error))
      }
    }
    return null
  }
}

/**
 * Selectors
 */
const selectIsLoading = (state: AppState): boolean => state.checklist.isLoading
const selectIsLoadingDelete = (state: AppState): boolean =>
  state.checklist.isLoadingDelete

const selectChecklists = (state: AppState): Checklist[] =>
  Object.values(state.checklist.checklists.byId).map((checklist) => {
    return { ...checklist }
  })

const selectChecklistById = (id: string) =>
  createSelector(selectChecklists, (checklists) => {
    const [checklist] = checklists.filter((checklist) => checklist._id === id)

    return checklist
  })

const selectFilteredChecklists = (filter?: Filter) =>
  createSelector(selectChecklists, (checklists) => {
    let filteredChecklists: Checklist[] = []

    if (!filter || filter === Filter.All) filteredChecklists = checklists

    if (filter === Filter.Started) {
      filteredChecklists = checklists.filter(
        (checklist) => checklist.status === Status.Started,
      )
    }

    if (filter === Filter.Finished) {
      filteredChecklists = checklists.filter(
        (checklist) => checklist.status === Status.Finished,
      )
    }

    return filteredChecklists
  })

/**
 * Reducer
 */
const reducer: Reducer<State, Action> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case ActionTypes.CREATE_CHECKLIST_PENDING:
    case ActionTypes.FETCH_CHECKLISTS_PENDING:
    case ActionTypes.UPDATE_CHECKLIST_PENDING:
      return { ...state, isLoading: true }

    case ActionTypes.CREATE_CHECKLIST_ERROR:
    case ActionTypes.FETCH_CHECKLISTS_ERROR:
    case ActionTypes.UPDATE_CHECKLIST_ERROR:
      return { ...state, isLoading: false }

    case ActionTypes.SAVE_CHECKLISTS:
      const { checklists } = action.payload

      const base = action.meta.shouldReplace ? {} : { ...state.checklists.byId }

      const newById = checklists.reduce((acc, checklist) => {
        return { ...acc, [checklist._id]: checklist }
      }, base)

      return {
        ...state,
        isLoading: false,
        checklists: {
          byId: newById,
          allIds: Object.keys(newById),
        },
      }

    case ActionTypes.DELETE_CHECKLISTS_PENDING:
      return { ...state, isLoading: true, isLoadingDelete: true }
    case ActionTypes.DELETE_CHECKLISTS_ERROR:
      return { ...state, isLoading: false, isLoadingDelete: false }
    case ActionTypes.DELETE_CHECKLISTS_SUCCESS:
      return { ...state, isLoading: false, isLoadingDelete: false }

    default:
      return state
  }
}

export default {
  types,
  actions: {
    createChecklist,
    createChecklistPending,
    createChecklistError,
    createChecklistSuccess,
    saveChecklists,
    fetchChecklists,
    fetchChecklistById,
    fetchChecklistsByUser,
    fetchChecklistsPending,
    fetchChecklistsError,
    fetchChecklistsSuccess,
    updateChecklist,
    updateChecklistPending,
    updateChecklistError,
    updateChecklistSuccess,
    updateCheckFromChecklist,
    updateBlockFromChecklist,
    updateBlockCommentFromChecklist,
    deleteChecklists,
    deleteChecklistsPending,
    deleteChecklistsError,
    deleteChecklistsSuccess,
  },
  selectors: {
    selectIsLoading,
    selectIsLoadingDelete,
    selectChecklistById,
    selectChecklists,
    selectFilteredChecklists,
  },
  reducer,
}
