import React, { Fragment, /* useState, */ useEffect } from 'react'
import { /* useDispatch, */ useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Page from '@atlaskit/page'
import Button from '@atlaskit/button'
import { CONTROLLING_URL } from 'config/server.config'

import modules from 'modules'
import TextField from '@atlaskit/textfield'

import { Field, FormFooter } from '@atlaskit/form'

import PageHeader from 'components/page-header.component'

const ControllingScreen: React.FunctionComponent = (): JSX.Element => {

  const history = useHistory()
  /* const dispatch = useDispatch() */

  const isAdminUser = useSelector(
    modules.auth.selectors.selectIsAdminUser,
  )

  const userPermissions = useSelector(
    modules.auth.selectors.selectUserPermissions,
  )

  useEffect(() => {
    if (!userPermissions?.includes('controllingViewPermission')) {
      if(isAdminUser) {
        //console.log("access ok.")
      }
      else {
        history.replace('')
      }
    }
  }, [isAdminUser, userPermissions])

  return (
    <Page>
      <PageHeader title="Abrechnungsprüfung" breadcrump="Abrechnungsprüfung" />
      <form
        target="_blank"
        method="POST"
        action={CONTROLLING_URL ?? 'https://controlling.karl-joos-gmbh.de'}
        encType="multipart/form-data"
      >
        <Field name="wiegedaten" label="Wiegedaten" isRequired>
          {() => (
            <Fragment>
              <TextField type="file" name="wiegedaten" />
            </Fragment>
          )}
        </Field>

        <Field name="artikelauswertung" label="Artikelauswertung" isRequired>
          {() => (
            <Fragment>
              <TextField type="file" name="artikelauswertung" />
            </Fragment>
          )}
        </Field>

        <FormFooter>
          <Button type="submit" appearance="primary">
            Auswerten
          </Button>
        </FormFooter>
      </form>
    </Page>
  )
}

export default ControllingScreen
