import React from 'react'
import styled from 'styled-components'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { Field, FormFooter } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import Select, { ValueType } from '@atlaskit/select'
import Modal, {
  ModalTransition,
  ModalHeader,
  ModalTitle,
  ModalBody,
} from '@atlaskit/modal-dialog'
import { CreateUserParameters, UserRole, Permissions } from './users.types'
import { formatUserRole, formatUserPermission } from 'utils/format.utils'

const FooterButton = styled(Button)`
  margin-bottom: 20px;
`

interface RoleOptionType {
  label: string
  value: UserRole
}

interface PermissionsOptionType {
  label: string
  value: Permissions
}

interface FormProps {
  username: string
  firstName: string
  lastName: string
  role: RoleOptionType
  password: string
  permissions: PermissionsOptionType
}

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: (fields: CreateUserParameters) => void
}

const roles: RoleOptionType[] = [
  { label: formatUserRole(UserRole.Admin), value: UserRole.Admin },
  { label: formatUserRole(UserRole.Employee), value: UserRole.Employee },
]

const permissions: PermissionsOptionType[] = [
  {
    label: formatUserPermission(Permissions.UsersViewPermission),
    value: Permissions.UsersViewPermission,
  },
  {
    label: formatUserPermission(Permissions.VehiclesViewPermission),
    value: Permissions.VehiclesViewPermission,
  },
  {
    label: formatUserPermission(Permissions.EntrainmentViewPermission),
    value: Permissions.EntrainmentViewPermission,
  },
  {
    label: formatUserPermission(Permissions.ControllingViewPermission),
    value: Permissions.ControllingViewPermission,
  },
  {
    label: formatUserPermission(Permissions.VehicleViewPermission),
    value: Permissions.VehicleViewPermission,
  },
  {
    label: formatUserPermission(Permissions.ExcavatorViewPermission),
    value: Permissions.ExcavatorViewPermission,
  },
  {
    label: formatUserPermission(Permissions.ContainersViewPermission),
    value: Permissions.ContainersViewPermission,
  },
  {
    label: formatUserPermission(Permissions.ContainerViewPermission),
    value: Permissions.ContainerViewPermission,
  },
  {
    label: formatUserPermission(Permissions.InstrumentsViewPermission),
    value: Permissions.InstrumentsViewPermission,
  },
]

const CreateUserModal: React.FunctionComponent<Props> = (
  props,
): JSX.Element => {
  const { isOpen, onClose, onSubmit } = props

  const onSubmitClick = (fields: FormProps) => {
    const { role } = fields
    let permissions = []

    if (fields.permissions) {
      permissions = (fields.permissions as any).map((item: any) => item.value)
    }

    onSubmit({
      ...fields,
      role: role.value,
      permissions: permissions,
    })
    onClose()
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} shouldCloseOnOverlayClick={false}>
          <Form<FormProps> onSubmit={(v) => onSubmitClick(v)}>
            {({ formProps }) => (
              <form {...formProps}>
                <ModalHeader>
                  <ModalTitle>Benutzer anlegen</ModalTitle>
                </ModalHeader>
                <ModalBody>
                  <Field<ValueType<RoleOptionType, false>>
                    name="role"
                    label="Rolle"
                    defaultValue={roles[1]}
                  >
                    {({ fieldProps }) => (
                      <Select<RoleOptionType>
                        options={roles}
                        placeholder="Bitte auswählen"
                        isSearchable={false}
                        menuPlacement="bottom"
                        {...fieldProps}
                      />
                    )}
                  </Field>

                  <br />

                  <Field<ValueType<PermissionsOptionType, true>>
                    name="permissions"
                    label="Rechte"
                  >
                    {({ fieldProps }) => (
                      <Select<PermissionsOptionType, true>
                        options={permissions}
                        placeholder="Bitte auswählen"
                        isMulti={true}
                        {...fieldProps}
                      />
                    )}
                  </Field>

                  <br />

                  <Field name="username" label="Benutzername" isRequired>
                    {({ fieldProps }) => (
                      <TextField {...fieldProps} autoComplete="off" />
                    )}
                  </Field>

                  <br />

                  <Field name="password" label="Passwort" isRequired>
                    {({ fieldProps }) => (
                      <TextField
                        {...fieldProps}
                        autoComplete="off"
                        type="password"
                      />
                    )}
                  </Field>

                  <br />

                  <Field name="firstName" label="Vorname" isRequired>
                    {({ fieldProps }) => (
                      <TextField {...fieldProps} autoComplete="off" />
                    )}
                  </Field>

                  <br />

                  <Field name="lastName" label="Nachname" isRequired>
                    {({ fieldProps }) => (
                      <TextField {...fieldProps} autoComplete="off" />
                    )}
                  </Field>

                  <FormFooter>
                    <ButtonGroup>
                      <FooterButton onClick={onClose}>Abbrechen</FooterButton>
                      <FooterButton type="submit" appearance="primary">
                        anlegen
                      </FooterButton>
                    </ButtonGroup>
                  </FormFooter>
                </ModalBody>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default CreateUserModal
