import { Reducer, Dispatch } from 'redux'
import { createSelector } from 'reselect'

import { AppState, GenericThunk } from 'typescript/common.types'
import { instrumentSchema } from './instruments.schemas'
import * as api from './instruments.service'
import * as types from './instruments.types'
import {
  State,
  Instrument,
  ActionTypes,
  Action,
  CreateInstrumentParameters,
} from './instruments.types'

/**
 * Initial State
 */
const initialState: State = {
  instruments: {
    byId: {},
    allIds: [],
  },
  isLoading: false,
  isLoadingEdit: false,
}

/**
 * Actions & Thunks
 */
const createInstrumentPending = (): Action => ({
  type: ActionTypes.CREATE_INSTRUMENT_PENDING,
})
const createInstrumentError = (error: Error): Action => ({
  type: ActionTypes.CREATE_INSTRUMENT_ERROR,
  error,
})
const createInstrumentSuccess = (): Action => ({
  type: ActionTypes.CREATE_INSTRUMENT_SUCCESS,
})
const saveInstruments = (instruments: Instrument[], shouldReplace: boolean): Action => ({
  type: ActionTypes.SAVE_INSTRUMENTS,
  meta: { shouldReplace },
  payload: { instruments },
})

const createInstrument = (
  newInstrument: CreateInstrumentParameters,
): GenericThunk<Promise<Instrument | null>> => {
  return async (dispatch: Dispatch): Promise<Instrument | null> => {
    dispatch(createInstrumentPending())

    try {
      const { data: instrument } = await api.createInstrument(newInstrument)

      instrumentSchema.validateSync(instrument)
      dispatch(saveInstruments([instrument], false))
      dispatch(createInstrumentSuccess())

      return instrument
    } catch (error) {
      if (error instanceof Error) {
        dispatch(createInstrumentError(error))
      }
    }
    return null
  }
}

const editInstrumentPending = (): Action => ({
  type: ActionTypes.EDIT_INSTRUMENT_PENDING,
})
const editInstrumentError = (error: Error): Action => ({
  type: ActionTypes.EDIT_INSTRUMENT_ERROR,
  error,
})
const editInstrumentSuccess = (): Action => ({
  type: ActionTypes.EDIT_INSTRUMENT_SUCCESS,
})
const editInstrument = (editInstrument: Instrument): GenericThunk<Promise<Instrument | null>> => {
  return async (dispatch: Dispatch): Promise<Instrument | null> => {
    dispatch(editInstrumentPending())

    try {
      const { data: instrument } = await api.editInstrument(editInstrument)

      instrumentSchema.validateSync(instrument)
      dispatch(saveInstruments([instrument], true))
      dispatch(editInstrumentSuccess())

      return instrument
    } catch (error) {
      if (error instanceof Error) {
        dispatch(editInstrumentError(error))
      }
    }
    return null
  }
}

const fetchInstrumentsPending = (): Action => ({
  type: ActionTypes.FETCH_INSTRUMENTS_PENDING,
})
const fetchInstrumentsError = (error: Error): Action => ({
  type: ActionTypes.FETCH_INSTRUMENTS_ERROR,
  error,
})
const fetchInstrumentsSuccess = (): Action => ({
  type: ActionTypes.FETCH_INSTRUMENTS_SUCCESS,
})

const fetchInstruments = (): GenericThunk<Promise<Instrument[]>> => {
  return async (dispatch: Dispatch): Promise<Instrument[]> => {
    dispatch(fetchInstrumentsPending())

    try {
      const { data: instruments } = await api.fetchInstruments()

      // TODO: 18.07.23: komischer error, Tabelle mit Prüfmittel wird nicht angezeigt obwohl alles durch geht.
      instruments.forEach((instrument) => instrumentSchema.validateSync(instrument))

      dispatch(saveInstruments(instruments, true))
      dispatch(fetchInstrumentsSuccess())

      return instruments
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchInstrumentsError(error))
      }
    }
    return []
  }
}

const deleteInstrumentsPending = (): Action => ({
  type: ActionTypes.DELETE_INSTRUMENTS_PENDING,
})
const deleteInstrumentsError = (error: Error): Action => ({
  type: ActionTypes.DELETE_INSTRUMENTS_ERROR,
  error,
})
const deleteInstrumentsSuccess = (instrumentId: string): Action => ({
  type: ActionTypes.DELETE_INSTRUMENTS_SUCCESS,
  payload: { instrumentId },
})

const deleteInstruments = (instrumentId: string): GenericThunk<Promise<boolean>> => {
  return async (dispatch: Dispatch): Promise<boolean> => {
    dispatch(deleteInstrumentsPending())

    try {
      const { data: response } = await api.deleteInstrument(instrumentId)

      dispatch(deleteInstrumentsSuccess(instrumentId))

      return response
    } catch (error) {
      if (error instanceof Error) {
        dispatch(deleteInstrumentsError(error))
      }
    }
    return false
  }
}

/**
 * Selectors
 */
const selectIsLoading = (state: AppState): boolean => state.instruments.isLoading
const selectIsLoadingEdit = (state: AppState): boolean => state.instruments.isLoadingEdit

const selectInstruments = (state: AppState): Instrument[] =>
  Object.values(state.instruments.instruments.byId)


const selectInstrumentById = (id: string) =>
  createSelector(selectInstruments, (instruments) => {
    const [instrument] = instruments.filter((instrument) => instrument._id === id)

    return instrument
  })

/**
 * Reducer
 */
const reducer: Reducer<State, Action> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case ActionTypes.CREATE_INSTRUMENT_PENDING:
    case ActionTypes.FETCH_INSTRUMENTS_PENDING:
    case ActionTypes.DELETE_INSTRUMENTS_PENDING:
      return { ...state, isLoading: true }

    case ActionTypes.CREATE_INSTRUMENT_ERROR:
    case ActionTypes.FETCH_INSTRUMENTS_ERROR:
    case ActionTypes.DELETE_INSTRUMENTS_ERROR:
      return { ...state, isLoading: false }

    case ActionTypes.DELETE_INSTRUMENTS_SUCCESS: {
      const { instrumentId } = action.payload
      const newById = { ...state.instruments.byId }
      delete newById[instrumentId]

      return {
        ...state,
        instruments: {
          byId: newById,
          allIds: Object.keys(newById),
        },
        isLoading: false,
      }
    }

    case ActionTypes.SAVE_INSTRUMENTS: {
      const { instruments } = action.payload

      const base = action.meta.shouldReplace ? {} : { ...state.instruments.byId }

      const newById = instruments.reduce((acc, instrument) => {
        return { ...acc, [instrument._id]: instrument }
      }, base)

      return {
        ...state,
        isLoading: false,
        instruments: {
          byId: newById,
          allIds: Object.keys(newById),
        },
      }
    }

    case ActionTypes.EDIT_INSTRUMENT_PENDING:
      return { ...state, isLoading: true, isLoadingEdit: true }

    case ActionTypes.EDIT_INSTRUMENT_ERROR:
      return { ...state, isLoading: false, isLoadingEdit: false }

    case ActionTypes.EDIT_INSTRUMENT_SUCCESS:
      return { ...state, isLoading: false, isLoadingEdit: false }

    default:
      return state
  }
}

export default {
  types,
  actions: {
    createInstrument,
    createInstrumentPending,
    createInstrumentError,
    createInstrumentSuccess,
    editInstrument,
    editInstrumentPending,
    editInstrumentError,
    editInstrumentSuccess,
    saveInstruments,
    fetchInstruments,
    fetchInstrumentsPending,
    fetchInstrumentsError,
    fetchInstrumentsSuccess,
    deleteInstruments,
    deleteInstrumentsPending,
    deleteInstrumentsError,
    deleteInstrumentsSuccess,
  },
  selectors: {
    selectIsLoading,
    selectIsLoadingEdit,
    selectInstrumentById,
    selectInstruments,
  },
  reducer,
}
