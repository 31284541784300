import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import AuthScreen from 'modules/auth/auth.screen'
import ControllingScreen from 'modules/controlling/controlling.screen'
import EntrainmentScreen from 'modules/entrainment/entrainment.screen'
import jwt from 'lib/jwt.class'
import Layout from 'modules/core/core.layout'
import modules from 'modules'
import SettingsScreen from 'modules/settings/settings.screen'
import StartScreen from 'modules/start/start.screen'
import UsersScreen from 'modules/users/users.screen'
import WagonScreen from 'modules/entrainment/wagon.screen'
import ChecklistScreen from 'modules/checklists/checklist.screen'
import VehicleChecklistScreen from 'modules/checklists/vehicle/vehicle-checklist.screen'
import ExcavatorChecklistScreen from 'modules/checklists/excavator/excavator-checklist.screen'
import VehiclesScreen from 'modules/vehicles/vehicles.screen'
import ContainersScreen from 'modules/containers/containers.screen'
import ContainerChecklistScreen from 'modules/checklists/container/container-checklist.screen'
import InstrumentsScreen from 'modules/instruments/instruments.screen'
import FtpDocumentsScreen from 'modules/ftp-documents/ftp-documents.screen'

export default (): JSX.Element => {
  const isAuthenticated = useSelector(
    modules.auth.selectors.selectIsAuthenticated,
  )

  useEffect(() => {
    if (!isAuthenticated) jwt.delete()
  }, [isAuthenticated])

  /** Routing Strategy
   *
   * Redux store saves the JWT that is received for every successful login.
   * As long as the JWT exists and is not expired, use app routes, otherwise
   * any route will show the authentication screen.
   *
   * In addition, all authenticated routes are wrapped inside of the
   * Layout, which renders a sidebar next to every screen.
   */
  return isAuthenticated ? (
    <Router>
      <Layout>
        <Route exact path="/" component={StartScreen}></Route>
        <Route
          exact
          path="/entrainment/wagons"
          component={EntrainmentScreen}
        ></Route>
        <Route
          exact
          path="/entrainment/wagons/:wagonId"
          component={WagonScreen}
        ></Route>
        <Route
          exact
          path="/checklists/vehicle"
          component={VehicleChecklistScreen}
        ></Route>
        <Route
          exact
          path="/checklists/excavator"
          component={ExcavatorChecklistScreen}
        ></Route>
        <Route
          exact
          path="/checklists/details/:checklistId"
          component={ChecklistScreen}
        ></Route>
        <Route exact path="/vehicles" component={VehiclesScreen}></Route>
        <Route exact path="/containers" component={ContainersScreen}></Route>
        <Route
          exact
          path="/checklists/container"
          component={ContainerChecklistScreen}
        ></Route>
        <Route exact path="/settings" component={SettingsScreen}></Route>
        <Route exact path="/admin/users" component={UsersScreen}></Route>
        <Route
          exact
          path="/admin/controlling"
          component={ControllingScreen}
        ></Route>
        <Route exact path="/instruments" component={InstrumentsScreen}></Route>
        <Route
          exact
          path="/ftp-documents"
          component={FtpDocumentsScreen}
        ></Route>
      </Layout>
    </Router>
  ) : (
    <Router>
      <Route path="*" component={AuthScreen}></Route>
    </Router>
  )
}
