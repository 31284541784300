import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import SubtaskIcon from '@atlaskit/icon/glyph/subtask'
import CheckCircleOutlineIcon from '@atlaskit/icon/glyph/check-circle-outline'
import Button from '@atlaskit/button'

import ExplanationButton from 'components/explanation-button.component'

interface Props {
  isModalOpen: boolean
  isForced?: boolean
  onClose: () => void
  onYesClick: () => void
  onNoClick: () => void
}

const DamagedModal: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { isModalOpen, onClose, onYesClick, onNoClick, isForced } = props

  const history = useHistory()
  const [countdown, setCountdown] = useState(5)

  useEffect(() => {
    if (!isForced || !countdown) return

    const interval = setInterval(() => {
      setCountdown(countdown - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [isForced, countdown])

  const isNoDisabled = isForced && countdown > 0

  return (
    <ModalTransition>
      {(isModalOpen || isForced) && (
        <Modal onClose={onClose} shouldCloseOnOverlayClick={false}>
          <ModalHeader>
            <ModalTitle>Wurden Schäden am Wagon festgestellt?</ModalTitle>
          </ModalHeader>

          <br />

          <ModalBody>
            <ExplanationButton
              title="Nein"
              description="Es ist keine Dokumentation nötig."
              isDisabled={isNoDisabled}
              onClick={!isNoDisabled ? onNoClick : () => null}
              icon={
                !isNoDisabled ? (
                  <CheckCircleOutlineIcon label="Nein" size="large" />
                ) : (
                  `${countdown}s`
                )
              }
            />

            <br />

            <ExplanationButton
              title="Ja"
              description="Bitte dokumentieren Sie die festgestellten Schäden."
              onClick={onYesClick}
              icon={<SubtaskIcon label="Ja" size="large" />}
            />

            <br />
          </ModalBody>

          <ModalFooter>
            <Button
              appearance="primary"
              onClick={() => {
                if (isForced) {
                  history.goBack()
                } else {
                  onClose()
                }
              }}
              autoFocus
            >
              Schließen
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default DamagedModal
