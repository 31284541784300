import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'

import modules from 'modules'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ChecklistCheckBlock from './checklist-check-block.component'
import { Status } from './checklist.types'
import Select from '@atlaskit/select';
import Button, { ButtonGroup } from '@atlaskit/button'
import TextArea from '@atlaskit/textarea'
import { FormFooter } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import dayjs from 'dayjs'
import { formatChecklistType } from 'utils/format.utils'

interface Props {
  checklistId: string
  checklistType: string
  checklistData: []
  status: Status
  containerNr: string
  checklistMessage: string
}

const FooterButton = styled(Button)`
  margin-bottom: 20px;
`

const ContainerChecklist: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { checklistId, checklistType, checklistData, status, containerNr, checklistMessage } = props

  const [stateChecklistMessage, setStateChecklistMessage] = useState(checklistMessage)
  const [stateContainerNr, setStateContainerNr] = useState(containerNr)

  const [container, setContainer] = useState<any>(null)

  const history = useHistory()
  const dispatch = useDispatch()

  const containers = useSelector(modules.containers.selectors.selectContainers)
  const isLoadingContainers = useSelector(modules.containers.selectors.selectIsLoading)

  const containerOptions:any = []

  containers.forEach((container) => {
    // nur passend zum ausgewählten Typ
    if(checklistType.toLowerCase() === container.type) {
      containerOptions.push({'value': container.containerNr, 'label': container.containerNr})
    }
  })

  useEffect(() => {
    dispatch(modules.containers.actions.fetchContainers())
  }, [dispatch])

  const renderChecklistBlocks = () => {
    if (checklistData.length === 0) {
      return <div>keine Daten verfügbar.</div>
    }

    return checklistData.map(function (checkBlock: any, index: number) {
      return (
        <ChecklistCheckBlock
          key={index}
          checklistId={checklistId}
          checklistBlock={checkBlock}
          status={status}
          blockNumber={index + 1}
        />
      )
    })
  }
  
  const routeBack = () => {
    if(checklistType === 'Container' || checklistType === 'Skip' ) {
      history.push('/checklists/container')
    }
  }

  const onSubmitClick = () => {
    dispatch(
      modules.checklist.actions.updateChecklist(checklistId, {
        status: Status.Finished,
        vehiclePlate: '',
        containerNr: stateContainerNr,
        checklistMessage: stateChecklistMessage,
      }),
    )
    routeBack()
  }

  const onCancel = () => {
    dispatch(
      modules.checklist.actions.updateChecklist(checklistId, {
        status: Status.Cancelled,
        vehiclePlate: '',
        containerNr: stateContainerNr,
        checklistMessage: stateChecklistMessage,
      }),
    )
    routeBack()
  }

  const handleContainerSelect = (selectedOption: any) => {
    setStateContainerNr(selectedOption.value)

    const container = containers.filter((container) => container.containerNr === selectedOption.value)
    setContainer(container[0])
  }

  const handleChecklistMessage = (selectedOption: any) => {
    setStateChecklistMessage(selectedOption.target.value)
  }

  return (
    <>
      <form className='checklist-form' onSubmit={() => onSubmitClick()}>
        
        <label htmlFor="select-container" className="font-bold">{formatChecklistType(checklistType)} auswählen</label>
        {!isLoadingContainers && status === 'started' ? (
          <Select
            inputId="select-container"
            options={containerOptions}
            onChange={handleContainerSelect}
            placeholder="Auswählen..."
            isRequired
          />
        ) : (
          <TextField
            name="containerNr"
            defaultValue={stateContainerNr}
            isDisabled={status === 'finished' || status === 'cancelled'}
            isRequired
          />
        )}
        
        { stateContainerNr === null && status === 'started' ? null : (<>

          <div className="my-4">
            {container !== null ? (
              <>
                <div className='font-bold'>Behälterdaten:</div>
                <div className="grid lg:grid-cols-2 xl:grid-cols-3">
                  <div className="flex justify-between p-2 md:m-2 bg-gray-white"><div>Baujahr</div><div className="font-bold">{dayjs(container.dateBuilt).format('YYYY')}</div></div>
                  <div className="flex justify-between p-2 md:m-2 bg-gray-white"><div>zul. Gesamtgewicht</div><div className="font-bold">{container.totalWeight} kg</div></div>
                  <div className="flex justify-between p-2 md:m-2 bg-gray-white"><div>Behälterlänge i. L. ca.:</div><div className="font-bold">{container.containerLenght} mm</div></div>
                  <div className="flex justify-between p-2 md:m-2 bg-gray-white"><div>Behälterhöhe i. L. ca.:</div><div className="font-bold">{container.containerHeight} mm</div></div>
                  <div className="flex justify-between p-2 md:m-2 bg-gray-white"><div>Inhalt</div><div className="font-bold">{container.volume} m³</div></div>
                  <div className="flex justify-between p-2 md:m-2 bg-gray-white"><div>Fabr.-Nr.</div><div className="font-bold">{container.factoryNr}</div></div>
                  <div className="flex justify-between p-2 md:m-2 bg-gray-white"><div>Eigengewicht</div><div className="font-bold">{container.selfWeight} kg</div></div>
                  <div className="flex justify-between p-2 md:m-2 bg-gray-white"><div>Behälterbreite i. L. ca.:</div><div className="font-bold">{container.containerWidth} mm</div></div>
                  <div className="flex justify-between p-2 md:m-2 bg-gray-white"><div>Verr.-Maß</div><div className="font-bold">{container.vDimension} mm</div></div>
                  <div className="flex justify-between p-2 md:m-2 bg-gray-white"><div>Gültigkeit</div><div className="font-bold">{dayjs(container.validation).format('DD.MM.YYYY')}</div></div>
                </div>
              </>
            ) : null }
          </div>

          <div className="grid lg:grid-cols-2 xl:grid-cols-3">
            {renderChecklistBlocks()}
          </div>

          <div className='font-bold'>Bemerkugen:</div>
          <TextArea
            resize="auto"
            maxHeight="20vh"
            name="checklist_message"
            isDisabled={status === 'finished' || status === 'cancelled'}
            onChange={handleChecklistMessage}
            defaultValue={stateChecklistMessage}
          />

          <FormFooter>
            <ButtonGroup>
              {status === 'finished' || status === 'cancelled' ? (
                <>
                  <FooterButton onClick={() => routeBack()} appearance="primary">
                    Zurück
                  </FooterButton>
                </>
              ) : (
                <>
                  <FooterButton onClick={onCancel}>Prüfung abbrechen</FooterButton>
                  <FooterButton type="submit" appearance="primary">
                    Prüfung beenden
                  </FooterButton>
                </>
              )}
            </ButtonGroup>
          </FormFooter>
        </>)}
      </form>
    </>
  )
}

export default ContainerChecklist
