// @ts-nocheck
export const SERVER_URL = process.env.REACT_APP_SERVER_URL
export const CONTROLLING_URL = process.env.REACT_APP_CONTROLLING_SERVER_URL
export const USERS_ENDPOINT = '/api/v1/users'
export const AUTHENTICATE_ENDPOINT = '/api/v1/users/authenticate'
export const CHANGE_PASSWORD_ENDPOINT = '/api/v1/users/change-password'
export const ENTRAINMENT_WAGONS_ENDPOINT = '/api/v1/entrainment/wagons'
export const CHECKLISTS_ENDPOINT = '/api/v1/checklists'
export const CONTACTS_ENDPOINT = '/api/v1/contacts'
export const DAILY_REPORTS_ENDPOINT = '/api/v1/daily-reports'
export const VEHICLES_ENDPOINT = '/api/v1/vehicles'
export const CONTAINERS_ENDPOINT = '/api/v1/containers'
export const INSTRUMENT_ENDPOINT = '/api/v1/instruments'
export const FTP_DOCUMENTS_ENDPOINT = '/api/v1/ftp'