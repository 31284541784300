import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Page from '@atlaskit/page'
import AddIcon from '@atlaskit/icon/glyph/add'

import modules from 'modules'
import PageHeader from 'components/page-header.component'
import VehiclesTable from './vehicles-table.component'
import { CreateVehicleParameters } from './vehicles.types'
import CreateVehicleModal from './create-vehicle.modal'
import { LoadingButton } from '@atlaskit/button'

const VehiclesScreen: React.FunctionComponent = (): JSX.Element => {
  const [isCreateVehicleModalOpen, setIsCreateVehicleModalOpen] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const isAdminUser = useSelector(
    modules.auth.selectors.selectIsAdminUser,
  )

  const userPermissions = useSelector(
    modules.auth.selectors.selectUserPermissions,
  )

  const vehicles = useSelector(modules.vehicles.selectors.selectVehicles)
  const isLoading = useSelector(modules.vehicles.selectors.selectIsLoading)
  const isLoadingEdit = useSelector(modules.vehicles.selectors.selectIsLoadingEdit)

  useEffect(() => {
    if (!userPermissions?.includes('vehiclesViewPermission')) {
      if(isAdminUser) {
        //console.log("access ok.")
      }
      else {
        history.replace('')
      }
    }
  }, [isAdminUser, userPermissions])

  useEffect(() => {
    dispatch(modules.vehicles.actions.fetchVehicles())
  }, [dispatch, isLoadingEdit])

  const createVehicle = async (vehicle: CreateVehicleParameters) => {
    return dispatch(modules.vehicles.actions.createVehicle(vehicle))
  }

  return (
    <Page>
      <CreateVehicleModal
        isOpen={isCreateVehicleModalOpen}
        onClose={() => setIsCreateVehicleModalOpen(false)}
        onSubmit={createVehicle}
      />
      <PageHeader
        title="Fahrzeugverwaltung"
        breadcrump="Fahrzeugverwaltung"
        actions={
          <LoadingButton
            onClick={() => setIsCreateVehicleModalOpen(true)}
            isLoading={isLoading}
            appearance="primary"
            iconBefore={<AddIcon label="Fahrzeug anlegen" />}
          >
            Fahrzeug anlegen
          </LoadingButton>
        }
      />
      <VehiclesTable vehicles={vehicles} onShowDetails={() => null} />
    </Page>
  )
}

export default VehiclesScreen
