import { Reducer, Dispatch } from 'redux'
import { createSelector } from 'reselect'
import jwtd from 'jwt-decode'

import { AppState, GenericThunk } from 'typescript/common.types'
import { User, UserRole } from 'modules/users/users.types'
import { userSchema } from './auth.schemas'
import * as api from './auth.service'
import jwt from 'lib/jwt.class'
import * as types from './auth.types'
import {
  State,
  ActionTypes,
  Action,
  DecodedJWT,
  AuthenticationRequestData,
} from './auth.types'

/**
 * Initial State
 */
export const initialState: State = {
  user: null,
  token: null,
  isLoading: false,
}

/**
 * Actions
 */
const authenticatePending = (): Action => ({
  type: ActionTypes.AUTHENTICATE_PENDING,
})
const authenticateError = (error: Error): Action => ({
  type: ActionTypes.AUTHENTICATE_ERROR,
  error,
})
const authenticateSuccess = (user: User, token: DecodedJWT): Action => ({
  type: ActionTypes.AUTHENTICATE_SUCCESS,
  payload: { user, token },
})

/**
 * Thunks
 */
export const authenticate = (
  credentials: AuthenticationRequestData,
): GenericThunk<Promise<User | null>> => {
  return async (dispatch: Dispatch): Promise<User | null> => {
    dispatch(authenticatePending())

    try {
      const {
        data: { user, token },
      } = await api.authenticate(credentials)

      userSchema.validateSync(user)
      await jwt.save(token)
      dispatch(authenticateSuccess(user, jwtd(token)))

      return user
    } catch (error) {
      if (error instanceof Error) {
        dispatch(authenticateError(error))
      }
    }
    return null
  }
}

/**
 * Selectors
 */
const selectIsLoading = (state: AppState): boolean => state.auth.isLoading

const selectUser = (state: AppState): User | null => state.auth.user

const selectToken = (state: AppState): DecodedJWT | null => state.auth.token

const selectUserRole = createSelector(selectUser, (user) =>
  user ? user.role : null,
)

const selectIsAdminUser = createSelector(
  selectUserRole,
  (userRole) => userRole === UserRole.Superadmin || userRole === UserRole.Admin,
)

const selectIsSuperadminUser = createSelector(
  selectUserRole,
  (userRole) => userRole === UserRole.Superadmin,
)

const selectUserPermissions = createSelector(selectUser, (user) =>
  user ? user.permissions : null,
)

const selectUserName = createSelector(selectUser, (user) =>
  user ? `${user.firstName} ${user.lastName}` : null,
)

const selectIsTokenExpired = createSelector(
  selectToken,
  (token) => token && Date.now() > token.exp * 1000,
)

const selectIsAuthenticated = createSelector(
  selectUser,
  selectIsTokenExpired,
  (user, isTokenExpired) => user && !isTokenExpired,
)

/**
 * Reducer
 */
export const reducer: Reducer<State, Action> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case ActionTypes.AUTHENTICATE_PENDING:
      return { ...state, isLoading: true }

    case ActionTypes.AUTHENTICATE_ERROR:
      return { ...state, isLoading: false }

    case ActionTypes.AUTHENTICATE_SUCCESS:
      const { user, token } = action.payload
      return { ...state, isLoading: false, user, token }

    default:
      return state
  }
}

export default {
  types,
  actions: {
    authenticate,
    authenticatePending,
    authenticateError,
    authenticateSuccess,
  },
  selectors: {
    selectIsLoading,
    selectUser,
    selectToken,
    selectIsTokenExpired,
    selectUserRole,
    selectIsAdminUser,
    selectIsSuperadminUser,
    selectUserPermissions,
    selectUserName,
    selectIsAuthenticated,
  },
  reducer,
}
