import { Wagon } from 'modules/entrainment/entrainment.types'

export enum ActionTypes {
  CREATE_DAILY_REPORT_PENDING = 'DAILY_REPORTS/CREATE_DAILY_REPORT_PENDING',
  CREATE_DAILY_REPORT_ERROR = 'DAILY_REPORTS/CREATE_DAILY_REPORT_ERROR',
  CREATE_DAILY_REPORT_SUCCESS = 'DAILY_REPORTS/CREATE_DAILY_REPORT_SUCCESS',
  FETCH_DAILY_REPORTS_PENDING = 'DAILY_REPORTS/FETCH_DAILY_REPORTS_PENDING',
  FETCH_DAILY_REPORTS_ERROR = 'DAILY_REPORTS/FETCH_DAILY_REPORTS_ERROR',
  FETCH_DAILY_REPORTS_SUCCESS = 'DAILY_REPORTS/FETCH_DAILY_REPORTS_SUCCESS',
  DELETE_DAILY_REPORT_PENDING = 'DAILY_REPORTS/DELETE_DAILY_REPORT_PENDING',
  DELETE_DAILY_REPORT_ERROR = 'DAILY_REPORTS/DELETE_DAILY_REPORT_ERROR',
  DELETE_DAILY_REPORT_SUCCESS = 'DAILY_REPORTS/DELETE_DAILY_REPORT_SUCCESS',
  SAVE_DAILY_REPORTS = 'DAILY_REPORTS/SAVE_DAILY_REPORTS',
}

/**
 * DailyReport
 */
export interface DailyReport {
  /**
   * DailyReport ID generated by the databse
   */
  _id: string

  /**
   * DailyReport name
   */
  dayOfReport: Date

  /**
   * DailyReport selected wagons
   * These wagons will be shown on the report
   */
  wagons?: Wagon[]

  /**
   * Date and time the dailyReport was created
   */
  createdAt: Date

  /**
   * Date and time the dailyReport was last updated
   */
  updatedAt: Date
}

export interface State {
  isLoading: boolean
  dailyReports: {
    byId: {
      [id: string]: DailyReport
    }
    allIds: string[]
  }
}

export interface CreateDailyReportParameters {
  wagons: string[]
  dayOfReport: Date
}

export interface CreateDailyReportPendingAction {
  type: ActionTypes.CREATE_DAILY_REPORT_PENDING
}

export interface CreateDailyReportErrorAction {
  type: ActionTypes.CREATE_DAILY_REPORT_ERROR
  error: Error
}

export interface CreateDailyReportSuccessAction {
  type: ActionTypes.CREATE_DAILY_REPORT_SUCCESS
}

export interface FetchDailyReportsPendingAction {
  type: ActionTypes.FETCH_DAILY_REPORTS_PENDING
}

export interface FetchDailyReportsErrorAction {
  type: ActionTypes.FETCH_DAILY_REPORTS_ERROR
  error: Error
}

export interface FetchDailyReportsSuccessAction {
  type: ActionTypes.FETCH_DAILY_REPORTS_SUCCESS
}

export interface DeleteDailyReportPendingAction {
  type: ActionTypes.DELETE_DAILY_REPORT_PENDING
}

export interface DeleteDailyReportErrorAction {
  type: ActionTypes.DELETE_DAILY_REPORT_ERROR
  error: Error
}

export interface DeleteDailyReportSuccessAction {
  type: ActionTypes.DELETE_DAILY_REPORT_SUCCESS
  payload: {
    dailyReportId: string
  }
}

export interface SaveDailyReportsAction {
  type: ActionTypes.SAVE_DAILY_REPORTS
  meta: {
    shouldReplace: boolean
  }
  payload: {
    dailyReports: DailyReport[]
  }
}

export type Action =
  | CreateDailyReportPendingAction
  | CreateDailyReportErrorAction
  | CreateDailyReportSuccessAction
  | FetchDailyReportsPendingAction
  | FetchDailyReportsErrorAction
  | FetchDailyReportsSuccessAction
  | DeleteDailyReportPendingAction
  | DeleteDailyReportErrorAction
  | DeleteDailyReportSuccessAction
  | SaveDailyReportsAction
