import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import SignOutIcon from '@atlaskit/icon/glyph/sign-out'
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left'
import Button from '@atlaskit/button'

import {
  AtlassianNavigation,
  PrimaryButton,
  ProductHome,
} from '@atlaskit/atlassian-navigation'

import { ReactComponent as Logo } from 'assets/images/logo.svg'

interface CoreNavigationProps extends RouteComponentProps {
  userName: string | null
  onSignOut: () => void
}

const CoreNavigation: React.FunctionComponent<CoreNavigationProps> = (
  props,
): JSX.Element => {
  const { userName, onSignOut, history } = props

  return (
    <AtlassianNavigation
      label="Joos Web"
      renderProductHome={() => (
        <ProductHome
          icon={Logo as any}
          logo={Logo as any}
          siteTitle="Joos App"
        />
      )}
      primaryItems={[
        <PrimaryButton
          onClick={() => history.replace('/')}
          isHighlighted={history.location.pathname === '/'}
        >
          Home
        </PrimaryButton>,
        <PrimaryButton
          isDisabled={history.location.pathname === '/'}
          iconBefore={<ArrowLeftIcon label="Zurück" />}
          onClick={() => history.goBack()}
        >
          Zurück
        </PrimaryButton>,
      ]}
      // primaryItems={[]}
      renderSignIn={() => <span>{userName}</span>}
      renderProfile={() => (
        <Button
          appearance="subtle"
          iconAfter={<SignOutIcon label="Abmelden" />}
          onClick={onSignOut}
        >
          Abmelden
        </Button>
      )}
    />
  )
}

export default withRouter(CoreNavigation)
