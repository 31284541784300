import { Contact } from 'modules/contacts/contacts.types'
import { User } from 'modules/users/users.types'

export enum ActionTypes {
  CREATE_WAGON_PENDING = 'LOADING/CREATE_WAGON_PENDING',
  CREATE_WAGON_ERROR = 'LOADING/CREATE_WAGON_ERROR',
  CREATE_WAGON_SUCCESS = 'LOADING/CREATE_WAGON_SUCCESS',
  FETCH_WAGONS_PENDING = 'LOADING/FETCH_WAGONS_PENDING',
  FETCH_WAGONS_ERROR = 'LOADING/FETCH_WAGONS_ERROR',
  FETCH_WAGONS_SUCCESS = 'LOADING/FETCH_WAGONS_SUCCESS',
  UPDATE_WAGON_PENDING = 'LOADING/UPDATE_WAGON_PENDING',
  UPDATE_WAGON_ERROR = 'LOADING/UPDATE_WAGON_ERROR',
  UPDATE_WAGON_SUCCESS = 'LOADING/UPDATE_WAGON_SUCCESS',
  UPDATE_HOPPER_PENDING = 'LOADING/UPDATE_HOPPER_PENDING',
  UPDATE_HOPPER_ERROR = 'LOADING/UPDATE_HOPPER_ERROR',
  UPDATE_HOPPER_SUCCESS = 'LOADING/UPDATE_HOPPER_SUCCESS',
  SEARCH_WAGONS_PENDING = 'LOADING/SEARCH_WAGONS_PENDING',
  SEARCH_WAGONS_ERROR = 'LOADING/SEARCH_WAGONS_ERROR',
  SEARCH_WAGONS_SUCCESS = 'LOADING/SEARCH_WAGONS_SUCCESS',
  SAVE_WAGONS = 'LOADING/SAVE_WAGONS',
  SAVE_PAGINATION = 'LOADING/SAVE_PAGINATION',
}

export enum Material {
  E8 = 'E8',
  STW = 'STW',
  Misch = 'Misch.',
  CroNiMo = 'CroNiMo',
  C10 = 'C10',
}

export enum Position {
  A = 'A',
  M = 'M',
  E = 'E',
}

export enum Status {
  Supplied = 'supplied',
  Finished = 'finished',
  Collected = 'collected',
  Deleted = 'deleted',
}

export enum Filter {
  All = 'all',
  Current = 'current',
  Collected = 'collected',
}

export enum Damaged {
  Unchecked = 'unchecked',
  None = 'none',
  Documented = 'documented',
}

export interface Hopper {
  _id: string
  netWeight: number,
  grossWeight: number
  tara: number
  position: Position
  weightNr: string
  updatedAt: Date
  updatedBy: User | null
}

export interface HopperInputs {
  hopperId: string
  netWeight: number
  grossWeight: number
  position: Position
  weightNr: string
}

export interface HopperUpdates {
  grossWeight: number
  netWeight: number
  position: Position
  weightNr: string
}

export interface Wagon {
  _id: string
  wagonNo: string
  monthNo?: string
  isReported: boolean
  collectedAt?: Date
  material: Material
  limit: number
  position?: number
  netWeight: number
  officialWeight?: number
  status: Status
  createdAt: Date
  updatedAt: Date
  createdBy: User | null
  receiver: Contact | null
  hoppers: Hopper[]
  damaged: Damaged
}

export interface WagonUpdates {
  wagonNo?: string
  receiver?: string
  monthNo?: string
  material?: Material
  limit?: number
  officialWeight?: number
  position?: number
  status?: Status
  collectedAt?: Date
  damaged?: Damaged
}

export interface State {
  isLoading: boolean
  wagons: {
    byId: {
      [id: string]: Wagon
    }
    allIds: string[]
  }
  pagination: any
}

export interface CreateWagonRequestData {
  wagonNo: string
  material: Material
  limit: number
}

export interface CreateWagonPendingAction {
  type: ActionTypes.CREATE_WAGON_PENDING
}

export interface CreateWagonErrorAction {
  type: ActionTypes.CREATE_WAGON_ERROR
  error: Error
}

export interface CreateWagonSuccessAction {
  type: ActionTypes.CREATE_WAGON_SUCCESS
}

export interface FetchWagonsPendingAction {
  type: ActionTypes.FETCH_WAGONS_PENDING
}

export interface FetchWagonsErrorAction {
  type: ActionTypes.FETCH_WAGONS_ERROR
  error: Error
}

export interface FetchWagonsSuccessAction {
  type: ActionTypes.FETCH_WAGONS_SUCCESS
}

export interface UpdateWagonPendingAction {
  type: ActionTypes.UPDATE_WAGON_PENDING
}

export interface UpdateWagonErrorAction {
  type: ActionTypes.UPDATE_WAGON_ERROR
  error: Error
}

export interface UpdateWagonSuccessAction {
  type: ActionTypes.UPDATE_WAGON_SUCCESS
}

export interface UpdateHopperPendingAction {
  type: ActionTypes.UPDATE_HOPPER_PENDING
}

export interface UpdateHopperErrorAction {
  type: ActionTypes.UPDATE_HOPPER_ERROR
  error: Error
}

export interface UpdateHopperSuccessAction {
  type: ActionTypes.UPDATE_HOPPER_SUCCESS
}

export interface SearchWagonsPendingAction {
  type: ActionTypes.SEARCH_WAGONS_PENDING
}

export interface SearchWagonsErrorAction {
  type: ActionTypes.SEARCH_WAGONS_ERROR
  error: Error
}

export interface SearchWagonsSuccessAction {
  type: ActionTypes.SEARCH_WAGONS_SUCCESS
}

export interface SaveWagonsAction {
  type: ActionTypes.SAVE_WAGONS
  meta: {
    shouldReplace: boolean
  }
  payload: {
    wagons: Wagon[]
  }
}

export interface SavePaginationAction {
  type: ActionTypes.SAVE_PAGINATION
  payload: {
    pagination: any
  }
}

export type Action =
  | CreateWagonPendingAction
  | CreateWagonErrorAction
  | CreateWagonSuccessAction
  | FetchWagonsPendingAction
  | FetchWagonsErrorAction
  | FetchWagonsSuccessAction
  | UpdateWagonPendingAction
  | UpdateWagonErrorAction
  | UpdateWagonSuccessAction
  | UpdateHopperPendingAction
  | UpdateHopperErrorAction
  | UpdateHopperSuccessAction
  | SearchWagonsPendingAction
  | SearchWagonsErrorAction
  | SearchWagonsSuccessAction
  | SaveWagonsAction
  | SavePaginationAction
