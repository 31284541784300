import { AxiosResponse } from 'axios'

import { ENTRAINMENT_WAGONS_ENDPOINT } from 'config/server.config'
import { request } from 'utils/http.utils'
import {
  CreateWagonRequestData,
  Wagon,
  WagonUpdates,
  HopperUpdates,
} from './entrainment.types'

export const createWagon = (
  wagon: CreateWagonRequestData,
): Promise<AxiosResponse<Wagon>> => {
  return request({
    method: 'post',
    url: ENTRAINMENT_WAGONS_ENDPOINT,
    data: wagon,
  })
}

export const fetchWagons = (
    page: number,
    limit: number,
): Promise<AxiosResponse<any>> => { // Wagon[]
  return request({
    method: 'get',
    url: ENTRAINMENT_WAGONS_ENDPOINT,
    params: { page, limit },
  })
}

export const updateWagon = (
  wagonId: string,
  wagonUpdates: WagonUpdates,
): Promise<AxiosResponse<Wagon>> => {
  return request({
    method: 'patch',
    url: `${ENTRAINMENT_WAGONS_ENDPOINT}/${wagonId}`,
    data: wagonUpdates,
  })
}

export const updateWagons = (
  updates: {
    wagonId: string
    updates: WagonUpdates
  }[],
): Promise<AxiosResponse<Wagon[]>> => {
  return request({
    method: 'patch',
    url: `${ENTRAINMENT_WAGONS_ENDPOINT}/multiple`,
    data: updates,
  })
}

export const updateHopper = (
  wagonId: string,
  hopperId: string,
  hopperUpdates: HopperUpdates,
): Promise<AxiosResponse<Wagon>> => {
  return request({
    method: 'put',
    url: `${ENTRAINMENT_WAGONS_ENDPOINT}/${wagonId}/hoppers/${hopperId}`,
    data: hopperUpdates,
  })
}

export const downloadWagonReport = (
  wagonId: string,
  wagonNo: string,
): Promise<void> => {
  return request({
    method: 'get',
    responseType: 'blob',
    url: `${ENTRAINMENT_WAGONS_ENDPOINT}/${wagonId}/report`,
  }).then((blob) => {
    const url = window.URL.createObjectURL(new Blob([blob.data]))
    const a = document.createElement('a')
    a.href = url
    a.download = `Wagonzettel_${wagonNo}.pdf`
    a.click()
    a.remove()
    setTimeout(() => window.URL.revokeObjectURL(url), 100)
  })
}

export const searchWagons = (
  searchTerm: string,
): Promise<AxiosResponse<any>> => { // Wagon[]
  return request({
    method: 'get',
    url: `${ENTRAINMENT_WAGONS_ENDPOINT}/search`,
    params: { searchTerm },
  })
}
