import { Reducer, Dispatch } from 'redux'

import { AppState, GenericThunk } from 'typescript/common.types'
import * as api from './settings.service'
import * as types from './settings.types'
import {
  State,
  ActionTypes,
  Action,
  ChangePasswordRequestData,
} from './settings.types'

/**
 * Initial State
 */
export const initialState: State = {
  isLoading: false,
}

/**
 * Actions
 */
const changePasswordPending = (): Action => ({
  type: ActionTypes.CHANGE_PASSWORD_PENDING,
})
const changePasswordError = (error: Error): Action => ({
  type: ActionTypes.CHANGE_PASSWORD_ERROR,
  error,
})
const changePasswordSuccess = (): Action => ({
  type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
})

/**
 * Thunks
 */
export const changePassword = (
  credentials: ChangePasswordRequestData,
): GenericThunk<Promise<boolean>> => {
  return async (dispatch: Dispatch): Promise<boolean> => {
    dispatch(changePasswordPending())

    try {
      const { data } = await api.changePassword(credentials)

      dispatch(changePasswordSuccess())

      return data
    } catch (error) {
      if (error instanceof Error) {
        dispatch(changePasswordError(error))
      }
    }
    return false
  }
}

/**
 * Selectors
 */
const selectIsLoading = (state: AppState): boolean => state.auth.isLoading

/**
 * Reducer
 */
export const reducer: Reducer<State, Action> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case ActionTypes.CHANGE_PASSWORD_PENDING:
      return { ...state, isLoading: true }

    case ActionTypes.CHANGE_PASSWORD_ERROR:
      return { ...state, isLoading: false }

    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return { ...state, isLoading: false }

    default:
      return state
  }
}

export default {
  types,
  actions: {
    changePassword,
    changePasswordPending,
    changePasswordError,
    changePasswordSuccess,
  },
  selectors: {
    selectIsLoading,
  },
  reducer,
}
