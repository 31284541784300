// @ts-nocheck
import React, { Fragment, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up'
import TextField from '@atlaskit/textfield'
import Button, { ButtonGroup } from '@atlaskit/button'
import InlineEdit from '@atlaskit/inline-edit'
import styled from 'styled-components'
import { Flex } from 'reflexbox'
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table'

import { cleanNumber, formatContainerType, formatDate, formatDateTime, getYear } from 'utils/format.utils'
import modules from 'modules'
import { Container } from './containers.types'
import { useConfirmation } from 'components/confirmation-modal.service'
import { DatePicker } from '@atlaskit/datetime-picker'
import MaskedInput from 'react-text-mask'
import dayjs from 'dayjs'

const ReadViewContainer = styled.div`
  cursor: pointer;
`

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: {
  preGlobalFilteredRows: any
  globalFilter: any
  setGlobalFilter: any
}) => {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <TextField
      isCompact
      width={300}
      placeholder={`${count} Einträge durchsuchen`}
      elemBeforeInput={<EditorSearchIcon label="Suchen" />}
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
    />
  )
}

interface Props {
  containers: Container[]
  onShowDetails: (containerId: string) => void
}

const Table = ({ columns, data }: { columns: any; data: any }) => {
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state,

    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 25,
        sortBy: [{ id: 'createdAt', desc: true }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const { pageIndex, globalFilter } = state

  return (
    <Fragment>
      <Flex justifyContent="flex-end">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Flex>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <Flex>
                    {column.render('Header')}

                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ChevronDownIcon label="Absteigend sortieren" />
                      ) : (
                        <ChevronUpIcon label="Aufsteigend sortieren" />
                      )
                    ) : (
                      /**
                       * Placeholder for sort icons. Was this empty or null,
                       * adding an icon would always shift the whole table
                       * due to readjustments in header width and height.
                       *
                       * By using a placeholder with the same size as the icons,
                       * we can completely get rid of this effect.
                       */
                      <div style={{ height: 24, width: 24 }}></div>
                    )}
                  </Flex>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((page: any) => {
            prepareRow(page)
            return (
              <tr {...page.getRowProps()}>
                {page.cells.map((cell: any) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <br />
      <Flex justifyContent="space-between">
        <ButtonGroup appearance="default">
          <Button onClick={() => gotoPage(0)} isDisabled={!canPreviousPage}>
            {'<<'} Erste
          </Button>
          <Button onClick={() => previousPage()} isDisabled={!canPreviousPage}>
            {'<'} Vorherige
          </Button>
          <Button onClick={() => nextPage()} isDisabled={!canNextPage}>
            Nächste {'>'}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            isDisabled={!canNextPage}
          >
            Letzte {'>>'}
          </Button>
        </ButtonGroup>
        <span>
          Seite{' '}
          <strong>
            {pageIndex + 1} von {pageOptions.length}
          </strong>{' '}
        </span>
      </Flex>
      <br />
      <br />
    </Fragment>
  )
}

const ContainerTable: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { containers, onShowDetails } = props

  const dispatch = useDispatch()
  const confirm = useConfirmation()

  const editContainerNumber = async (
    containerId: string,
    changeField: string,
    newValue: string | Date,
  ) => {
    const container = containers.find((container) => {
      return container._id === containerId
    })

    const cleanValue = cleanNumber(newValue)

    if (cleanValue === container[changeField]) {
      return container[changeField] = newValue
    }
    else {
      container[changeField] = cleanValue
    }

    return dispatch(modules.containers.actions.editContainer(container))
  }

  const editContainerString = async (
    containerId: string,
    changeField: string,
    newValue: string | Date,
  ) => {
    const container = containers.find((container) => {
      return container._id === containerId
    })

    container[changeField] = newValue

    return dispatch(modules.containers.actions.editContainer(container))
  }

  const editContainerDate = async (
    containerId: string,
    changeField: string,
    newValue: string | Date,
  ) => {
    const container = containers.find((container) => {
      return container._id === containerId
    })

    container[changeField] = newValue

    return dispatch(modules.containers.actions.editContainer(container))
  }

  const readViewComponent = (value) => {
    if(value === null) {
      return <ReadViewContainer>n.a.</ReadViewContainer>
    }
    else {
      return <ReadViewContainer>{value}</ReadViewContainer>
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Behälter-Nr.',
        Cell: ({
          row: {
            original: { containerId, containerNr },
          },
        }) => (
          <InlineEdit
            defaultValue={containerNr}
            editView={(fieldProps) => <TextField {...fieldProps} autoFocus />}
            readView={() => (
              <ReadViewContainer>
                {containerNr ? containerNr : 'n.a.'}
              </ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              editContainerString(containerId, 'containerNr', newValue)
            }}
          />
        ),
        accessor: 'containerNr',
      },
      {
        Header: 'Container-Typ',
        accessor: 'type',
      },
      {
        Header: 'Baujahr',
        Cell: ({
          row: {
            original: { containerId, dateBuilt },
          },
        }) => (
          <InlineEdit
            defaultValue={dayjs(dateBuilt).format('YYYY')}
            editView={(fieldProps) => <MaskedInput
              mask={[
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
              ]}
              guide={true}
                {...fieldProps}
                render={(ref, props) => (
                  <TextField
                    ref={ref}
                    {...(props as any)}
                    autoComplete="off"
                  />
                )}
            />}
            readView={() => <ReadViewContainer>{dateBuilt === null ? ("n.a.") : (getYear(dateBuilt))}</ReadViewContainer>}
            onConfirm={(newValue) => {
              editContainerDate(containerId, 'dateBuilt', newValue)
            }}
          />
        ),
        accessor: 'dateBuilt',
      },
      {
        Header: 'zul. Ges.-Gewicht',
        Cell: ({
          row: {
            original: { containerId, totalWeight },
          },
        }) => (
          <InlineEdit
            defaultValue={totalWeight}
            editView={(fieldProps) => <TextField {...fieldProps} autoFocus />}
            readView={() => (
              <ReadViewContainer>
                {totalWeight ? totalWeight : 'n.a.'}
              </ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              editContainerNumber(containerId, 'totalWeight', newValue)
            }}
          />
        ),
        accessor: 'totalWeight',
      },
      {
        Header: 'Behälterlänge',
        Cell: ({
          row: {
            original: { containerId, containerLenght },
          },
        }) => (
          <InlineEdit
            defaultValue={containerLenght}
            editView={(fieldProps) => <TextField {...fieldProps} autoFocus />}
            readView={() => (
              <ReadViewContainer>
                {containerLenght ? containerLenght : 'n.a.'}
              </ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              editContainerNumber(containerId, 'containerLenght', newValue)
            }}
          />
        ),
        accessor: 'containerLenght',
      },
      {
        Header: 'Behälterhöhe',
        Cell: ({
          row: {
            original: { containerId, containerHeight },
          },
        }) => (
          <InlineEdit
            defaultValue={containerHeight}
            editView={(fieldProps) => <TextField {...fieldProps} autoFocus />}
            readView={() => (
              <ReadViewContainer>
                {containerHeight ? containerHeight : 'n.a.'}
              </ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              editContainerNumber(containerId, 'containerHeight', newValue)
            }}
          />
        ),
        accessor: 'containerHeight',
      },
      {
        Header: 'Inhalt',
        Cell: ({
          row: {
            original: { containerId, volume },
          },
        }) => (
          <InlineEdit
            defaultValue={volume}
            editView={(fieldProps) => <TextField {...fieldProps} autoFocus />}
            readView={() => (
              <ReadViewContainer>
                {volume ? volume : 'n.a.'}
              </ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              editContainerNumber(containerId, 'volume', newValue)
            }}
          />
        ),
        accessor: 'volume',
      },
      {
        Header: 'Fabrik-Nr.',
        Cell: ({
          row: {
            original: { containerId, factoryNr },
          },
        }) => (
          <InlineEdit
            defaultValue={factoryNr}
            editView={(fieldProps) => <TextField {...fieldProps} autoFocus />}
            readView={() => (
              <ReadViewContainer>
                {factoryNr ? factoryNr : 'n.a.'}
              </ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              editContainerString(containerId, 'factoryNr', newValue)
            }}
          />
        ),
        accessor: 'factoryNr',
      },
      {
        Header: 'Eigengewicht',
        Cell: ({
          row: {
            original: { containerId, selfWeight },
          },
        }) => (
          <InlineEdit
            defaultValue={selfWeight}
            editView={(fieldProps) => <TextField {...fieldProps} autoFocus />}
            readView={() => (
              <ReadViewContainer>
                {selfWeight ? selfWeight : 'n.a.'}
              </ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              editContainerNumber(containerId, 'selfWeight', newValue)
            }}
          />
        ),
        accessor: 'selfWeight',
      },
      {
        Header: 'Behälterbreite',
        Cell: ({
          row: {
            original: { containerId, containerWidth },
          },
        }) => (
          <InlineEdit
            defaultValue={containerWidth}
            editView={(fieldProps) => <TextField {...fieldProps} autoFocus />}
            readView={() => (
              <ReadViewContainer>
                {containerWidth ? containerWidth : 'n.a.'}
              </ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              editContainerNumber(containerId, 'containerWidth', newValue)
            }}
          />
        ),
        accessor: 'containerWidth',
      },
      {
        Header: 'Verr.-Maß',
        Cell: ({
          row: {
            original: { containerId, vDimension },
          },
        }) => (
          <InlineEdit
            defaultValue={vDimension}
            editView={(fieldProps) => <TextField {...fieldProps} autoFocus />}
            readView={() => (
              <ReadViewContainer>
                {vDimension ? vDimension : 'n.a.'}
              </ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              editContainerNumber(containerId, 'vDimension', newValue)
            }}
          />
        ),
        accessor: 'vDimension',
      },
      {
        Header: 'Gültigkeit',
        Cell: ({
          row: {
            original: { containerId, validation },
          },
        }) => (
          <InlineEdit
            defaultValue={dayjs(validation).format('YYYY-MM-DD')}
            editView={(fieldProps) => (
              <DatePicker
                {...fieldProps}
                locale="de-DE"
                weekStartDay={1}
                dateFormat="DD.MM.YYYY"
              />
            )}
            readView={() => <ReadViewContainer>{formatDate(validation)}</ReadViewContainer>}
            onConfirm={(newValue) => {
              editContainerDate(containerId, 'validation', newValue)
            }}
          />
        ),
        accessor: 'validation',
      },
      /* {
        Header: 'Erstellungsdatum',
        accessor: 'createdAt',
        Cell: ({ value }) => value.split('###')[1],
      },
      {
        Header: 'Bearbeitet',
        accessor: 'updatedAt',
      }, */
      {
        Header: '',
        accessor: 'delete',
        Cell: ({
          row: {
            original: { containerId },
          },
        }) => (
          <Flex justifyContent="flex-end">
            <Button
              appearance="link"
              onClick={() =>
                confirm({
                  title: 'Behälter löschen?',
                  description:
                    'Der Behälter wird vollständig gelöscht und verliert somit Zugang zur ' +
                    'Applikation. Sämtliche Daten, die vom jeweiligen Behälter erstellt ' +
                    'oder verändert wurden bleiben allerdings erhalten.',
                })
                  .then(() =>
                    dispatch(modules.containers.actions.deleteContainers(containerId)),
                  )
                  .catch(() => null)
              }
            >
              Behälter löschen
            </Button>
          </Flex>
        ),
      },
    ],
    [dispatch, onShowDetails],
  )

  const rows = containers.map((container) => {
    const {
      _id,
      containerNr,
      type,
      dateBuilt,
      totalWeight,
      containerLenght,
      containerHeight,
      volume,
      factoryNr,
      selfWeight,
      containerWidth,
      vDimension,
      validation,
      createdAt,
      updatedAt,
    } = container

    return {
      containerId: _id,
      containerNr,
      type: formatContainerType(type),

      dateBuilt: getYear(dateBuilt),
      totalWeight,
      containerLenght,
      containerHeight,
      volume,
      factoryNr,
      selfWeight,
      containerWidth,
      vDimension,

      validation,
      /**
       * Search and sort depend on the accessor key. This means, we can either base
       * this on the ISO string or the formatted string, by passing either of them.
       * Here we want to search by the formatted string, but sort by the ISO string,
       * so we pass both as delimited string and display the formatted part only,
       * while still being able to sort correctly.
       */
      createdAt: `${createdAt}###${formatDateTime(createdAt)}`,
      updatedAt: formatDateTime(updatedAt),
    }
  })

  return <Table columns={columns} data={rows} />
}

export default ContainerTable
