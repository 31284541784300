import React from 'react'
import styled from 'styled-components'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { Field, FormFooter } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import Modal, {
  ModalTransition,
  ModalHeader,
  ModalTitle,
  ModalBody,
} from '@atlaskit/modal-dialog'
import { CreateVehicleParameters } from './vehicles.types'
import { DatePicker } from '@atlaskit/datetime-picker'
// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs'

const FooterButton = styled(Button)`
  margin-bottom: 20px;
`

interface FormProps {
  vehiclePlate: string
  huauDate: Date
  spDate: Date
  uvvDate: Date
}

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: (fields: CreateVehicleParameters) => void
}

const CreateVehicleModal: React.FunctionComponent<Props> = (
  props,
): JSX.Element => {
  const { isOpen, onClose, onSubmit } = props

  const date = dayjs().format('YYYY-MM-DD')

  const onSubmitClick = (fields: FormProps) => {
    onSubmit({
      ...fields,
    })
    onClose()
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} shouldCloseOnOverlayClick={false}>
          <Form<FormProps> onSubmit={(v) => onSubmitClick(v)}>
            {({ formProps }) => (
              <form {...formProps}>
                <ModalHeader>
                  <ModalTitle>Fahrzeug anlegen</ModalTitle>
                </ModalHeader>
                <ModalBody>
                  <Field name="vehiclePlate" label="Kennzeichen" isRequired>
                    {({ fieldProps }) => (
                      <TextField {...fieldProps} autoComplete="off" />
                    )}
                  </Field>

                  <Field
                    name="huauDate"
                    label="HU/AU"
                    isRequired={false}
                    defaultValue={date}
                  >
                    {({ fieldProps }) => (
                      <>
                        <DatePicker
                          {...fieldProps}
                          locale="de-DE"
                          weekStartDay={1}
                          dateFormat="DD.MM.YYYY"
                        />
                        {/* <HelperMessage>Help or instruction text goes here</HelperMessage> */}
                      </>
                    )}
                  </Field>

                  <Field
                    name="spDate"
                    label="SP"
                    isRequired={false}
                    defaultValue={date}
                  >
                    {({ fieldProps }) => (
                      <>
                        <DatePicker
                          {...fieldProps}
                          locale="de-DE"
                          weekStartDay={1}
                          dateFormat="DD.MM.YYYY"
                        />
                        {/* <HelperMessage>Help or instruction text goes here</HelperMessage> */}
                      </>
                    )}
                  </Field>

                  <Field
                    name="uvvDate"
                    label="UVV"
                    isRequired={false}
                    defaultValue={date}
                  >
                    {({ fieldProps }) => (
                      <>
                        <DatePicker
                          {...fieldProps}
                          locale="de-DE"
                          weekStartDay={1}
                          dateFormat="DD.MM.YYYY"
                        />
                        {/* <HelperMessage>Help or instruction text goes here</HelperMessage> */}
                      </>
                    )}
                  </Field>

                  <FormFooter>
                    <ButtonGroup>
                      <FooterButton onClick={onClose}>Abbrechen</FooterButton>
                      <FooterButton type="submit" appearance="primary">
                        anlegen
                      </FooterButton>
                    </ButtonGroup>
                  </FormFooter>
                </ModalBody>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default CreateVehicleModal
