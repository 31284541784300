import React from 'react'
import styled from 'styled-components'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { Field, FormFooter } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import Select, { ValueType } from '@atlaskit/select'
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'

import { cleanNumber } from 'utils/format.utils'
import { Position, HopperInputs } from './entrainment.types'

const FooterButton = styled(Button)`
  margin-bottom: 20px;
`

interface OptionType {
  label: string
  value: Position
}

interface FormProps {
  grossWeight: string
  netWeight: string
  position: OptionType,
  weightNr: string
}

interface Props {
  isOpenHopperId: string | null
  onClose: () => void
  onSubmit: (fields: HopperInputs) => void
}

const positions: OptionType[] = [
  { label: 'A (Anfang)', value: Position.A },
  { label: 'M (Mitte)', value: Position.M },
  { label: 'E (Ende)', value: Position.E },
]

const LoadHopperModal: React.FunctionComponent<Props> = (
  props,
): JSX.Element => {
  const { isOpenHopperId, onClose, onSubmit } = props

  const onSubmitClick = (fields: FormProps) => {
    const { grossWeight, netWeight, position, weightNr } = fields

    if (!isOpenHopperId) return

    onSubmit({
      hopperId: isOpenHopperId,
      grossWeight: cleanNumber(grossWeight),
      netWeight: cleanNumber(netWeight),
      position: position.value,
      weightNr: weightNr
    })
    onClose()
  }

  return (
    <ModalTransition>
      {isOpenHopperId && (
        <Modal onClose={onClose} shouldCloseOnOverlayClick={false}>
          <Form<FormProps> onSubmit={(fields) => onSubmitClick(fields)}>
            {({ formProps }) => (
              <form {...formProps}>
                <ModalHeader>
                  <ModalTitle>Muldenbeladung Dokumentieren</ModalTitle>
                </ModalHeader>

                <ModalBody>
                  <div className="hidden">
                    <Field name="grossWeight" label="Bruttogewicht">
                      {({ fieldProps }) => (
                        <MaskedInput
                          mask={createNumberMask({
                            prefix: '',
                            suffix: ' kg',
                            thousandsSeparatorSymbol: '.',
                          })}
                          {...fieldProps}
                          render={(ref, props) => (
                            <TextField
                              ref={ref}
                              {...(props as any)}
                              autoComplete="off"
                              placeholder="In Kilogramm angeben"
                            />
                          )}
                        />
                      )}
                    </Field>
                  </div>

                  <Field name="netWeight" label="Nettogewicht" isRequired>
                    {({ fieldProps }) => (
                      <MaskedInput
                        mask={createNumberMask({
                          prefix: '',
                          suffix: ' kg',
                          thousandsSeparatorSymbol: '.',
                        })}
                        {...fieldProps}
                        render={(ref, props) => (
                          <TextField
                            ref={ref}
                            {...(props as any)}
                            autoComplete="off"
                            placeholder="In Kilogramm angeben"
                          />
                        )}
                      />
                    )}
                  </Field>

                  <Field name="weightNr" label="Wiegeschein-Nr." isRequired>
                    {({ fieldProps }) => <TextField {...fieldProps} />}
                  </Field>

                  <Field<ValueType<OptionType, false>>
                    name="position"
                    label="Position"
                    defaultValue={positions[0]}
                  >
                    {({ fieldProps }) => (
                      <Select<OptionType>
                        options={positions}
                        placeholder="Bitte auswählen"
                        isSearchable={false}
                        menuPlacement="top"
                        {...fieldProps}
                      />
                    )}
                  </Field>

                  <FormFooter>
                    <ButtonGroup>
                      <FooterButton onClick={onClose}>Abbrechen</FooterButton>
                      <FooterButton type="submit" appearance="primary">
                        Übermitteln
                      </FooterButton>
                    </ButtonGroup>
                  </FormFooter>
                </ModalBody>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default LoadHopperModal
