import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'

import modules from 'modules'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ChecklistCheckBlock from './checklist-check-block.component'
import { Status } from './checklist.types'
import Select from '@atlaskit/select';
import Button, { ButtonGroup } from '@atlaskit/button'
import TextArea from '@atlaskit/textarea'
import { FormFooter } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import dayjs from 'dayjs'

interface Props {
  checklistId: string
  checklistType: string
  checklistData: []
  status: Status
  vehiclePlate: string
  checklistMessage: string
}

const FooterButton = styled(Button)`
  margin-bottom: 20px;
`

const VehicleChecklist: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { checklistId, checklistType, checklistData, status, vehiclePlate, checklistMessage } = props

  const [stateChecklistMessage, setStateChecklistMessage] = useState(checklistMessage)
  const [stateVehiclePlate, setStateVehiclePlate] = useState(vehiclePlate)

  const [huauDate, setHuauDate] = useState<Date | null>(null)
  const [spDate, setSpDate] = useState<Date | null>(null)
  const [uvvDate, setUvvDate] = useState<Date | null>(null)

  const history = useHistory()
  const dispatch = useDispatch()

  const vehicles = useSelector(modules.vehicles.selectors.selectVehicles)
  const isLoadingVehicles = useSelector(modules.vehicles.selectors.selectIsLoading)

  const vehicleOptions:any = []

  vehicles.forEach((vehicle) => {
    vehicleOptions.push({'value': vehicle.vehiclePlate, 'label': vehicle.vehiclePlate})
  })

  useEffect(() => {
    dispatch(modules.vehicles.actions.fetchVehicles())
  }, [dispatch])

  const renderChecklistBlocks = () => {
    if (checklistData.length === 0) {
      return <div>keine Daten verfügbar.</div>
    }

    return checklistData.map(function (checkBlock: any, index: number) {
      return (
        <ChecklistCheckBlock
          key={index}
          checklistId={checklistId}
          checklistBlock={checkBlock}
          status={status}
          blockNumber={index + 1}
        />
      )
    })
  }
  
  const routeBack = () => {
    if(checklistType === 'Vehicle') {
      history.push('/checklists/vehicle')
    }
    if(checklistType === 'Excavator') {
      history.push('/checklists/excavator')
    }
  }

  const onSubmitClick = () => {
    dispatch(
      modules.checklist.actions.updateChecklist(checklistId, {
        status: Status.Finished,
        vehiclePlate: stateVehiclePlate,
        containerNr: '',
        checklistMessage: stateChecklistMessage,
      }),
    )
    routeBack()
  }

  const onCancel = () => {
    dispatch(
      modules.checklist.actions.updateChecklist(checklistId, {
        status: Status.Cancelled,
        vehiclePlate: stateVehiclePlate,
        containerNr: '',
        checklistMessage: stateChecklistMessage,
      }),
    )
    routeBack()
  }

  const handleVehicleSelect = (selectedOption: any) => {
    setStateVehiclePlate(selectedOption.value)

    const vehicle = vehicles.filter((vehicle) => vehicle.vehiclePlate === selectedOption.value)
    setHuauDate(vehicle[0].huauDate)
    setSpDate(vehicle[0].spDate)
    setUvvDate(vehicle[0].uvvDate)
  }

  const handleChecklistMessage = (selectedOption: any) => {
    setStateChecklistMessage(selectedOption.target.value)
  }

  return (
    <form className='checklist-form' onSubmit={() => onSubmitClick()}>

      <label htmlFor="select-vehicle" className="font-bold">Fahrzeug auswählen (Kennzeichen)</label>
      {!isLoadingVehicles && status === 'started' ? (
        <Select
          inputId="select-vehicle"
          options={vehicleOptions}
          onChange={handleVehicleSelect}
          placeholder="Auswählen..."
          isRequired
        />
      ) : (
        <TextField
          name="vehiclePlate"
          defaultValue={stateVehiclePlate}
          isDisabled={status === 'finished' || status === 'cancelled'}
          isRequired
        />
      )}

      <div className="my-4">
        <div className='font-bold'>Fahrzeugdaten:</div>
        <div className="flex">
          {huauDate &&
            <div className="">HU/AU: <span className="underline">{dayjs(huauDate).format('DD.MM.YYYY')}</span></div>
          }
          {spDate &&
            <div className="ml-2">SP:  <span className="underline">{dayjs(spDate).format('DD.MM.YYYY')}</span></div>
          }
          {uvvDate &&
            <div className="ml-2">UVV:  <span className="underline">{dayjs(uvvDate).format('DD.MM.YYYY')}</span></div>
          }
          {!huauDate &&
            <div>Fahrzeug wählen...</div>
          }
        </div>
      </div>

      <div className="grid lg:grid-cols-2 xl:grid-cols-3">
        {renderChecklistBlocks()}
      </div>

      <div className='font-bold'>Bemerkugen:</div>
      <TextArea
        resize="auto"
        maxHeight="20vh"
        name="checklist_message"
        isDisabled={status === 'finished' || status === 'cancelled'}
        onChange={handleChecklistMessage}
        defaultValue={stateChecklistMessage}
      />

      <FormFooter>
        <ButtonGroup>
          {status === 'finished' || status === 'cancelled' ? (
            <>
              <FooterButton onClick={() => routeBack()} appearance="primary">
                Zurück
              </FooterButton>
            </>
          ) : (
            <>
              <FooterButton onClick={onCancel}>Prüfung abbrechen</FooterButton>
              <FooterButton type="submit" appearance="primary">
                Prüfung beenden
              </FooterButton>
            </>
          )}
        </ButtonGroup>
      </FormFooter>
    </form>
  )
}

export default VehicleChecklist
