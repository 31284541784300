import React, { ChangeEvent, useState, useEffect } from 'react'
import { Checkbox } from '@atlaskit/checkbox';
import modules from 'modules'
import { useDispatch } from 'react-redux';
import { Status } from './checklist.types'

interface Props {
  checklistId: string,
  status: Status,
  checkId: string,
  checkDescription: string,
  checkFaults: boolean|null,
  blockFaults: boolean,
  counter: number
}

const ChecklistCheck: React.FunctionComponent<Props> = (props): JSX.Element => {

  const {
    checklistId,
    status,
    checkId,
    checkDescription,
    checkFaults,
  } = props

  const [faults, setFaults] = useState<
    boolean | null
  >(checkFaults);

  useEffect(() => {
    if(props.blockFaults) {
      setFaults(props.checkFaults)
    }
  }, [props.counter])

  const dispatch = useDispatch()

  const handleCheck = (checkId: string, checkFaults: boolean | null) => {
    dispatch(modules.checklist.actions.updateCheckFromChecklist(checklistId, checkId, checkFaults))
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.currentTarget.value) {
      case 'yes':
        setFaults(event.currentTarget.checked ? true : null)
        handleCheck(checkId, event.currentTarget.checked ? true : null)
        break;
      case 'no':
        setFaults(event.currentTarget.checked ? false : null)
        handleCheck(checkId, event.currentTarget.checked ? false : null)
        break;
      default:
        break;
    }
  }

  const markCheck = (status: string, faults: boolean | null) => {
    if((status === 'finished' || status === 'cancelled') && faults === true) {
      return ' mark-check-red'
    }
    return ''
  }

  // windowsDimension
  /* const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight })

  useEffect(() => {
      const handleResize = () => {
          const { innerWidth: width, innerHeight: height } = window
          setWindowDimensions({ width, height })
      }

      window.addEventListener('resize', handleResize)

      return () => window.removeEventListener('resize', handleResize)
  }, [])

  const returnCheckboxSize = () => {
    if(windowDimensions.width <= 467) {
      return 'xlarge'
    }
    else {
      return 'large'
    }
  } */

  return (
    <div className={'flex justify-between mt-4 mb-4' + markCheck(status, faults)}>
      <div>{checkDescription}</div>
      <div className="flex">
        <div className="pr-4">
          <Checkbox
            size="large"
            isChecked={faults === true}
            label="ja"
            value="yes"
            onChange={(e) => onChange(e)}
            isRequired={faults === null}
            isDisabled={status === 'finished' || status === 'cancelled'}
          />
        </div>
        <div>
          <Checkbox
            size="large"
            isChecked={faults === false}
            label="nein"
            value="no"
            onChange={(e) => onChange(e)}
            isRequired={faults === null}
            isDisabled={status === 'finished' || status === 'cancelled'}
          />
        </div>
      </div>
    </div>
  )
}

export default ChecklistCheck