import * as yup from 'yup'

import { UserRole } from 'modules/users/users.types'

export const userSchema = yup.object({
  _id: yup.string().required(),
  role: yup.string().oneOf(Object.values(UserRole)).required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
})
