export enum ActionTypes {
  SET_VERSION = 'CORE/SET_VERSION',
  SHOW_FLAG = 'CORE/SHOW_FLAG',
  HIDE_FLAG = 'CORE/HIDE_FLAG',
}

export interface Version {
  version: string
}

export enum FlagAppearance {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export interface FlagConfig {
  title: string
  description: string
  appearance: FlagAppearance
}

export interface State {
  version: string
  flag: FlagConfig | null
}

export interface SetVersionAction {
  type: ActionTypes.SET_VERSION
  payload: Version
}

export interface ShowFlagAction {
  type: ActionTypes.SHOW_FLAG
  payload: { config: FlagConfig }
}

export interface HideFlagAction {
  type: ActionTypes.HIDE_FLAG
}

export type Action = SetVersionAction | ShowFlagAction | HideFlagAction
