import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Page from '@atlaskit/page'

import modules from 'modules'
import PageHeader from 'components/page-header.component'
import ExplanationButton from 'components/explanation-button.component'

const LauncherGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, auto));
  grid-gap: 16px;
`

const StartScreen: React.FunctionComponent = (): JSX.Element => {
  const history = useHistory()
  const dispatch = useDispatch()

  const navigateTo = (path: string) => history.push(path)
  const signOut = () => dispatch({ type: 'RESET_STATE' })

  const isAdminUser = useSelector(modules.auth.selectors.selectIsAdminUser)

  const userPermissions = useSelector(
    modules.auth.selectors.selectUserPermissions,
  )

  return (
    <Page>
      <PageHeader title="Applikationen" breadcrump="Startseite" />
      <LauncherGrid>
        {isAdminUser ||
        userPermissions?.includes('entrainmentViewPermission') ? (
          <ExplanationButton
            title="Wagonverladung"
            description="Hier wird die Wagonverladung durch automatisierte 
              Berechnungen, Verwaltung und Dokumentation digital unterstützt."
            icon={<i className="text-2xl fa-solid fa-train"></i>}
            onClick={() => navigateTo('/entrainment/wagons')}
          />
        ) : null}
        {isAdminUser || userPermissions?.includes('usersViewPermission') ? (
          <ExplanationButton
            title="Benutzerverwaltung"
            description="Hier können sämtliche bereits existierende Benutzer 
            verwaltet und neue hinzugefügt werden."
            icon={<i className="text-2xl fa-solid fa-users"></i>}
            onClick={() => navigateTo('/admin/users')}
          />
        ) : null}
        {isAdminUser || userPermissions?.includes('vehiclesViewPermission') ? (
          <ExplanationButton
            title="Fahrzeugverwaltung"
            description="Hier können sämtliche Fahrzeuge verwaltet und neu hinzugefügt werden."
            icon={<i className="text-2xl fa-solid fa-truck-container"></i>}
            onClick={() => navigateTo('/vehicles')}
          />
        ) : null}
        {isAdminUser || userPermissions?.includes('vehicleViewPermission') ? (
          <ExplanationButton
            title="Fahrzeugprüfung"
            description="Fahrzeugprüfung für Absetzkipperfahrzeuge und Abrollkipperfahrzeuge nach DGUV 214-016/DGUV 214-017."
            icon={<i className="text-2xl fa-solid fa-list-check"></i>}
            onClick={() => navigateTo('/checklists/vehicle')}
          />
        ) : null}
        {isAdminUser || userPermissions?.includes('excavatorViewPermission') ? (
          <ExplanationButton
            title="Baggergprüfung"
            description="Baggerprüfung nach BG BAU (Berufsgenossenschaft der Bauwirtschaft)."
            icon={<i className="text-2xl fa-solid fa-list-check"></i>}
            onClick={() => navigateTo('/checklists/excavator')}
          />
        ) : null}
        {isAdminUser ||
        userPermissions?.includes('containersViewPermission') ? (
          <ExplanationButton
            title="Behälterverwaltung"
            description="Hier können sämtliche Mulden und Container verwaltet und neu hinzugefügt werden."
            icon={<i className="text-2xl fa-solid fa-container-storage"></i>}
            onClick={() => navigateTo('/containers')}
          />
        ) : null}
        {isAdminUser || userPermissions?.includes('containerViewPermission') ? (
          <ExplanationButton
            title="Behälterprüfung"
            description="Behälterprüfung für Absetzmulden/Abrollcontainer nach DGUV 214-016/DGUV 214-017."
            icon={<i className="text-2xl fa-solid fa-list-check"></i>}
            onClick={() => navigateTo('/checklists/container')}
          />
        ) : null}
        {isAdminUser ||
        userPermissions?.includes('instrumentsViewPermission') ? (
          <ExplanationButton
            title="Prüfmittelverwaltung"
            description="Hier können sämtliche Prüfmittel verwaltet und neu hinzugefügt werden."
            icon={<i className="text-2xl fa-solid fa-screwdriver-wrench"></i>}
            onClick={() => navigateTo('/instruments')}
          />
        ) : null}
        {isAdminUser ||
        userPermissions?.includes('controllingViewPermission') ? (
          <ExplanationButton
            title="Abrechnungsprüfung"
            description="Automatisierte Auswertung von Wiegedaten und Artikelauswertungen."
            icon={<i className="text-2xl fa-solid fa-microchip-ai"></i>}
            onClick={() => navigateTo('/admin/controlling')}
          />
        ) : null}
        <ExplanationButton
          title="Zeiterfassung"
          description="Zeiterfassung für Mitarbeiter der Karl Joos GmbH."
          icon={<i className="text-2xl fa-solid fa-clock"></i>}
          onClick={() => navigateTo('/')}
        />
        <ExplanationButton
          title="Betriebsdokumente"
          description="Betriebsanweisungen und Dokumente der Karl Joos GmbH."
          icon={<i className="text-2xl fa-solid fa-memo"></i>}
          onClick={() => navigateTo('/ftp-documents')}
        />
      </LauncherGrid>

      <PageHeader title="Benutzer" />
      <LauncherGrid>
        <ExplanationButton
          title="Einstellungen"
          description="Individuelle Anpassungen und die Verwaltung des Kontos 
            durch den Benutzer."
          icon={<i className="text-2xl fa-solid fa-gear"></i>}
          onClick={() => navigateTo('/settings')}
        />
        <ExplanationButton
          title="Abmelden"
          description="Meldet den derzeit angemeldeten Benutzer vom System ab."
          icon={
            <i className="text-2xl fa-solid fa-arrow-right-from-bracket"></i>
          }
          onClick={signOut}
        />
      </LauncherGrid>
    </Page>
  )
}

export default StartScreen
