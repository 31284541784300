export enum ActionTypes {
  CREATE_VEHICLE_PENDING = 'VEHICLES/CREATE_VEHICLE_PENDING',
  CREATE_VEHICLE_ERROR = 'VEHICLES/CREATE_VEHICLE_ERROR',
  CREATE_VEHICLE_SUCCESS = 'VEHICLES/CREATE_VEHICLE_SUCCESS',
  EDIT_VEHICLE_PENDING = 'VEHICLES/EDIT_VEHICLE_PENDING',
  EDIT_VEHICLE_ERROR = 'VEHICLES/EDIT_VEHICLE_ERROR',
  EDIT_VEHICLE_SUCCESS = 'VEHICLES/EDIT_VEHICLE_SUCCESS',
  FETCH_VEHICLES_PENDING = 'VEHICLES/FETCH_VEHICLES_PENDING',
  FETCH_VEHICLES_ERROR = 'VEHICLES/FETCH_VEHICLES_ERROR',
  FETCH_VEHICLES_SUCCESS = 'VEHICLES/FETCH_VEHICLES_SUCCESS',
  DELETE_VEHICLES_PENDING = 'VEHICLES/DELETE_VEHICLES_PENDING',
  DELETE_VEHICLES_ERROR = 'VEHICLES/DELETE_VEHICLES_ERROR',
  DELETE_VEHICLES_SUCCESS = 'VEHICLES/DELETE_VEHICLES_SUCCESS',
  SAVE_VEHICLES = 'VEHICLES/SAVE_VEHICLES',
}

/**
 * Vehicle
 */
export interface Vehicle {
  _id: string
  vehiclePlate: string
  huauDate: Date
  spDate: Date
  uvvDate: Date
  createdAt: Date
  updatedAt: Date
}

export interface State {
  isLoading: boolean
  isLoadingEdit: boolean
  vehicles: {
    byId: {
      [id: string]: Vehicle
    }
    allIds: string[]
  }
}

export interface CreateVehicleParameters {
  vehiclePlate: string
  huauDate: Date
  spDate: Date
  uvvDate: Date
}

export interface CreateVehiclePendingAction {
  type: ActionTypes.CREATE_VEHICLE_PENDING
}

export interface CreateVehicleErrorAction {
  type: ActionTypes.CREATE_VEHICLE_ERROR
  error: Error
}

export interface CreateVehicleSuccessAction {
  type: ActionTypes.CREATE_VEHICLE_SUCCESS
}

export interface EditVehiclePendingAction {
  type: ActionTypes.EDIT_VEHICLE_PENDING
}

export interface EditVehicleErrorAction {
  type: ActionTypes.EDIT_VEHICLE_ERROR
  error: Error
}

export interface EditVehicleSuccessAction {
  type: ActionTypes.EDIT_VEHICLE_SUCCESS
}

export interface FetchVehiclesPendingAction {
  type: ActionTypes.FETCH_VEHICLES_PENDING
}

export interface FetchVehiclesErrorAction {
  type: ActionTypes.FETCH_VEHICLES_ERROR
  error: Error
}

export interface FetchVehiclesSuccessAction {
  type: ActionTypes.FETCH_VEHICLES_SUCCESS
}

export interface DeleteVehiclesPendingAction {
  type: ActionTypes.DELETE_VEHICLES_PENDING
}

export interface DeleteVehiclesErrorAction {
  type: ActionTypes.DELETE_VEHICLES_ERROR
  error: Error
}

export interface DeleteVehiclesSuccessAction {
  type: ActionTypes.DELETE_VEHICLES_SUCCESS
  payload: {
    vehicleId: string
  }
}

export interface SaveVehiclesAction {
  type: ActionTypes.SAVE_VEHICLES
  meta: {
    shouldReplace: boolean
  }
  payload: {
    vehicles: Vehicle[]
  }
}

export type Action =
  | CreateVehiclePendingAction
  | CreateVehicleErrorAction
  | CreateVehicleSuccessAction
  | EditVehiclePendingAction
  | EditVehicleErrorAction
  | EditVehicleSuccessAction
  | FetchVehiclesPendingAction
  | FetchVehiclesErrorAction
  | FetchVehiclesSuccessAction
  | DeleteVehiclesPendingAction
  | DeleteVehiclesErrorAction
  | DeleteVehiclesSuccessAction
  | SaveVehiclesAction