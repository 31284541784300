import * as yup from 'yup'

export const instrumentSchema = yup.object({
  _id: yup.string().required(),
  instrumentName: yup.string().required(),
  instrumentTag: yup.string().nullable(),
  lastCheckDate: yup.date(),
  nextCheckDate: yup.date(),
  instrumentCheckType: yup.string().nullable(),
  instrumentSerial: yup.string().nullable(),
  createdAt: yup.date().required(),
  updatedAt: yup.date().required(),
})
