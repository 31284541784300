import React from 'react'

import BreadcrumbsStateless, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'

import AKPageHeader from '@atlaskit/page-header'

interface Props {
  breadcrump?: string,
  path?: string,
  title: string
  actions?: JSX.Element
}

const PageHeader: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { breadcrump = '', path, title, actions } = props

  const breadcrumbs = (
    <BreadcrumbsStateless onExpand={() => null}>
      <BreadcrumbsItem text="Karl Joos GmbH" key="kjg" href='/' />
      <BreadcrumbsItem text={breadcrump} key={breadcrump} href={path} />
    </BreadcrumbsStateless>
  )

  const optionalProps = breadcrump
    ? {
        breadcrumbs,
      }
    : {}

  return (
    <AKPageHeader {...optionalProps} actions={actions}>
      {title}
    </AKPageHeader>
  )
}

export default PageHeader
