import { User, UserRole } from 'modules/users/users.types'

export enum ActionTypes {
  AUTHENTICATE_PENDING = 'AUTH/AUTHENTICATE_PENDING',
  AUTHENTICATE_ERROR = 'AUTH/AUTHENTICATE_ERROR',
  AUTHENTICATE_SUCCESS = 'AUTH/AUTHENTICATE_SUCCESS',
}

export interface DecodedJWT {
  user: {
    _id: string
    role: UserRole
  }
  iat: number
  exp: number
}

export interface State {
  isLoading: boolean
  user: User | null
  token: DecodedJWT | null
}

export interface AuthenticationRequestData {
  username: string
  password: string
}

export interface AuthenticationResponseData {
  user: User
  token: string
}

export interface AuthenticatePendingAction {
  type: ActionTypes.AUTHENTICATE_PENDING
}

export interface AuthenticateErrorAction {
  type: ActionTypes.AUTHENTICATE_ERROR
  error: Error
}

export interface AuthenticateSuccessAction {
  type: ActionTypes.AUTHENTICATE_SUCCESS
  payload: {
    user: User
    token: DecodedJWT
  }
}

export type Action =
  | AuthenticatePendingAction
  | AuthenticateErrorAction
  | AuthenticateSuccessAction
