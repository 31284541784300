import * as yup from 'yup'

export const contactSchema = yup.object({
  _id: yup.string().required(),
  name: yup.string().required(),
  address: yup.string().required(),
  zip: yup.number().required(),
  city: yup.string().required(),
  emails: yup.array(yup.string()).required(),
  createdAt: yup.date().required(),
  updatedAt: yup.date().required(),
})
