// @ts-nocheck
import React, { Fragment, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up'
import TextField from '@atlaskit/textfield'
import Button, { ButtonGroup } from '@atlaskit/button'
import InlineEdit from '@atlaskit/inline-edit'
import styled from 'styled-components'
import { Flex } from 'reflexbox'
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table'

import { formatDate, formatDateTime } from 'utils/format.utils'
import modules from 'modules'
import { Vehicle } from './vehicles.types'
import { useConfirmation } from 'components/confirmation-modal.service'
import { DatePicker } from '@atlaskit/datetime-picker'
import dayjs from 'dayjs'

const ReadViewContainer = styled.div`
  cursor: pointer;
`

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: {
  preGlobalFilteredRows: any
  globalFilter: any
  setGlobalFilter: any
}) => {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <TextField
      isCompact
      width={300}
      placeholder={`${count} Einträge durchsuchen`}
      elemBeforeInput={<EditorSearchIcon label="Suchen" />}
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
    />
  )
}

interface Props {
  vehicles: Vehicle[]
  onShowDetails: (vehicleId: string) => void
}

const Table = ({ columns, data }: { columns: any; data: any }) => {
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state,

    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 25,
        sortBy: [{ id: 'vehiclePlate', desc: true }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const { pageIndex, globalFilter } = state

  return (
    <Fragment>
      <Flex justifyContent="flex-end">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Flex>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <Flex>
                    {column.render('Header')}

                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ChevronDownIcon label="Absteigend sortieren" />
                      ) : (
                        <ChevronUpIcon label="Aufsteigend sortieren" />
                      )
                    ) : (
                      /**
                       * Placeholder for sort icons. Was this empty or null,
                       * adding an icon would always shift the whole table
                       * due to readjustments in header width and height.
                       *
                       * By using a placeholder with the same size as the icons,
                       * we can completely get rid of this effect.
                       */
                      <div style={{ height: 24, width: 24 }}></div>
                    )}
                  </Flex>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((page: any) => {
            prepareRow(page)
            return (
              <tr {...page.getRowProps()}>
                {page.cells.map((cell: any) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <br />
      <Flex justifyContent="space-between">
        <ButtonGroup appearance="default">
          <Button onClick={() => gotoPage(0)} isDisabled={!canPreviousPage}>
            {'<<'} Erste
          </Button>
          <Button onClick={() => previousPage()} isDisabled={!canPreviousPage}>
            {'<'} Vorherige
          </Button>
          <Button onClick={() => nextPage()} isDisabled={!canNextPage}>
            Nächste {'>'}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            isDisabled={!canNextPage}
          >
            Letzte {'>>'}
          </Button>
        </ButtonGroup>
        <span>
          Seite{' '}
          <strong>
            {pageIndex + 1} von {pageOptions.length}
          </strong>{' '}
        </span>
      </Flex>
      <br />
      <br />
    </Fragment>
  )
}

const VehicleTable: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { vehicles, onShowDetails } = props

  const dispatch = useDispatch()
  const confirm = useConfirmation()

  const editVehicle = async (
    vehicleId: string,
    changeField: string,
    newValue: string | Date,
  ) => {
    const vehicle = vehicles.find((vehicle) => {
      return vehicle._id === vehicleId
    })

    if (newValue === vehicle[changeField]) return
    vehicle[changeField] = newValue

    return dispatch(modules.vehicles.actions.editVehicle(vehicle))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Kennzeichen',
        Cell: ({
          row: {
            original: { vehicleId, vehiclePlate },
          },
        }) => (
          <InlineEdit
            defaultValue={vehiclePlate}
            editView={(fieldProps) => <TextField {...fieldProps} autoFocus />}
            readView={() => (
              <ReadViewContainer>{vehiclePlate}</ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              editVehicle(vehicleId, 'vehiclePlate', newValue)
            }}
          />
        ),
        accessor: 'vehiclePlate',
      },
      {
        Header: 'HU/AU',
        Cell: ({
          row: {
            original: { vehicleId, huauDate },
          },
        }) => (
          <InlineEdit
            defaultValue={dayjs(huauDate).format('YYYY-MM-DD')}
            editView={(fieldProps) => (
              <DatePicker
                {...fieldProps}
                dateFormat="DD.MM.YYYY"
                locale="de-DE"
                weekStartDay={1}
              />
            )}
            readView={() => (
              <ReadViewContainer>{formatDate(huauDate)}</ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              editVehicle(vehicleId, 'huauDate', newValue)
            }}
          />
        ),
        accessor: 'huauDate',
      },
      {
        Header: 'SP',
        Cell: ({
          row: {
            original: { vehicleId, spDate },
          },
        }) => (
          <InlineEdit
            defaultValue={dayjs(spDate).format('YYYY-MM-DD')}
            editView={(fieldProps) => (
              <DatePicker
                {...fieldProps}
                locale="de-DE"
                weekStartDay={1}
                dateFormat="DD.MM.YYYY"
              />
            )}
            readView={() => (
              <ReadViewContainer>{formatDate(spDate)}</ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              editVehicle(vehicleId, 'spDate', newValue)
            }}
          />
        ),
        accessor: 'spDate',
      },
      {
        Header: 'UVV',
        Cell: ({
          row: {
            original: { vehicleId, uvvDate },
          },
        }) => (
          <InlineEdit
            defaultValue={dayjs(uvvDate).format('YYYY-MM-DD')}
            editView={(fieldProps) => (
              <DatePicker
                {...fieldProps}
                locale="de-DE"
                weekStartDay={1}
                dateFormat="DD.MM.YYYY"
              />
            )}
            readView={() => (
              <ReadViewContainer>{formatDate(uvvDate)}</ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              editVehicle(vehicleId, 'uvvDate', newValue)
            }}
          />
        ),
        accessor: 'uvvDate',
      },
      {
        Header: 'Erstellungsdatum',
        accessor: 'createdAt',
        Cell: ({ value }) => value.split('###')[1],
      },
      {
        Header: 'Bearbeitet',
        accessor: 'updatedAt',
      },
      {
        Header: '',
        accessor: 'delete',
        Cell: ({
          row: {
            original: { vehicleId },
          },
        }) => (
          <Flex justifyContent="flex-end">
            <Button
              appearance="link"
              onClick={() =>
                confirm({
                  title: 'Fahrzeug löschen?',
                  description:
                    'Das Fahrzeug wird vollständig gelöscht und verliert somit Zugang zur ' +
                    'Applikation. Sämtliche Daten, die vom jeweiligen Fahrzeug erstellt ' +
                    'oder verändert wurden bleiben allerdings erhalten.',
                })
                  .then(() =>
                    dispatch(
                      modules.vehicles.actions.deleteVehicles(vehicleId),
                    ),
                  )
                  .catch(() => null)
              }
            >
              Fahrzeug löschen
            </Button>
          </Flex>
        ),
      },
    ],
    [dispatch, onShowDetails],
  )

  const rows = vehicles.map((vehicle) => {
    const {
      _id,
      vehiclePlate,
      huauDate,
      spDate,
      uvvDate,
      createdAt,
      updatedAt,
    } = vehicle

    return {
      vehicleId: _id,
      vehiclePlate,
      huauDate,
      spDate,
      uvvDate,
      /**
       * Search and sort depend on the accessor key. This means, we can either base
       * this on the ISO string or the formatted string, by passing either of them.
       * Here we want to search by the formatted string, but sort by the ISO string,
       * so we pass both as delimited string and display the formatted part only,
       * while still being able to sort correctly.
       */
      createdAt: `${createdAt}###${formatDateTime(createdAt)}`,
      updatedAt: formatDateTime(updatedAt),
    }
  })

  return <Table columns={columns} data={rows} />
}

export default VehicleTable
