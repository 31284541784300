import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from 'reflexbox'
import styled from 'styled-components'
import { Line } from 'rc-progress'
import { useHistory } from 'react-router-dom'
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler'
import DropdownMenu, { DropdownItem } from '@atlaskit/dropdown-menu'
import CheckIcon from '@atlaskit/icon/glyph/check'

import colors from 'config/colors.config'
import {
  formatWagonStatus,
  formatWagonDamaged,
  formatNumber,
} from 'utils/format.utils'
import modules from 'modules'
import { Status, Damaged, Material } from './entrainment.types'
import Label from 'components/label.component'
import { Contact } from 'modules/contacts/contacts.types'

const BasicContainer = styled.div`
  padding: 8px;
  margin-bottom: 8px;
  background: ${colors.white};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  border-left: 4px solid ${colors.primary};
  cursor: pointer;
  :hover {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  }
`

const Container = styled.div`
  padding: 12px 16px;
  background: ${colors.white};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  border-top: 4px solid rgba(0, 0, 0, 0.2);
  :hover {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  }
`

const Title = styled.h5`
  cursor: pointer;
  text-decoration: underline;
  font-size: 20px;
  color: ${colors.primary};
  margin: 0;
`

const Receiver = styled.span`
  cursor: pointer;
  text-decoration: underline;
`

const FreeWeight = styled.h3`
  margin: 8px 0;
  font-size: 28px;
`

const Damage = styled.span`
  color: ${colors.critical};
`

const Info = styled.span`
  margin: 8px 0;
  font-size: 14px;
`

interface Props {
  _id: string
  isBufferStop?: boolean
  isExit?: boolean
  isBasic?: boolean
  position?: number
  wagonNo: string
  material: string
  limit: number
  netWeight: number
  status: Status
  damaged: Damaged
  receiver: Contact | null
}

const Wagon: React.FunctionComponent<Props> = (props): JSX.Element => {
  const {
    _id,
    isBufferStop,
    isExit,
    isBasic,
    position,
    wagonNo,
    material,
    limit,
    netWeight,
    status,
    damaged,
    receiver,
  } = props

  const dispatch = useDispatch()
  const isAdminUser = useSelector(modules.auth.selectors.selectIsAdminUser)
  const contacts = useSelector(modules.contacts.selectors.selectContacts)

  const history = useHistory()

  const progress = (netWeight / limit) * 100
  let color = colors.darkgrey
  if (status === Status.Supplied) {
    if (progress < 75) color = colors.cdGreen
    if (progress >= 75) color = colors.warning
    if (netWeight > limit) color = colors.critical
  }

  return isBasic ? (
    <BasicContainer>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Flex mr={2}>
            <DragHandlerIcon label="Verschieben" size="large" />
          </Flex>
          <Title>{wagonNo}</Title>
        </Flex>

        <Flex>
          {position ? (
            <Label
              isFluid
              text={`Wagon ${position}${
                isBufferStop ? ` (Prellbock)` : isExit ? ` (Ausfahrt)` : ''
              }`}
            />
          ) : null}
        </Flex>
      </Flex>
    </BasicContainer>
  ) : (
    <Container>
      <Flex flexDirection="row" alignItems="center" mb={3}>
        <Box width={2 / 3}>
          <Title onClick={() => history.push('/entrainment/wagons/' + _id)}>
            {wagonNo}
          </Title>
        </Box>
        <Flex width={1 / 3} justifyContent="flex-end">
          <DropdownMenu
            trigger={({ triggerRef, ...providedProps }) => (
              <Title
                {...providedProps}
                ref={triggerRef as any}
              >
                {material}
              </Title>
            )}
            placement="bottom-end"
          >
            {Object.values(Material).map((currentMaterial) => {
              return (
                <DropdownItem
                  elemAfter={
                    currentMaterial === material ? (
                      <CheckIcon label="Ausgewählt" />
                    ) : null
                  }
                  onClick={() =>
                    dispatch(
                      modules.entrainment.actions.setMaterial(
                        _id,
                        currentMaterial,
                      ),
                    )
                  }
                >
                  {currentMaterial}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </Flex>
      </Flex>
      <Flex flexDirection="column" alignItems="flex-end" mb={2}>
        <Flex width={1} justifyContent="space-between">
          <Info>Ladegewicht:</Info>
          <Info>
            {formatNumber(netWeight)} kg / {formatNumber(limit)} kg
          </Info>
        </Flex>
        <Line percent={progress} strokeWidth={2} strokeColor={color} />
        <Flex width={1} justifyContent="space-between" mt={1}>
          {status === Status.Supplied ? (
            <Fragment>
              <Info>Offen:</Info>
              <FreeWeight>{formatNumber(limit - netWeight)} kg</FreeWeight>
            </Fragment>
          ) : (
            <Fragment>
              <Info>Status:</Info>
              <FreeWeight>{formatWagonStatus(status)}</FreeWeight>
            </Fragment>
          )}
        </Flex>
      </Flex>
      <Flex justifyContent="space-between">
        {position ? (
          `Wagon ${position}${
            isBufferStop ? ` (Prellbock)` : isExit ? ` (Ausfahrt)` : ''
          }`
        ) : (
          <div />
        )}
        {isAdminUser ? (
          <DropdownMenu
            trigger={({ triggerRef, ...providedProps }) => (
              <Receiver
                {...providedProps}
                ref={triggerRef}
              >
                {receiver?.name || 'Kein Empfänger'}
              </Receiver>
            )}
            placement="bottom-end"
          >
            {contacts.map((contact) => {
              return (
                <DropdownItem
                  elemAfter={
                    contact._id === receiver?._id ? (
                      <CheckIcon label="Ausgewählt" />
                    ) : null
                  }
                  onClick={() =>
                    dispatch(
                      modules.entrainment.actions.setReceiver(_id, contact._id),
                    )
                  }
                >
                  {contact.name}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        ) : null}
      </Flex>
      {damaged === Damaged.Documented ? (
        <Damage>{formatWagonDamaged(damaged)}</Damage>
      ) : null}
    </Container>
  )
}

export default Wagon
