import { AxiosResponse } from 'axios'

import { AUTHENTICATE_ENDPOINT } from 'config/server.config'
import { request } from 'utils/http.utils'
import {
  AuthenticationRequestData,
  AuthenticationResponseData,
} from './auth.types'

export const authenticate = (
  credentials: AuthenticationRequestData,
): Promise<AxiosResponse<AuthenticationResponseData>> => {
  return request({
    method: 'post',
    url: AUTHENTICATE_ENDPOINT,
    data: credentials,
  })
}
