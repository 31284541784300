import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Page from '@atlaskit/page'
import AddIcon from '@atlaskit/icon/glyph/add'

import modules from 'modules'
import PageHeader from 'components/page-header.component'
import ContainersTable from './containers-table.component'
import { CreateContainerParameters } from './containers.types'
import CreateContainerModal from './create-container.modal'
import { LoadingButton } from '@atlaskit/button'

const ContainersScreen: React.FunctionComponent = (): JSX.Element => {
  const [isCreateContainerModalOpen, setIsCreateContainerModalOpen] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const isAdminUser = useSelector(
    modules.auth.selectors.selectIsAdminUser,
  )

  const userPermissions = useSelector(
    modules.auth.selectors.selectUserPermissions,
  )

  const containers = useSelector(modules.containers.selectors.selectContainers)
  const isLoading = useSelector(modules.containers.selectors.selectIsLoading)
  const isLoadingEdit = useSelector(modules.containers.selectors.selectIsLoadingEdit)

  useEffect(() => {
    if (!userPermissions?.includes('containersViewPermission')) {
      if(isAdminUser) {
        //console.log("access ok.")
      }
      else {
        history.replace('')
      }
    }
  }, [isAdminUser, userPermissions])

  useEffect(() => {
    dispatch(modules.containers.actions.fetchContainers())
  }, [dispatch, isLoadingEdit])

  const createContainer = async (vehicle: CreateContainerParameters) => {
    return dispatch(modules.containers.actions.createContainer(vehicle))
  }

  return (
    <Page>
      <CreateContainerModal
        isOpen={isCreateContainerModalOpen}
        onClose={() => setIsCreateContainerModalOpen(false)}
        onSubmit={createContainer}
      />
      <PageHeader
        title="Behälterverwaltung"
        breadcrump="Behälterverwaltung"
        actions={
          <LoadingButton
            onClick={() => setIsCreateContainerModalOpen(true)}
            isLoading={isLoading}
            appearance="primary"
            iconBefore={<AddIcon label="Behälter anlegen" />}
          >
            Behälter anlegen
          </LoadingButton>
        }
      />
      <div className="containers-table"><ContainersTable containers={containers} onShowDetails={() => null} /></div>
    </Page>
  )
}

export default ContainersScreen
