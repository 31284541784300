import React from 'react'
import styled from 'styled-components'
import Button, { ButtonGroup } from '@atlaskit/button'
import Form, { Field, FormFooter } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import Modal, {
  ModalTransition,
  ModalHeader,
  ModalTitle,
  ModalBody,
} from '@atlaskit/modal-dialog'
import { ContainerType, CreateContainerParameters } from './containers.types'
import { DatePicker } from '@atlaskit/datetime-picker'
// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs'
import Select, { ValueType } from '@atlaskit/select'
import { cleanNumber, formatContainerType } from 'utils/format.utils'
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'

const FooterButton = styled(Button)`
  margin-bottom: 20px;
`

interface ContainerOptionType {
  label: string
  value: ContainerType
}

interface FormProps {
  containerNr: string
  type: ContainerOptionType
  dateBuilt: Date
  totalWeight: string
  containerLenght: string
  containerHeight: string
  volume: string
  factoryNr: string
  selfWeight: string
  containerWidth: string
  vDimension: string
  validation: Date
}

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: (fields: CreateContainerParameters) => void
}

const types: ContainerOptionType[] = [
  {
    label: formatContainerType(ContainerType.Container),
    value: ContainerType.Container,
  },
  { label: formatContainerType(ContainerType.Skip), value: ContainerType.Skip },
]

const CreateContainerModal: React.FunctionComponent<Props> = (
  props,
): JSX.Element => {
  const { isOpen, onClose, onSubmit } = props

  const date = dayjs().format('YYYY-MM-DD')

  const onSubmitClick = (fields: FormProps) => {
    const {
      type,
      totalWeight,
      containerLenght,
      containerHeight,
      volume,
      factoryNr,
      selfWeight,
      containerWidth,
      vDimension,
    } = fields

    onSubmit({
      ...fields,
      type: type.value,
      factoryNr: factoryNr === undefined ? 'n.a.' : factoryNr,
      totalWeight: cleanNumber(totalWeight),
      containerLenght: cleanNumber(containerLenght),
      containerHeight: cleanNumber(containerHeight),
      volume: cleanNumber(volume),
      selfWeight: cleanNumber(selfWeight),
      containerWidth: cleanNumber(containerWidth),
      vDimension: cleanNumber(vDimension),
    })

    onClose()
  }

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose} shouldCloseOnOverlayClick={false}>
          <Form<FormProps> onSubmit={(v) => onSubmitClick(v)}>
            {({ formProps }) => (
              <form {...formProps}>
                <ModalHeader>
                  <ModalTitle>Mulde/Container anlegen</ModalTitle>
                </ModalHeader>
                <ModalBody>
                  <Field<ValueType<ContainerOptionType, false>>
                    name="type"
                    label="Container-Typ"
                    defaultValue={types[0]}
                  >
                    {({ fieldProps }) => (
                      <Select<ContainerOptionType>
                        options={types}
                        placeholder="Bitte auswählen"
                        isSearchable={false}
                        menuPlacement="bottom"
                        {...fieldProps}
                      />
                    )}
                  </Field>

                  <Field
                    name="containerNr"
                    label="Behälter-Nummer"
                    isRequired={true}
                  >
                    {({ fieldProps }) => (
                      <TextField {...fieldProps} autoComplete="off" />
                    )}
                  </Field>

                  <Field name="dateBuilt" label="Baujahr" isRequired={false}>
                    {({ fieldProps }) => (
                      <MaskedInput
                        mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                        guide={true}
                        {...fieldProps}
                        render={(ref, props) => (
                          <TextField
                            ref={ref}
                            {...(props as any)}
                            autoComplete="off"
                          />
                        )}
                      />
                    )}
                  </Field>

                  <Field
                    name="totalWeight"
                    label="zul. Gesamtgewicht"
                    isRequired={false}
                  >
                    {({ fieldProps }) => (
                      <MaskedInput
                        mask={createNumberMask({
                          prefix: '',
                          suffix: ' kg',
                          thousandsSeparatorSymbol: '.',
                          allowDecimal: true,
                          decimalSymbol: ',',
                        })}
                        {...fieldProps}
                        render={(ref, props) => (
                          <TextField
                            ref={ref}
                            {...(props as any)}
                            autoComplete="off"
                            placeholder="In kg angeben"
                          />
                        )}
                      />
                    )}
                  </Field>

                  <Field
                    name="containerLenght"
                    label="Behälterlänge i. L. ca.:"
                    isRequired={false}
                  >
                    {({ fieldProps }) => (
                      <MaskedInput
                        mask={createNumberMask({
                          prefix: '',
                          suffix: ' mm',
                          thousandsSeparatorSymbol: '.',
                          allowDecimal: true,
                          decimalSymbol: ',',
                        })}
                        {...fieldProps}
                        render={(ref, props) => (
                          <TextField
                            ref={ref}
                            {...(props as any)}
                            autoComplete="off"
                            placeholder="In mm angeben"
                          />
                        )}
                      />
                    )}
                  </Field>

                  <Field
                    name="containerHeight"
                    label="Behälterhöhe i. L. ca.:"
                    isRequired={false}
                  >
                    {({ fieldProps }) => (
                      <MaskedInput
                        mask={createNumberMask({
                          prefix: '',
                          suffix: ' mm',
                          thousandsSeparatorSymbol: '.',
                          allowDecimal: true,
                          decimalSymbol: ',',
                        })}
                        {...fieldProps}
                        render={(ref, props) => (
                          <TextField
                            ref={ref}
                            {...(props as any)}
                            autoComplete="off"
                            placeholder="In mm angeben"
                          />
                        )}
                      />
                    )}
                  </Field>

                  <Field name="volume" label="Inhalt" isRequired={false}>
                    {({ fieldProps }) => (
                      <MaskedInput
                        mask={createNumberMask({
                          prefix: '',
                          suffix: ' m³',
                          thousandsSeparatorSymbol: '.',
                          allowDecimal: true,
                          decimalSymbol: ',',
                        })}
                        {...fieldProps}
                        render={(ref, props) => (
                          <TextField
                            ref={ref}
                            {...(props as any)}
                            autoComplete="off"
                            placeholder="In m³ angeben"
                          />
                        )}
                      />
                    )}
                  </Field>

                  <Field name="factoryNr" label="Fabrik-Nr.">
                    {({ fieldProps }) => (
                      <TextField {...fieldProps} autoComplete="off" />
                    )}
                  </Field>

                  <Field
                    name="selfWeight"
                    label="Eigengewicht"
                    isRequired={false}
                  >
                    {({ fieldProps }) => (
                      <MaskedInput
                        mask={createNumberMask({
                          prefix: '',
                          suffix: ' kg',
                          thousandsSeparatorSymbol: '.',
                          allowDecimal: true,
                          decimalSymbol: ',',
                        })}
                        {...fieldProps}
                        render={(ref, props) => (
                          <TextField
                            ref={ref}
                            {...(props as any)}
                            autoComplete="off"
                            placeholder="In kg angeben"
                          />
                        )}
                      />
                    )}
                  </Field>

                  <Field
                    name="containerWidth"
                    label="Behälterbreite i. L. ca.:"
                    isRequired={false}
                  >
                    {({ fieldProps }) => (
                      <MaskedInput
                        mask={createNumberMask({
                          prefix: '',
                          suffix: ' mm',
                          thousandsSeparatorSymbol: '.',
                          allowDecimal: true,
                          decimalSymbol: ',',
                        })}
                        {...fieldProps}
                        render={(ref, props) => (
                          <TextField
                            ref={ref}
                            {...(props as any)}
                            autoComplete="off"
                            placeholder="In mm angeben"
                          />
                        )}
                      />
                    )}
                  </Field>

                  <Field name="vDimension" label="Verr.-Maß" isRequired={false}>
                    {({ fieldProps }) => (
                      <MaskedInput
                        mask={createNumberMask({
                          prefix: '',
                          suffix: ' mm',
                          thousandsSeparatorSymbol: '.',
                          allowDecimal: true,
                          decimalSymbol: ',',
                        })}
                        {...fieldProps}
                        render={(ref, props) => (
                          <TextField
                            ref={ref}
                            {...(props as any)}
                            autoComplete="off"
                            placeholder="In mm angeben"
                          />
                        )}
                      />
                    )}
                  </Field>

                  <Field
                    name="validation"
                    label="Gültigkeit"
                    isRequired={false}
                    defaultValue={date}
                  >
                    {({ fieldProps }) => (
                      <>
                        <DatePicker
                          {...fieldProps}
                          locale="de-DE"
                          weekStartDay={1}
                          dateFormat="DD.MM.YYYY"
                        />
                        {/* <HelperMessage>Help or instruction text goes here</HelperMessage> */}
                      </>
                    )}
                  </Field>

                  <FormFooter>
                    <ButtonGroup>
                      <FooterButton onClick={onClose}>Abbrechen</FooterButton>
                      <FooterButton type="submit" appearance="primary">
                        anlegen
                      </FooterButton>
                    </ButtonGroup>
                  </FormFooter>
                </ModalBody>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default CreateContainerModal
