// @ts-nocheck
import React, { Fragment, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up'
import TextField from '@atlaskit/textfield'
import Button, { ButtonGroup } from '@atlaskit/button'
import { Flex } from 'reflexbox'
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table'

import { formatDateTime } from 'utils/format.utils'
import { useConfirmation } from 'components/confirmation-modal.service'
import { DailyReport } from './daily-reports.types'
import dayjs from 'dayjs'

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: {
  preGlobalFilteredRows: any
  globalFilter: any
  setGlobalFilter: any
}) => {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <TextField
      isCompact
      width={300}
      placeholder={`${count} Einträge durchsuchen`}
      elemBeforeInput={<EditorSearchIcon label="Suchen" />}
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
    />
  )
}

interface Props {
  dailyReports: DailyReport[]
  onDownloadClick: (dailyReportId: string) => void
  onTransmitClick: (dailyReportId: string) => void
}

interface TableProps {
  options: {
    columns: any
    data: any
  }
}

const Table = ({ columns, data }: TableProps) => {
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state,

    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        sortBy: [{ id: 'createdAt', desc: true }],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const { pageIndex, globalFilter } = state

  return (
    <Fragment>
      <Flex justifyContent="flex-end">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Flex>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <Flex>
                    {column.render('Header')}

                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ChevronDownIcon label="Absteigend sortieren" />
                      ) : (
                        <ChevronUpIcon label="Aufsteigend sortieren" />
                      )
                    ) : (
                      /**
                       * Placeholder for sort icons. Was this empty or null,
                       * adding an icon would always shift the whole table
                       * due to readjustments in header width and height.
                       *
                       * By using a placeholder with the same size as the icons,
                       * we can completely get rid of this effect.
                       */
                      <div style={{ height: 24, width: 24 }}></div>
                    )}
                  </Flex>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((page: any) => {
            prepareRow(page)
            return (
              <tr {...page.getRowProps()}>
                {page.cells.map((cell: any) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <br />
      <Flex justifyContent="space-between">
        <ButtonGroup appearance="default">
          <Button onClick={() => gotoPage(0)} isDisabled={!canPreviousPage}>
            {'<<'} Erste
          </Button>
          <Button onClick={() => previousPage()} isDisabled={!canPreviousPage}>
            {'<'} Vorherige
          </Button>
          <Button onClick={() => nextPage()} isDisabled={!canNextPage}>
            Nächste {'>'}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            isDisabled={!canNextPage}
          >
            Letzte {'>>'}
          </Button>
        </ButtonGroup>
        <span>
          Seite{' '}
          <strong>
            {pageIndex + 1} von {pageOptions.length}
          </strong>{' '}
        </span>
      </Flex>
      <br />
      <br />
    </Fragment>
  )
}

const DailyReportTable: React.FunctionComponent<Props> = (
  props,
): JSX.Element => {
  const { dailyReports, onDownloadClick, onTransmitClick } = props

  //const dispatch = useDispatch()
  const confirm = useConfirmation()

  const columns = useMemo(
    () => [
      {
        Header: 'Meldetag',
        accessor: 'dayOfReport',
        Cell: ({ value }) => value.split('###')[1],
      },
      {
        Header: 'Empfänger',
        accessor: 'receiver',
      },
      {
        Header: '',
        accessor: 'dailyReportId-1',
        Cell: ({
          row: {
            original: { dailyReportId },
          },
        }) => (
          <Flex justifyContent="flex-end">
            <Button
              appearance="link"
              onClick={() => onDownloadClick(dailyReportId)}
            >
              Herunterladen
            </Button>
          </Flex>
        ),
      },
      {
        Header: '',
        accessor: 'dailyReportId-2',
        Cell: ({
          row: {
            original: { dailyReportId, receiver },
          },
        }) => (
          <Flex justifyContent="flex-end">
            <Button
              appearance="link"
              onClick={() =>
                confirm({
                  title: 'Tagesbericht übermitteln?',
                  description:
                    'Der Tagesbericht wird an die Empfänger der ' +
                    receiver +
                    ' per Email übermittelt. ',
                })
                  .then(() => onTransmitClick(dailyReportId))
                  .catch(() => null)
              }
            >
              Übermitteln
            </Button>
          </Flex>
        ),
      },
    ],
    [],
  )

  const rows = dailyReports.map((dailyReport) => {
    const { _id, dayOfReport, createdAt, wagons } = dailyReport

    const receiver = wagons.length ? wagons[0].receiver?.name : ''

    return {
      dailyReportId: _id,
      receiver,
      /**
       * Search and sort depend on the accessor key. This means, we can either base
       * this on the ISO string or the formatted string, by passing either of them.
       * Here we want to search by the formatted string, but sort by the ISO string,
       * so we pass both as delimited string and display the formatted part only,
       * while still being able to sort correctly.
       */
      dayOfReport: `${dayOfReport}###${dayjs(dayOfReport).format(
        'DD.MM.YYYY',
      )}`,
      createdAt: `${createdAt}###${formatDateTime(createdAt)}`,
    }
  })

  return <Table columns={columns} data={rows} />
}

export default DailyReportTable
