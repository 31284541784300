import { AxiosResponse } from 'axios'

import { INSTRUMENT_ENDPOINT } from 'config/server.config'
import { request } from 'utils/http.utils'
import { CreateInstrumentParameters, Instrument } from './instruments.types'

export const createInstrument = (
  instrument: CreateInstrumentParameters,
): Promise<AxiosResponse<Instrument>> => {
  return request({
    method: 'post',
    url: INSTRUMENT_ENDPOINT,
    data: instrument,
  })
}

export const editInstrument = (
  instrument: Instrument,
): Promise<AxiosResponse<Instrument>> => {
  return request({
    method: 'put',
    url: `${INSTRUMENT_ENDPOINT}/${instrument._id}`,
    data: instrument,
  })
}

export const fetchInstruments = (): Promise<AxiosResponse<Instrument[]>> => {
  return request({
    method: 'get',
    url: INSTRUMENT_ENDPOINT,
  })
}

export const deleteInstrument = (instrumentId: string): Promise<AxiosResponse<boolean>> => {
  return request({
    method: 'delete',
    url: `${INSTRUMENT_ENDPOINT}/${instrumentId}`,
  })
}