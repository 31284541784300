import { AxiosResponse } from 'axios'

import { CONTACTS_ENDPOINT } from 'config/server.config'
import { request } from 'utils/http.utils'
import { CreateContactParameters, Contact } from './contacts.types'

export const createContact = (
  contact: CreateContactParameters,
): Promise<AxiosResponse<Contact>> => {
  return request({
    method: 'post',
    url: CONTACTS_ENDPOINT,
    data: contact,
  })
}

export const fetchContacts = (): Promise<AxiosResponse<Contact[]>> => {
  return request({
    method: 'get',
    url: CONTACTS_ENDPOINT,
  })
}

export const deleteContact = (
  contactId: string,
): Promise<AxiosResponse<boolean>> => {
  return request({
    method: 'delete',
    url: `${CONTACTS_ENDPOINT}/${contactId}`,
  })
}
