/* eslint-disable */

import { combineReducers } from 'redux'

import modules from 'modules'
import jwt from 'lib/jwt.class'

const appReducer = combineReducers({
  core: modules.core.reducer,
  auth: modules.auth.reducer,
  users: modules.users.reducer,
  entrainment: modules.entrainment.reducer,
  contacts: modules.contacts.reducer,
  dailyReports: modules.dailyReports.reducer,
  checklist: modules.checklist.reducer,
  vehicles: modules.vehicles.reducer,
  containers: modules.containers.reducer,
  instruments: modules.instruments.reducer,
  ftp: modules.ftp.reducer,
})

export default (state: any, action: any) => {
  if (action.type === 'RESET_STATE') {
    state = undefined
    jwt.delete()
  }

  return appReducer(state, action)
}
