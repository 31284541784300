import { Reducer } from 'redux'

import { AppState } from 'typescript/common.types'

import {
  State,
  ActionTypes,
  Action,
  SetVersionAction,
  ShowFlagAction,
  HideFlagAction,
  FlagConfig,
} from './core.types'

/**
 * Initial State
 */
export const initialState: State = {
  version: '0.0.0',
  flag: null,
}

/**
 * Action Types
 */
export const types = ActionTypes

/**
 * Actions
 */
const setVersion = (version: string): SetVersionAction => ({
  type: ActionTypes.SET_VERSION,
  payload: { version },
})

const showFlagAction = (config: FlagConfig): ShowFlagAction => ({
  type: ActionTypes.SHOW_FLAG,
  payload: { config },
})

const hideFlagAction = (): HideFlagAction => ({
  type: ActionTypes.HIDE_FLAG,
})

/**
 * Selectors
 */
const selectVersion = (state: AppState): string => state.core.version
const selectFlagConfig = (state: AppState): FlagConfig | null => state.core.flag

/**
 * Reducer
 */
export const reducer: Reducer<State, Action> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case ActionTypes.SET_VERSION:
      return {
        ...state,
        version: action.payload.version,
      }

    case ActionTypes.SHOW_FLAG:
      return {
        ...state,
        flag: action.payload.config,
      }

    case ActionTypes.HIDE_FLAG:
      return {
        ...state,
        flag: null,
      }

    default:
      return state
  }
}

export default {
  types,
  actions: {
    setVersion,
    showFlagAction,
    hideFlagAction,
  },
  selectors: {
    selectVersion,
    selectFlagConfig,
  },
  reducer,
}
