import dayjs from 'dayjs'

import {
  WAGON_STATUS,
  DATE_TIME_FORMAT,
  WAGON_DAMAGED,
  USER_ROLE,
  CONTAINER_TYPE,
  CHECKLIST_TYPE,
  USER_PERMISSIONS,
  CHECKLIST_STATUS,
  DATE_FORMAT,
} from 'config/const.config'

export const formatWagonStatus = (status: string): string =>
  WAGON_STATUS[status] || ''
export const formatWagonDamaged = (damaged: string): string =>
  WAGON_DAMAGED[damaged] || ''

export const formatUserRole = (userRole: string): string =>
  USER_ROLE[userRole] || ''
export const formatUserPermission = (userPermission: string): string =>
  USER_PERMISSIONS[userPermission] || ''

export const formatChecklistStatus = (status: string): string =>
  CHECKLIST_STATUS[status] || ''
export const formatChecklistType = (type: string): string =>
  CHECKLIST_TYPE[type] || ''
export const formatContainerType = (containerType: string): string =>
  CONTAINER_TYPE[containerType] || ''

export const formatNumber = (number: number): string => {
  const tokens = number.toString().split('.')
  tokens[0] = tokens[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  return tokens.join(',')
}

export const cleanNumber = (number: string): number => {
  if (number !== undefined) {
    return parseInt(number.replace(/[^0-9]+/g, '')) || 0
  } else {
    return 0
  }
}

export const formatDateTime = (date: Date = new Date()): string =>
  dayjs(
    new Date(date).toLocaleString('en-US', { timeZone: 'Europe/Berlin' }),
  ).format(DATE_TIME_FORMAT)

export const getYear = (date: Date = new Date()): string =>
  dayjs(
    new Date(date).toLocaleString('en-US', { timeZone: 'Europe/Berlin' }),
  ).format('YYYY')

export const formatDate = (date: Date = new Date()): string =>
  dayjs(
    new Date(date).toLocaleString('en-US', { timeZone: 'Europe/Berlin' }),
  ).format(DATE_FORMAT)
