import * as yup from 'yup'

export const containerSchema = yup.object({
  _id: yup.string().required(),
  containerNr: yup.string().required(),
  type: yup.string().required(),

  /* containerClass: yup.string().nullable(), */
  dateBuilt: yup.date().nullable(),
  totalWeight: yup.number().nullable(),
  containerLenght: yup.number().nullable(),
  containerHeight: yup.number().nullable(),
  volume: yup.number().nullable(),
  factoryNr: yup.string().nullable(),
  selfWeight: yup.number().nullable(),
  containerWidth: yup.number().nullable(),
  vDimension: yup.number().nullable(),

  validation: yup.date().required(),
  createdAt: yup.date().required(),
  updatedAt: yup.date().required(),
})
