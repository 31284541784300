import { applyMiddleware, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { persistReducer, persistStore } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import LocalStorage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer from './reducer.redux'
import notificationsMiddleware from './notifications-middleware.redux'

/**
 * Configure redux-persist to automatically merge new state.
 * Uses whitelist mode, so every state to be persisted, must be whitelisted
 */
const persistedReducer = persistReducer(
  {
    key: 'root',
    stateReconciler: autoMergeLevel2,
    storage: LocalStorage,
    whitelist: ['auth'],
  },
  rootReducer,
)

const composeEnhancers = composeWithDevTools({
  name: 'Joos Web',
})

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware, notificationsMiddleware)),
)
export const persistor = persistStore(store)

interface ExtendedModule extends NodeModule {
  hot?: {
    accept: Function
  }
}
const eModule: ExtendedModule = module

if (eModule.hot) {
  eModule.hot.accept(() => {
    const nextRootReducer = require('./reducer.redux')
    store.replaceReducer(nextRootReducer)
  })
}
