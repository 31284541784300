import { AxiosResponse } from 'axios'

import { VEHICLES_ENDPOINT } from 'config/server.config'
import { request } from 'utils/http.utils'
import { CreateVehicleParameters, Vehicle } from './vehicles.types'

export const createVehicle = (
  vehicle: CreateVehicleParameters,
): Promise<AxiosResponse<Vehicle>> => {
  return request({
    method: 'post',
    url: VEHICLES_ENDPOINT,
    data: vehicle,
  })
}

export const editVehicle = (
  vehicle: Vehicle,
): Promise<AxiosResponse<Vehicle>> => {
  return request({
    method: 'put',
    url: `${VEHICLES_ENDPOINT}/${vehicle._id}`,
    data: vehicle,
  })
}

export const fetchVehicles = (): Promise<AxiosResponse<Vehicle[]>> => {
  return request({
    method: 'get',
    url: VEHICLES_ENDPOINT,
  })
}

export const deleteVehicle = (vehicleId: string): Promise<AxiosResponse<boolean>> => {
  return request({
    method: 'delete',
    url: `${VEHICLES_ENDPOINT}/${vehicleId}`,
  })
}