import { AxiosResponse } from 'axios'

import { CHECKLISTS_ENDPOINT } from 'config/server.config'
import { request } from 'utils/http.utils'
import {
  CreateChecklistRequestData,
  Checklist,
  ChecklistUpdates,
  ChecklistTypes,
} from './checklist.types'

export const createChecklist = (
  checklist: CreateChecklistRequestData,
): Promise<AxiosResponse<Checklist>> => {
  return request({
    method: 'post',
    url: CHECKLISTS_ENDPOINT,
    data: checklist,
  })
}

export const fetchChecklists = (type: ChecklistTypes[]): Promise<AxiosResponse<Checklist[]>> => {
  return request({
    method: 'get',
    url: CHECKLISTS_ENDPOINT,
    params: {
      checklistType: type
    },
  })
}

export const fetchChecklistById = (checklistId: string): Promise<AxiosResponse<Checklist[]>> => {
  return request({
    method: 'get',
    url: `${CHECKLISTS_ENDPOINT}/checklist/${checklistId}`,
  })
}

export const fetchChecklistsByUser = (userId: string, type: ChecklistTypes[]): Promise<AxiosResponse<Checklist[]>> => {
  return request({
    method: 'get',
    url: `${CHECKLISTS_ENDPOINT}/user/${userId}`,
    params: {
      checklistType: type
    },
  })
}

export const updateChecklist = (
  checklistId: string,
  checklistUpdates: ChecklistUpdates,
): Promise<AxiosResponse<Checklist>> => {
  return request({
    method: 'patch',
    url: `${CHECKLISTS_ENDPOINT}/${checklistId}`,
    data: checklistUpdates,
  })
}

export const updateCheckFromChecklist = (
  checklistId: string,
  checkId: string,
  checkFaults: boolean | null,
): Promise<AxiosResponse<Checklist>> => {
  return request({
    method: 'patch',
    url: `${CHECKLISTS_ENDPOINT}/${checklistId}/check`,
    data: {
      checkId,
      checkFaults,
    },
  })
}

export const updateBlockFromChecklist = (
  checklistId: string,
  blockId: string,
  blockFaults: boolean | null,
): Promise<AxiosResponse<Checklist>> => {
  return request({
    method: 'patch',
    url: `${CHECKLISTS_ENDPOINT}/${checklistId}/block`,
    data: {
      blockId,
      blockFaults,
    },
  })
}

export const updateBlockCommentFromChecklist = (
  checklistId: string,
  blockId: string,
  comment: string | null,
): Promise<AxiosResponse<Checklist>> => {
  return request({
    method: 'patch',
    url: `${CHECKLISTS_ENDPOINT}/${checklistId}/block/comment`,
    data: {
      blockId,
      comment,
    },
  })
}

export const deleteChecklists = (
  checklists: object,
): Promise<AxiosResponse<Checklist>> => {
  return request({
    method: 'delete',
    url: `${CHECKLISTS_ENDPOINT}`,
    data: {
      checklists
    },
  })
}
