// @ts-nocheck
import React from 'react'
import styled from 'styled-components'

import colors from 'config/colors.config'

interface Props {
  text: string
  color?: string
  background?: string
  isBasic?: boolean
  isFluid?: boolean
}

const Container = styled.div`
  padding: 4px 8px;
  border-radius: 3px;
  color: ${(props) => props.color || null};
  background-color: ${(props) =>
    props.isBasic ? null : props.background || colors.subtle};
  font-size: 13px;
  text-align: center;
  width: ${(props) => (props.isFluid ? '100%' : 'auto')};
`

const Label: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { text, color, background, isBasic, isFluid } = props

  return (
    <Container
      color={color}
      background={background}
      isBasic={isBasic}
      isFluid={isFluid}
    >
      <span>{text}</span>
    </Container>
  )
}

export default Label
