import React, {
  Fragment,
  createContext,
  useContext,
  useState,
  useRef,
} from 'react'

import ConfirmationModal, {
  ConfirmationOptions,
} from './confirmation-modal.component'

const ConfirmationServiceContext = createContext<
  (options: ConfirmationOptions) => Promise<void>
>(Promise.reject)

export const useConfirmation = () => useContext(ConfirmationServiceContext)

export const ConfirmationServiceProvider = ({
  children,
}: {
  children: any
}) => {
  const [
    confirmationState,
    setConfirmationState,
  ] = useState<ConfirmationOptions | null>(null)

  const awaitingPromiseRef = useRef<{
    resolve: () => void
    reject: () => void
  }>()

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options)
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject }
    })
  }

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject()
    }

    setConfirmationState(null)
  }

  const handleConfirm = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }

    setConfirmationState(null)
  }

  return (
    <Fragment>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />

      <ConfirmationModal
        isModalOpen={Boolean(confirmationState)}
        onConfirm={handleConfirm}
        onClose={handleClose}
        {...confirmationState}
      />
    </Fragment>
  )
}
