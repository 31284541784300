import * as yup from 'yup'

export const vehicleSchema = yup.object({
  _id: yup.string().required(),
  vehiclePlate: yup.string(),
  huauDate: yup.date().required(),
  spDate: yup.date().required(),
  uvvDate: yup.date().required(),
  createdAt: yup.date().required(),
  updatedAt: yup.date().required(),
})
