import { Reducer, Dispatch } from 'redux'
import { createSelector } from 'reselect'

import { AppState, GenericThunk } from 'typescript/common.types'
import { wagonSchema } from './entrainment.schemas'
import * as api from './entrainment.service'
import * as types from './entrainment.types'
import {
  State,
  Wagon,
  ActionTypes,
  Action,
  Filter,
  Status,
  Damaged,
  CreateWagonRequestData,
  WagonUpdates,
  HopperUpdates,
} from './entrainment.types'

/**
 * Initial State
 */
const initialState: State = {
  wagons: {
    byId: {},
    allIds: [],
  },
  pagination: {
    total: null,
    page: null,
    maxPages: null,
    limit: 25 // react-table braucht initial diese Angabe
  },
  isLoading: false,
}

/**
 * Actions & Thunks
 */
const createWagonPending = (): Action => ({
  type: ActionTypes.CREATE_WAGON_PENDING,
})
const createWagonError = (error: Error): Action => ({
  type: ActionTypes.CREATE_WAGON_ERROR,
  error,
})
const createWagonSuccess = (): Action => ({
  type: ActionTypes.CREATE_WAGON_SUCCESS,
})
const saveWagons = (wagons: Wagon[], shouldReplace: boolean): Action => ({
  type: ActionTypes.SAVE_WAGONS,
  meta: { shouldReplace },
  payload: { wagons },
})
const savePagination = (pagination: any): Action => ({
  type: ActionTypes.SAVE_PAGINATION,
  payload: { pagination },
})

const createWagon = (
  newWagon: CreateWagonRequestData,
): GenericThunk<Promise<Wagon | null>> => {
  return async (dispatch: Dispatch): Promise<Wagon | null> => {
    dispatch(createWagonPending())

    try {
      const { data: wagon } = await api.createWagon(newWagon)

      wagonSchema.validateSync(wagon)
      dispatch(saveWagons([wagon], false))
      dispatch(createWagonSuccess())

      return wagon
    } catch (error) {
      if (error instanceof Error) {
        dispatch(createWagonError(error))
      }
    }
    return null
  }
}

const fetchWagonsPending = (): Action => ({
  type: ActionTypes.FETCH_WAGONS_PENDING,
})
const fetchWagonsError = (error: Error): Action => ({
  type: ActionTypes.FETCH_WAGONS_ERROR,
  error,
})
const fetchWagonsSuccess = (): Action => ({
  type: ActionTypes.FETCH_WAGONS_SUCCESS,
})

const fetchWagons = (page: number, limit: number): GenericThunk<Promise<any>> => { // Wagon[]
  return async (dispatch: Dispatch): Promise<any> => { // Wagon[]
    dispatch(fetchWagonsPending())

    try {
      const { data } = await api.fetchWagons(page, limit)
      const { wagons, pagination } = data;

      wagons.forEach((wagon: Wagon[]) => wagonSchema.validateSync(wagon))

      dispatch(saveWagons(wagons, true))
      dispatch(savePagination(pagination))
      dispatch(fetchWagonsSuccess())

      return wagons
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchWagonsError(error))
      }
    }
    return []
  }
}

const updateWagonPending = (): Action => ({
  type: ActionTypes.UPDATE_WAGON_PENDING,
})
const updateWagonError = (error: Error): Action => ({
  type: ActionTypes.UPDATE_WAGON_ERROR,
  error,
})
const updateWagonSuccess = (): Action => ({
  type: ActionTypes.UPDATE_WAGON_SUCCESS,
})

const updateWagon = (
  wagonId: string,
  wagonUpdates: WagonUpdates,
): GenericThunk<Promise<Wagon | null>> => {
  return async (dispatch: Dispatch): Promise<Wagon | null> => {
    dispatch(updateWagonPending())

    try {
      const { data: wagon } = await api.updateWagon(wagonId, wagonUpdates)

      wagonSchema.validateSync(wagon)
      dispatch(saveWagons([wagon], false))
      dispatch(updateWagonSuccess())

      return wagon
    } catch (error) {
      if (error instanceof Error) {
        dispatch(updateWagonError(error))
      }
    }
    return null
  }
}

const updateWagons = (
  updates: { wagonId: string; updates: WagonUpdates }[],
): GenericThunk<Promise<Wagon[] | null>> => {
  return async (dispatch: Dispatch): Promise<Wagon[] | null> => {
    dispatch(updateWagonPending())

    try {
      const { data: wagons } = await api.updateWagons(updates)

      wagons.map((wagon) => wagonSchema.validateSync(wagon))

      dispatch(saveWagons(wagons, false))
      dispatch(updateWagonSuccess())

      return wagons
    } catch (error) {
      if (error instanceof Error) {
        dispatch(updateWagonError(error))
      }
    }
    return null
  }
}

const searchWagonsPending = (): Action => ({
  type: ActionTypes.SEARCH_WAGONS_PENDING,
})
const searchWagonsError = (error: Error): Action => ({
  type: ActionTypes.SEARCH_WAGONS_ERROR,
  error,
})
const searchWagonsSuccess = (): Action => ({
  type: ActionTypes.SEARCH_WAGONS_SUCCESS,
})

const searchWagons = (searchTerm: string): GenericThunk<Promise<any>> => { // Wagon[]
  return async (dispatch: Dispatch): Promise<any> => { // Wagon[]
    dispatch(searchWagonsPending())

    try {
      const { data } = await api.searchWagons(searchTerm)

      data.forEach((wagon: Wagon[]) => wagonSchema.validateSync(wagon))

      dispatch(saveWagons(data, true))
      dispatch(searchWagonsSuccess())

      return data
    } catch (error) {
      if (error instanceof Error) {
        dispatch(searchWagonsError(error))

        console.log(error)
      }
    }
    return []
  }
}

const setWagonFinished = (wagonId: string): ReturnType<typeof updateWagon> => {
  return updateWagon(wagonId, { status: Status.Finished })
}

const setWagonCollected = (wagonId: string): ReturnType<typeof updateWagon> => {
  return updateWagon(wagonId, {
    status: Status.Collected,
    collectedAt: new Date(),
  })
}

const setWagonDeleted = (wagonId: string): ReturnType<typeof updateWagon> => {
  return updateWagon(wagonId, { status: Status.Deleted })
}

const setMaterial = (
  wagonId: string,
  material: types.Material,
): ReturnType<typeof updateWagon> => {
  return updateWagon(wagonId, { material })
}

const setWagonDamagedNone = (
  wagonId: string,
): ReturnType<typeof updateWagon> => {
  return updateWagon(wagonId, { damaged: Damaged.None })
}

const setWagonDamagedDocumented = (
  wagonId: string,
): ReturnType<typeof updateWagon> => {
  return updateWagon(wagonId, {
    damaged: Damaged.Documented,
  })
}

const setMonthNo = (
  wagonId: string,
  monthNo: string,
): ReturnType<typeof updateWagon> => {
  return updateWagon(wagonId, { monthNo })
}

const setOfficialWeight = (
  wagonId: string,
  officialWeight: number,
): ReturnType<typeof updateWagon> => {
  return updateWagon(wagonId, { officialWeight })
}

const setReceiver = (
  wagonId: string,
  contactId: string,
): ReturnType<typeof updateWagon> => {
  return updateWagon(wagonId, { receiver: contactId })
}

const updateHopperPending = (): Action => ({
  type: ActionTypes.UPDATE_HOPPER_PENDING,
})
const updateHopperError = (error: Error): Action => ({
  type: ActionTypes.UPDATE_HOPPER_ERROR,
  error,
})
const updateHopperSuccess = (): Action => ({
  type: ActionTypes.UPDATE_HOPPER_SUCCESS,
})

const updateHopper = (
  wagonId: string,
  hopperId: string,
  hopperUpdates: HopperUpdates,
): GenericThunk<Promise<Wagon | null>> => {
  return async (dispatch: Dispatch): Promise<Wagon | null> => {
    dispatch(updateWagonPending())

    try {
      const { data: wagon } = await api.updateHopper(
        wagonId,
        hopperId,
        hopperUpdates,
      )

      wagonSchema.validateSync(wagon)
      dispatch(saveWagons([wagon], false))
      dispatch(updateWagonSuccess())

      return wagon
    } catch (error) {
      if (error instanceof Error) {
        dispatch(updateWagonError(error))
      }
    }
    return null
  }
}

/**
 * Selectors
 */
const selectIsLoading = (state: AppState): boolean =>
  state.entrainment.isLoading

const selectWagons = (state: AppState): Wagon[] =>
  Object.values(state.entrainment.wagons.byId).map((wagon) => {
    const netWeight = wagon.hoppers.reduce((acc, hopper) => {
      const { netWeight, grossWeight, tara } = hopper
      return acc + netWeight
      // return grossWeight >= tara ? acc + grossWeight - tara : acc
    }, 0)

    return { ...wagon, netWeight }
  })

const selectPagination = (state: AppState): any =>
  state.entrainment.pagination

const selectWagonById = (id: string) =>
  createSelector(selectWagons, (wagons) => {
    const [wagon] = wagons.filter((wagon) => wagon._id === id)

    return wagon
  })

const selectFilteredWagons = (filter?: Filter) =>
  createSelector(selectWagons, (wagons) => {
    let filteredWagons: Wagon[] = []
    if (!filter || filter === Filter.All) filteredWagons = wagons
    if (filter === Filter.Collected) {
      filteredWagons = wagons.filter(
        (wagon) => wagon.status === Status.Collected,
      )
    }
    if (filter === Filter.Current) {
      filteredWagons = wagons.filter(
        (wagon) =>
          wagon.status === Status.Supplied || wagon.status === Status.Finished,
      )
      filteredWagons.sort((a, b) => {
        if (a.position !== undefined && b.position !== undefined) {
          if (a.position > b.position) return 1
          if (a.position < b.position) return -1
        } else {
          if (a.createdAt > b.createdAt) return 1
          if (a.createdAt < b.createdAt) return -1
        }
        return 0
      })
    }

    return filteredWagons
  })

/**
 * Reducer
 */
const reducer: Reducer<State, Action> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case ActionTypes.CREATE_WAGON_PENDING:
    case ActionTypes.FETCH_WAGONS_PENDING:
    case ActionTypes.UPDATE_WAGON_PENDING:
      return { ...state, isLoading: true }

    case ActionTypes.CREATE_WAGON_ERROR:
    case ActionTypes.FETCH_WAGONS_ERROR:
    case ActionTypes.UPDATE_WAGON_ERROR:
      return { ...state, isLoading: false }

    case ActionTypes.SAVE_WAGONS:
      const { wagons } = action.payload

      const base = action.meta.shouldReplace ? {} : { ...state.wagons.byId }

      const newById = wagons.reduce((acc, wagon) => {
        return { ...acc, [wagon._id]: wagon }
      }, base)

      return {
        ...state,
        isLoading: false,
        wagons: {
          byId: newById,
          allIds: Object.keys(newById),
        },
      }

    case ActionTypes.SAVE_PAGINATION:
      const { pagination } = action.payload

      return {
        ...state,
        isLoading: false,
        pagination: pagination
      }

    default:
      return state
  }
}

export default {
  types,
  actions: {
    createWagon,
    createWagonPending,
    createWagonError,
    createWagonSuccess,
    saveWagons,
    fetchWagons,
    fetchWagonsPending,
    fetchWagonsError,
    fetchWagonsSuccess,
    updateWagon,
    updateWagons,
    updateWagonPending,
    updateWagonError,
    updateWagonSuccess,
    updateHopper,
    updateHopperPending,
    updateHopperError,
    updateHopperSuccess,
    searchWagons,
    searchWagonsPending,
    searchWagonsError,
    searchWagonsSuccess,
    setWagonFinished,
    setWagonCollected,
    setWagonDeleted,
    setMaterial,
    setMonthNo,
    setOfficialWeight,
    setWagonDamagedNone,
    setWagonDamagedDocumented,
    setReceiver,
  },
  selectors: {
    selectIsLoading,
    selectWagonById,
    selectWagons,
    selectPagination,
    selectFilteredWagons,
  },
  reducer,
}
