import * as yup from 'yup'

import { Material, Status, Position, Damaged } from './entrainment.types'

const userSchema = yup.object({
  _id: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
})

export const wagonSchema = yup.object({
  _id: yup.string().required(),
  wagonNo: yup.string().required(),
  monthNo: yup.string().nullable(),
  material: yup.string().oneOf(Object.values(Material)).required(),
  damaged: yup.string().oneOf(Object.values(Damaged)).required(),
  limit: yup.number().required(),
  position: yup.number(),
  status: yup.string().oneOf(Object.values(Status)).required(),
  collectedAt: yup.date().nullable(),
  hoppers: yup
    .array(
      yup.object({
        _id: yup.string().required(),
        grossWeight: yup.number().required(),
        tara: yup.number().required(),
        position: yup.string().oneOf(Object.values(Position)).required(),
        updatedAt: yup.date().required(),
        updatedBy: userSchema.nullable(),
      }),
    )
    .required(),
  createdAt: yup.date().required(),
  updatedAt: yup.date().required(),
  createdBy: userSchema.nullable(),
})
