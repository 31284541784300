import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Page from '@atlaskit/page'
import AddIcon from '@atlaskit/icon/glyph/add'

import modules from 'modules'
import PageHeader from 'components/page-header.component'
import UserTable from './user-table.component'
import { CreateUserParameters } from './users.types'
import CreateUserModal from './create-user.modal'
import { LoadingButton } from '@atlaskit/button'

const UsersScreen: React.FunctionComponent = (): JSX.Element => {
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const isAdminUser = useSelector(
    modules.auth.selectors.selectIsAdminUser,
  )

  const userPermissions = useSelector(
    modules.auth.selectors.selectUserPermissions,
  )

  const users = useSelector(modules.users.selectors.selectUsers)
  const isLoading = useSelector(modules.users.selectors.selectIsLoading)
  const isLoadingEdit = useSelector(modules.users.selectors.selectIsLoadingEdit)

  useEffect(() => {
    if (!userPermissions?.includes('usersViewPermission')) {
      if(isAdminUser) {
        //console.log("access ok.")
      }
      else {
        history.replace('')
      }
    }
  }, [isAdminUser, userPermissions])

  useEffect(() => {
    dispatch(modules.users.actions.fetchUsers())
  }, [dispatch, isLoadingEdit])

  const createUser = async (user: CreateUserParameters) => {
    return dispatch(modules.users.actions.createUser(user))
  }

  return (
    <Page>
      <CreateUserModal
        isOpen={isCreateUserModalOpen}
        onClose={() => setIsCreateUserModalOpen(false)}
        onSubmit={createUser}
      />
      <PageHeader
        title="Benutzerverwaltung"
        breadcrump="Benutzerverwaltung"
        actions={
          <LoadingButton
            onClick={() => setIsCreateUserModalOpen(true)}
            isLoading={isLoading}
            appearance="primary"
            iconBefore={<AddIcon label="Benutzer anlegen" />}
          >
            Benutzer anlegen
          </LoadingButton>
        }
      />
      <UserTable users={users} onShowDetails={() => null} />
    </Page>
  )
}

export default UsersScreen
