// @ts-nocheck
import React, { Fragment, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import InlineEdit from '@atlaskit/inline-edit'
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search'
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down'
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up'
import AddIcon from '@atlaskit/icon/glyph/add'
import TextField from '@atlaskit/textfield'
import Button, { ButtonGroup } from '@atlaskit/button'
import { Flex } from 'reflexbox'
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
} from 'react-table'

import {
  formatWagonStatus,
  formatDateTime,
  formatNumber,
  cleanNumber,
} from 'utils/format.utils'
import modules from 'modules'
import { Wagon } from './entrainment.types'
import CreateDailyReportModal from './create-daily-report.modal'

const ReadViewContainer = styled.div`
  text-decoration: underline;
  cursor: pointer;
`

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  /* const count = preGlobalFilteredRows.length; */
  /* const count = 0; */
  const [value, setValue] = useState(globalFilter);

  const dispatch = useDispatch()

  /* const pagination = useSelector(modules.entrainment.selectors.selectPagination) */
  /* console.log(pagination) */

  const searchWagons = (searchTerm) => {
    dispatch(modules.entrainment.actions.searchWagons(searchTerm))
  }

  const onChange = useAsyncDebounce((value) => {
    if(value.length === 0) {
      dispatch(modules.entrainment.actions.fetchWagons(1,30))
    } else {
      searchWagons(value);
    }
    setValue(value);
  }, 200);

  return (
    <TextField
      isCompact
      width={300}
      placeholder={`Einträge durchsuchen`} // placeholder={`${count} Einträge durchsuchen`}
      elemBeforeInput={<EditorSearchIcon label="Suchen" />}
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    />
  );
};

/* const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: {
  preGlobalFilteredRows: any
  globalFilter: any
  setGlobalFilter: any
}) => {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <TextField
      isCompact
      width={300}
      placeholder={`${count} Einträge durchsuchen`}
      elemBeforeInput={<EditorSearchIcon label="Suchen" />}
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
    />
  )
} */

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  },
)

interface Props {
  wagons: Wagon[]
  /* pagination: any */
  onShowDetails: (wagonId: string) => void
}

interface TableProps {
  options: {
    columns: any
    data: any
  }
}

const Table = ({ columns, data, onSelectionUpdate }: TableProps) => {
  const dispatch = useDispatch()

  const pagination = useSelector(modules.entrainment.selectors.selectPagination)
  /* console.log(pagination) */

  const fetchNewPage = (direction) => {
    dispatch(modules.entrainment.actions.fetchWagons(pagination.page + direction, 30))
  }

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,

    canPreviousPage,
    canNextPage,
    /* pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage, */
    state,

    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageIndex: pagination.page - 1,
        pageSize: pagination.limit,
        sortBy: [{ id: 'createdAt', desc: true }],
      },
      manualPagination: true,
      pageCount: pagination.maxPages
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    },
  )

  const { pageIndex, globalFilter, selectedRowIds } = state

  const [
    isCreateDailyReportModalOpen,
    setIsCreateDailyReportModalOpen,
  ] = useState(false)

  const onSubmit = (dayOfReport: string) => {
    dispatch(
      modules.dailyReports.actions.createDailyReport({
        dayOfReport,
        wagons: selectedFlatRows.map((row) => row.original.wagonId),
      }),
    )
  }

  return (
    <Fragment>
      <CreateDailyReportModal
        isOpen={isCreateDailyReportModalOpen}
        onClose={() => setIsCreateDailyReportModalOpen(false)}
        onSubmit={onSubmit}
      />
      <Flex justifyContent="flex-end">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        &nbsp;
        <Button
          appearance="primary"
          isDisabled={!Object.keys(selectedRowIds).length}
          onClick={() => setIsCreateDailyReportModalOpen(true)}
          iconBefore={<AddIcon label="Tagesbericht anlegen" />}
        >
          Tagesbericht anlegen
        </Button>
      </Flex>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <Flex>
                    {column.render('Header')}

                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ChevronDownIcon label="Absteigend sortieren" />
                      ) : (
                        <ChevronUpIcon label="Aufsteigend sortieren" />
                      )
                    ) : (
                      /**
                       * Placeholder for sort icons. Was this empty or null,
                       * adding an icon would always shift the whole table
                       * due to readjustments in header width and height.
                       *
                       * By using a placeholder with the same size as the icons,
                       * we can completely get rid of this effect.
                       */
                      <div style={{ height: 24, width: 24 }}></div>
                    )}
                  </Flex>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((page: any) => {
            prepareRow(page)
            return (
              <tr {...page.getRowProps()}>
                {page.cells.map((cell: any) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      * wurde noch nicht in einem Tagesbericht erfasst
      <br />
      <br />
      <Flex justifyContent="space-between">
        <ButtonGroup appearance="default">
          {/* <Button onClick={() => gotoPage(0)} isDisabled={!canPreviousPage}>
            {'<<'} Erste
          </Button> */}
          <Button onClick={() => {/* previousPage(); */ fetchNewPage(-1)}} isDisabled={!canPreviousPage}>
            {'<'} Vorherige
          </Button>
          <Button onClick={() => { /* nextPage(); */ fetchNewPage(1)}} isDisabled={!canNextPage}>
            Nächste {'>'}
          </Button>
          {/* <Button
            onClick={() => gotoPage(pagination.page - 1)}
            isDisabled={!canNextPage}
          >
            Letzte {'>>'}
          </Button> */}
        </ButtonGroup>
        <span>
          Seite{' '}
          <strong>
            { pagination.page } von { pagination.maxPages }
          </strong>{' '}
        </span>
      </Flex>
      <br />
      <br />
    </Fragment>
  )
}

const WagonTable: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { wagons, /* pagination, */ onShowDetails, onSelectionUpdate } = props

  const dispatch = useDispatch()

  const columns = useMemo(
    () => [
      {
        Header: 'Wagonnummer',
        accessor: 'title',
      },
      {
        Header: 'Monatsnummer',
        Cell: ({
          row: {
            original: { wagonId, monthNo },
          },
        }) => (
          <InlineEdit
            defaultValue={monthNo}
            editView={(fieldProps) => <TextField {...fieldProps} autoFocus />}
            readView={() => (
              <ReadViewContainer>
                {monthNo || 'nicht angegeben'}
              </ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              if (newValue === monthNo) return

              dispatch(
                modules.entrainment.actions.setMonthNo(wagonId, newValue),
              )
            }}
          />
        ),
        accessor: 'monthNo',
      },
      {
        Header: 'Wagoneingang',
        accessor: 'createdAt',
        Cell: ({ value }) => value.split('###')[1],
      },
      {
        Header: 'Wagonausgang',
        accessor: 'collectedAt',
        Cell: ({ value }) => (value ? value.split('###')[1] : null),
      },
      {
        Header: 'Empfänger',
        accessor: 'receiver',
      },
      {
        Header: 'Bahngewicht',
        Cell: ({
          row: {
            original: { wagonId, officialWeight },
          },
        }) => (
          <InlineEdit
            defaultValue={officialWeight}
            editView={(fieldProps) => (
              <MaskedInput
                mask={createNumberMask({
                  prefix: '',
                  suffix: ' kg',
                  thousandsSeparatorSymbol: '.',
                })}
                {...fieldProps}
                render={(ref, props) => (
                  <TextField
                    ref={ref}
                    {...props}
                    autoFocus
                    autoComplete="off"
                    placeholder="In Kilogramm angeben"
                  />
                )}
              />
            )}
            readView={() => (
              <ReadViewContainer>
                {officialWeight
                  ? formatNumber(officialWeight) + ' kg'
                  : 'nicht angegeben'}
              </ReadViewContainer>
            )}
            onConfirm={(newValue) => {
              if (newValue === officialWeight) return

              dispatch(
                modules.entrainment.actions.setOfficialWeight(
                  wagonId,
                  cleanNumber(newValue),
                ),
              )
            }}
          />
        ),
        accessor: 'officialWeight',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: '',
        accessor: 'wagonId',
        Cell: ({
          row: {
            original: { wagonId },
          },
        }) => (
          <Flex justifyContent="flex-end">
            <Button appearance="link" onClick={() => onShowDetails(wagonId)}>
              Detailansicht
            </Button>
          </Flex>
        ),
      },
    ],
    [dispatch, onShowDetails],
  )

  const rows = wagons.map((wagon) => {
    const {
      _id,
      wagonNo,
      isReported,
      monthNo,
      officialWeight,
      status,
      collectedAt,
      createdAt,
      receiver,
    } = wagon

    const title = `${wagonNo}${isReported ? '' : ' *'}`

    return {
      wagonId: _id,
      title,
      isReported,
      receiver: receiver?.name,
      /**
       * Search and sort depend on the accessor key. This means, we can either base
       * this on the ISO string or the formatted string, by passing either of them.
       * Here we want to search by the formatted string, but sort by the ISO string,
       * so we pass both as delimited string and display the formatted part only,
       * while still being able to sort correctly.
       */
      createdAt: `${createdAt}###${formatDateTime(createdAt)}`,
      collectedAt: collectedAt
        ? `${collectedAt}###${formatDateTime(collectedAt)}`
        : null,
      status: formatWagonStatus(status),
      monthNo,
      officialWeight,
    }
  })

  return (
    <Table
      columns={columns}
      data={rows}
      onSelectionUpdate={onSelectionUpdate}
    />
  )
}

export default WagonTable
