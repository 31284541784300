import { Reducer, Dispatch } from 'redux'
import { createSelector } from 'reselect'

import { AppState, GenericThunk } from 'typescript/common.types'
import * as api from './ftp-documents.service'
import * as types from './ftp-documents.types'
import {
  State,
  ActionTypes,
  Action,
} from './ftp-documents.types'

/**
 * Initial State
 */
const initialState: State = {
  data: [],
  isLoading: false,
}

/**
 * Actions & Thunks
 */
const saveList = (data: [], shouldReplace: boolean): Action => ({
  type: ActionTypes.SAVE_LIST,
  meta: { shouldReplace },
  payload: { data },
})
const ListDicPending = (): Action => ({
  type: ActionTypes.LIST_DIC_PENDING,
})
const ListDicError = (error: Error): Action => ({
  type: ActionTypes.LIST_DIC_ERROR,
  error,
})
const ListDicSuccess = (): Action => ({
  type: ActionTypes.LIST_DIC_SUCCESS,
})

const ListDic = (path: string): GenericThunk<Promise<any>> => { 
  return async (dispatch: Dispatch): Promise<any> => { 
    dispatch(ListDicPending())

    try {
      const { data } = await api.listDirectory(path)
      
      dispatch(saveList(data, true))
      dispatch(ListDicSuccess())

      return data
    } catch (error) {
      if (error instanceof Error) {
        dispatch(ListDicError(error))
      }
    }

    return []
  }
}

/**
 * Selectors
 */
const selectIsLoading = (state: AppState): boolean =>
  state.ftp.isLoading

const selectList = (state: AppState): [] =>
  state.ftp.data

/**
 * Reducer
 */
const reducer: Reducer<State, Action> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case ActionTypes.LIST_DIC_PENDING:
      return { ...state, isLoading: true }

    case ActionTypes.LIST_DIC_ERROR:
      return { ...state, isLoading: false }

    case ActionTypes.SAVE_LIST:
      const { data } = action.payload

      return {
        ...state,
        isLoading: false,
        data: data
      }

    default:
      return state
  }
}

export default {
  types,
  actions: {
    saveList,
    ListDic,
    ListDicPending,
    ListDicError,
    ListDicSuccess,
  },
  selectors: {
    selectIsLoading,
    selectList,
  },
  reducer,
}
