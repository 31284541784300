export enum ActionTypes {
  CREATE_CONTAINER_PENDING = 'CONTAINERS/CREATE_CONTAINER_PENDING',
  CREATE_CONTAINER_ERROR = 'CONTAINERS/CREATE_CONTAINER_ERROR',
  CREATE_CONTAINER_SUCCESS = 'CONTAINERS/CREATE_CONTAINER_SUCCESS',
  EDIT_CONTAINER_PENDING = 'CONTAINERS/EDIT_CONTAINER_PENDING',
  EDIT_CONTAINER_ERROR = 'CONTAINERS/EDIT_CONTAINER_ERROR',
  EDIT_CONTAINER_SUCCESS = 'CONTAINERS/EDIT_CONTAINER_SUCCESS',
  FETCH_CONTAINERS_PENDING = 'CONTAINERS/FETCH_CONTAINERS_PENDING',
  FETCH_CONTAINERS_ERROR = 'CONTAINERS/FETCH_CONTAINERS_ERROR',
  FETCH_CONTAINERS_SUCCESS = 'CONTAINERS/FETCH_CONTAINERS_SUCCESS',
  DELETE_CONTAINERS_PENDING = 'CONTAINERS/DELETE_CONTAINERS_PENDING',
  DELETE_CONTAINERS_ERROR = 'CONTAINERS/DELETE_CONTAINERS_ERROR',
  DELETE_CONTAINERS_SUCCESS = 'CONTAINERS/DELETE_CONTAINERS_SUCCESS',
  SAVE_CONTAINERS = 'CONTAINERS/SAVE_CONTAINERS',
}

/**
 * Container types
 */
export enum ContainerType {
  Container = 'container',
  Skip = 'skip',
}

/**
 * Container
 */
export interface Container {
  _id: string
  containerNr: string
  type: ContainerType,

  /* containerClass: string, */
  dateBuilt: Date,
  totalWeight: number,
  containerLenght: number,
  containerHeight: number,
  volume: number,
  factoryNr: string,
  selfWeight: number,
  containerWidth: number,
  vDimension: number,

  validation: Date
  createdAt: Date
  updatedAt: Date
}

export interface State {
  isLoading: boolean
  isLoadingEdit: boolean
  containers: {
    byId: {
      [id: string]: Container
    }
    allIds: string[]
  }
}

export interface CreateContainerParameters {
  containerNr: string
  type: ContainerType
  dateBuilt: Date,
  totalWeight: number,
  containerLenght: number,
  containerHeight: number,
  volume: number,
  factoryNr: string,
  selfWeight: number,
  containerWidth: number,
  vDimension: number,

  validation: Date
}

export interface CreateContainerPendingAction {
  type: ActionTypes.CREATE_CONTAINER_PENDING
}

export interface CreateContainerErrorAction {
  type: ActionTypes.CREATE_CONTAINER_ERROR
  error: Error
}

export interface CreateContainerSuccessAction {
  type: ActionTypes.CREATE_CONTAINER_SUCCESS
}

export interface EditContainerPendingAction {
  type: ActionTypes.EDIT_CONTAINER_PENDING
}

export interface EditContainerErrorAction {
  type: ActionTypes.EDIT_CONTAINER_ERROR
  error: Error
}

export interface EditContainerSuccessAction {
  type: ActionTypes.EDIT_CONTAINER_SUCCESS
}

export interface FetchContainersPendingAction {
  type: ActionTypes.FETCH_CONTAINERS_PENDING
}

export interface FetchContainersErrorAction {
  type: ActionTypes.FETCH_CONTAINERS_ERROR
  error: Error
}

export interface FetchContainersSuccessAction {
  type: ActionTypes.FETCH_CONTAINERS_SUCCESS
}

export interface DeleteContainersPendingAction {
  type: ActionTypes.DELETE_CONTAINERS_PENDING
}

export interface DeleteContainersErrorAction {
  type: ActionTypes.DELETE_CONTAINERS_ERROR
  error: Error
}

export interface DeleteContainersSuccessAction {
  type: ActionTypes.DELETE_CONTAINERS_SUCCESS
  payload: {
    containerId: string
  }
}

export interface SaveContainersAction {
  type: ActionTypes.SAVE_CONTAINERS
  meta: {
    shouldReplace: boolean
  }
  payload: {
    containers: Container[]
  }
}

export type Action =
  | CreateContainerPendingAction
  | CreateContainerErrorAction
  | CreateContainerSuccessAction
  | EditContainerPendingAction
  | EditContainerErrorAction
  | EditContainerSuccessAction
  | FetchContainersPendingAction
  | FetchContainersErrorAction
  | FetchContainersSuccessAction
  | DeleteContainersPendingAction
  | DeleteContainersErrorAction
  | DeleteContainersSuccessAction
  | SaveContainersAction