import { Reducer, Dispatch } from 'redux'
import { createSelector } from 'reselect'

import { AppState, GenericThunk } from 'typescript/common.types'
import { dailyReportSchema } from './daily-reports.schemas'
import * as api from './daily-reports.service'
import * as types from './daily-reports.types'
import {
  State,
  DailyReport,
  ActionTypes,
  Action,
  CreateDailyReportParameters,
} from './daily-reports.types'

/**
 * Initial State
 */
const initialState: State = {
  dailyReports: {
    byId: {},
    allIds: [],
  },
  isLoading: false,
}

/**
 * Actions & Thunks
 */
const createDailyReportPending = (): Action => ({
  type: ActionTypes.CREATE_DAILY_REPORT_PENDING,
})
const createDailyReportError = (error: Error): Action => ({
  type: ActionTypes.CREATE_DAILY_REPORT_ERROR,
  error,
})
const createDailyReportSuccess = (): Action => ({
  type: ActionTypes.CREATE_DAILY_REPORT_SUCCESS,
})
const saveDailyReports = (
  dailyReports: DailyReport[],
  shouldReplace: boolean,
): Action => ({
  type: ActionTypes.SAVE_DAILY_REPORTS,
  meta: { shouldReplace },
  payload: { dailyReports },
})

const createDailyReport = (
  newDailyReport: CreateDailyReportParameters,
): GenericThunk<Promise<DailyReport | null>> => {
  return async (dispatch: Dispatch): Promise<DailyReport | null> => {
    dispatch(createDailyReportPending())

    try {
      const { data: dailyReport } = await api.createDailyReport(newDailyReport)

      dailyReportSchema.validateSync(dailyReport)
      dispatch(saveDailyReports([dailyReport], false))
      dispatch(createDailyReportSuccess())

      return dailyReport
    } catch (error) {
      if (error instanceof Error) {
        dispatch(createDailyReportError(error))
      }
    }
    return null
  }
}

const fetchDailyReportsPending = (): Action => ({
  type: ActionTypes.FETCH_DAILY_REPORTS_PENDING,
})
const fetchDailyReportsError = (error: Error): Action => ({
  type: ActionTypes.FETCH_DAILY_REPORTS_ERROR,
  error,
})
const fetchDailyReportsSuccess = (): Action => ({
  type: ActionTypes.FETCH_DAILY_REPORTS_SUCCESS,
})

const fetchDailyReports = (): GenericThunk<Promise<DailyReport[]>> => {
  return async (dispatch: Dispatch): Promise<DailyReport[]> => {
    dispatch(fetchDailyReportsPending())

    try {
      const { data: dailyReports } = await api.fetchDailyReports()

      dailyReports.forEach((dailyReport) =>
        dailyReportSchema.validateSync(dailyReport),
      )

      dispatch(saveDailyReports(dailyReports, true))
      dispatch(fetchDailyReportsSuccess())

      return dailyReports
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchDailyReportsError(error))
      }
    }
    return []
  }
}

const deleteDailyReportPending = (): Action => ({
  type: ActionTypes.DELETE_DAILY_REPORT_PENDING,
})
const deleteDailyReportError = (error: Error): Action => ({
  type: ActionTypes.DELETE_DAILY_REPORT_ERROR,
  error,
})
const deleteDailyReportSuccess = (dailyReportId: string): Action => ({
  type: ActionTypes.DELETE_DAILY_REPORT_SUCCESS,
  payload: { dailyReportId },
})

const deleteDailyReport = (
  dailyReportId: string,
): GenericThunk<Promise<boolean>> => {
  return async (dispatch: Dispatch): Promise<boolean> => {
    dispatch(deleteDailyReportPending())

    try {
      const { data: response } = await api.deleteDailyReport(dailyReportId)

      dispatch(deleteDailyReportSuccess(dailyReportId))

      return response
    } catch (error) {
      if (error instanceof Error) {
        dispatch(deleteDailyReportError(error))
      }
    }
    return false
  }
}

/**
 * Selectors
 */
const selectIsLoading = (state: AppState): boolean =>
  state.dailyReports.isLoading

const selectDailyReports = (state: AppState): DailyReport[] =>
  Object.values(state.dailyReports.dailyReports.byId)

const selectDailyReportById = (id: string) =>
  createSelector(selectDailyReports, (dailyReports) => {
    const [dailyReport] = dailyReports.filter(
      (dailyReport) => dailyReport._id === id,
    )

    return dailyReport
  })

/**
 * Reducer
 */
const reducer: Reducer<State, Action> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case ActionTypes.CREATE_DAILY_REPORT_PENDING:
    case ActionTypes.FETCH_DAILY_REPORTS_PENDING:
    case ActionTypes.DELETE_DAILY_REPORT_PENDING:
      return { ...state, isLoading: true }

    case ActionTypes.CREATE_DAILY_REPORT_ERROR:
    case ActionTypes.FETCH_DAILY_REPORTS_ERROR:
    case ActionTypes.DELETE_DAILY_REPORT_ERROR:
      return { ...state, isLoading: false }

    case ActionTypes.DELETE_DAILY_REPORT_SUCCESS: {
      const { dailyReportId } = action.payload
      const newById = { ...state.dailyReports.byId }
      delete newById[dailyReportId]

      return {
        ...state,
        dailyReports: {
          byId: newById,
          allIds: Object.keys(newById),
        },
        isLoading: false,
      }
    }

    case ActionTypes.SAVE_DAILY_REPORTS: {
      const { dailyReports } = action.payload

      const base = action.meta.shouldReplace
        ? {}
        : { ...state.dailyReports.byId }

      const newById = dailyReports.reduce((acc, dailyReport) => {
        return { ...acc, [dailyReport._id]: dailyReport }
      }, base)

      return {
        ...state,
        isLoading: false,
        dailyReports: {
          byId: newById,
          allIds: Object.keys(newById),
        },
      }
    }

    default:
      return state
  }
}

export default {
  types,
  actions: {
    createDailyReport,
    createDailyReportPending,
    createDailyReportError,
    createDailyReportSuccess,
    saveDailyReports,
    fetchDailyReports,
    fetchDailyReportsPending,
    fetchDailyReportsError,
    fetchDailyReportsSuccess,
    deleteDailyReport,
    deleteDailyReportPending,
    deleteDailyReportError,
    deleteDailyReportSuccess,
  },
  selectors: {
    selectIsLoading,
    selectDailyReportById,
    selectDailyReports,
  },
  reducer,
}
