export enum ActionTypes {
  CREATE_CONTACT_PENDING = 'CONTACTS/CREATE_CONTACT_PENDING',
  CREATE_CONTACT_ERROR = 'CONTACTS/CREATE_CONTACT_ERROR',
  CREATE_CONTACT_SUCCESS = 'CONTACTS/CREATE_CONTACT_SUCCESS',
  FETCH_CONTACTS_PENDING = 'CONTACTS/FETCH_CONTACTS_PENDING',
  FETCH_CONTACTS_ERROR = 'CONTACTS/FETCH_CONTACTS_ERROR',
  FETCH_CONTACTS_SUCCESS = 'CONTACTS/FETCH_CONTACTS_SUCCESS',
  DELETE_CONTACT_PENDING = 'CONTACTS/DELETE_CONTACT_PENDING',
  DELETE_CONTACT_ERROR = 'CONTACTS/DELETE_CONTACT_ERROR',
  DELETE_CONTACT_SUCCESS = 'CONTACTS/DELETE_CONTACT_SUCCESS',
  SAVE_CONTACTS = 'CONTACTS/SAVE_CONTACTS',
}

/**
 * Contact
 */
export interface Contact {
  /**
   * Contact ID generated by the databse
   */
  _id: string

  /**
   * Contact name
   */
  name: string

  /**
   * Contact email address
   * Information and data will be sent to this address
   */
  email: string

  /**
   * Date and time the contact was created
   */
  createdAt: Date

  /**
   * Date and time the contact was last updated
   */
  updatedAt: Date
}

export interface State {
  isLoading: boolean
  contacts: {
    byId: {
      [id: string]: Contact
    }
    allIds: string[]
  }
}

export interface CreateContactParameters {
  name: string
  email: string
}

export interface CreateContactPendingAction {
  type: ActionTypes.CREATE_CONTACT_PENDING
}

export interface CreateContactErrorAction {
  type: ActionTypes.CREATE_CONTACT_ERROR
  error: Error
}

export interface CreateContactSuccessAction {
  type: ActionTypes.CREATE_CONTACT_SUCCESS
}

export interface FetchContactsPendingAction {
  type: ActionTypes.FETCH_CONTACTS_PENDING
}

export interface FetchContactsErrorAction {
  type: ActionTypes.FETCH_CONTACTS_ERROR
  error: Error
}

export interface FetchContactsSuccessAction {
  type: ActionTypes.FETCH_CONTACTS_SUCCESS
}

export interface DeleteContactPendingAction {
  type: ActionTypes.DELETE_CONTACT_PENDING
}

export interface DeleteContactErrorAction {
  type: ActionTypes.DELETE_CONTACT_ERROR
  error: Error
}

export interface DeleteContactSuccessAction {
  type: ActionTypes.DELETE_CONTACT_SUCCESS
  payload: {
    contactId: string
  }
}

export interface SaveContactsAction {
  type: ActionTypes.SAVE_CONTACTS
  meta: {
    shouldReplace: boolean
  }
  payload: {
    contacts: Contact[]
  }
}

export type Action =
  | CreateContactPendingAction
  | CreateContactErrorAction
  | CreateContactSuccessAction
  | FetchContactsPendingAction
  | FetchContactsErrorAction
  | FetchContactsSuccessAction
  | DeleteContactPendingAction
  | DeleteContactErrorAction
  | DeleteContactSuccessAction
  | SaveContactsAction
