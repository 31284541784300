import React from 'react'
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog'
import Button from '@atlaskit/button'

export interface ConfirmationOptions {
  title?: string
  description?: string
}

interface Props extends ConfirmationOptions {
  isModalOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

const DamagedModal: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { isModalOpen, onClose, onConfirm, title, description } = props

  return (
    <ModalTransition>
      {isModalOpen && (
        <Modal onClose={onClose} shouldCloseOnOverlayClick={false}>
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
          </ModalHeader>

          <ModalBody>{description}</ModalBody>

          <ModalFooter>
            <Button appearance="primary" onClick={onConfirm} autoFocus>
              Bestätigen
            </Button>
            <Button appearance="subtle" onClick={onClose}>
              Schließen
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  )
}

export default DamagedModal
