export enum ActionTypes {
  CREATE_USER_PENDING = 'USERS/CREATE_USER_PENDING',
  CREATE_USER_ERROR = 'USERS/CREATE_USER_ERROR',
  CREATE_USER_SUCCESS = 'USERS/CREATE_USER_SUCCESS',
  EDIT_USER_PENDING = 'USERS/EDIT_USER_PENDING',
  EDIT_USER_ERROR = 'USERS/EDIT_USER_ERROR',
  EDIT_USER_SUCCESS = 'USERS/EDIT_USER_SUCCESS',
  FETCH_USERS_PENDING = 'USERS/FETCH_USERS_PENDING',
  FETCH_USERS_ERROR = 'USERS/FETCH_USERS_ERROR',
  FETCH_USERS_SUCCESS = 'USERS/FETCH_USERS_SUCCESS',
  DELETE_USER_PENDING = 'USERS/DELETE_USER_PENDING',
  DELETE_USER_ERROR = 'USERS/DELETE_USER_ERROR',
  DELETE_USER_SUCCESS = 'USERS/DELETE_USER_SUCCESS',
  RESET_PASSWORD_PENDING = 'USERS/RESET_PASSWORD_PENDING',
  RESET_PASSWORD_ERROR = 'USERS/RESET_PASSWORD_ERROR',
  RESET_PASSWORD_SUCCESS = 'USERS/RESET_PASSWORD_SUCCESS',
  SAVE_USERS = 'USERS/SAVE_USERS',
}
/**
 * UserRole
 */
export enum UserRole {
  /**
   * Superadmins have more access than admins.
   * Should only be necessary to make use of superadmin accounts,
   * when admins messed up.
   */
  Superadmin = 'superadmin',

  /**
   * Admins can see more and has more control over data than other users.
   */
  Admin = 'admin',

  /**
   * Employees can only see and do what is necessary to do their jobs.
   */
  Employee = 'employee',
}

/**
 * Permissions
 */
export enum Permissions {
  UsersViewPermission = 'usersViewPermission',
  VehiclesViewPermission = 'vehiclesViewPermission',
  EntrainmentViewPermission = 'entrainmentViewPermission',
  ControllingViewPermission = 'controllingViewPermission',
  VehicleViewPermission = 'vehicleViewPermission',
  ExcavatorViewPermission = 'excavatorViewPermission',
  ContainersViewPermission = 'containersViewPermission',
  ContainerViewPermission = 'containerViewPermission',
  InstrumentsViewPermission = 'instrumentsViewPermission',
}

/**
 * User
 */
export interface User {
  /**
   * User ID generated by the databse
   */
  _id: string

  /**
   * Username is part of the login credentials
   */
  username: string

  /**
   * User role for RBAC and feature selection
   */
  role: UserRole

  /**
   * User role for RBAC and feature selection
   */
  permissions: Permissions

  /**
   * User first name
   */
  firstName: string

  /**
   * User last name
   */
  lastName: string

  /**
   * Date and time the user was created
   */
  createdAt: Date

  /**
   * Date and time the user was last updated
   */
  updatedAt: Date
}

export interface State {
  isLoading: boolean
  isLoadingEdit: boolean
  users: {
    byId: {
      [id: string]: User
    }
    allIds: string[]
  }
}

export interface CreateUserParameters {
  username: string
  firstName: string
  lastName: string
  role: UserRole
  permissions: Object
  password: string
}

export interface ResetPasswordParameters {
  userId: string
  newPassword: string
}

export interface CreateUserPendingAction {
  type: ActionTypes.CREATE_USER_PENDING
}

export interface CreateUserErrorAction {
  type: ActionTypes.CREATE_USER_ERROR
  error: Error
}

export interface CreateUserSuccessAction {
  type: ActionTypes.CREATE_USER_SUCCESS
}

export interface EditUserPendingAction {
  type: ActionTypes.EDIT_USER_PENDING
}

export interface EditUserErrorAction {
  type: ActionTypes.EDIT_USER_ERROR
  error: Error
}

export interface EditUserSuccessAction {
  type: ActionTypes.EDIT_USER_SUCCESS
}

export interface FetchUsersPendingAction {
  type: ActionTypes.FETCH_USERS_PENDING
}

export interface FetchUsersErrorAction {
  type: ActionTypes.FETCH_USERS_ERROR
  error: Error
}

export interface FetchUsersSuccessAction {
  type: ActionTypes.FETCH_USERS_SUCCESS
}

export interface DeleteUserPendingAction {
  type: ActionTypes.DELETE_USER_PENDING
}

export interface DeleteUserErrorAction {
  type: ActionTypes.DELETE_USER_ERROR
  error: Error
}

export interface DeleteUserSuccessAction {
  type: ActionTypes.DELETE_USER_SUCCESS
  payload: {
    userId: string
  }
}

export interface ResetPasswordPendingAction {
  type: ActionTypes.RESET_PASSWORD_PENDING
}

export interface ResetPasswordErrorAction {
  type: ActionTypes.RESET_PASSWORD_ERROR
  error: Error
}

export interface ResetPasswordSuccessAction {
  type: ActionTypes.RESET_PASSWORD_SUCCESS
}

export interface SaveUsersAction {
  type: ActionTypes.SAVE_USERS
  meta: {
    shouldReplace: boolean
  }
  payload: {
    users: User[]
  }
}

export type Action =
  | CreateUserPendingAction
  | CreateUserErrorAction
  | CreateUserSuccessAction
  | EditUserPendingAction
  | EditUserErrorAction
  | EditUserSuccessAction
  | FetchUsersPendingAction
  | FetchUsersErrorAction
  | FetchUsersSuccessAction
  | DeleteUserPendingAction
  | DeleteUserErrorAction
  | DeleteUserSuccessAction
  | ResetPasswordPendingAction
  | ResetPasswordErrorAction
  | ResetPasswordSuccessAction
  | SaveUsersAction