import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag'
import SuccessIcon from '@atlaskit/icon/glyph/check-circle'
import ErrorIcon from '@atlaskit/icon/glyph/error'
import WarningIcon from '@atlaskit/icon/glyph/warning'
import InfoIcon from '@atlaskit/icon/glyph/info'

import modules from 'modules'
import colors from 'config/colors.config'
import { FlagAppearance } from './core.types'

const FlagContainer: React.FunctionComponent<{}> = (): JSX.Element | null => {
  const dispatch = useDispatch()
  const flagConfig = useSelector(modules.core.selectors.selectFlagConfig)
  const hideFlag = () => dispatch(modules.core.actions.hideFlagAction())

  if (!flagConfig) return null

  const { title, description, appearance } = flagConfig

  let icon = <InfoIcon label="Info" />
  if (appearance === FlagAppearance.Success) {
    icon = <SuccessIcon label="Success" primaryColor={colors.cdGreen} />
  }
  if (appearance === FlagAppearance.Warning) {
    icon = <WarningIcon label="Warning" primaryColor={colors.warning} />
  }
  if (appearance === FlagAppearance.Error) {
    icon = <ErrorIcon label="Error" primaryColor={colors.critical} />
  }

  return (
    <FlagGroup onDismissed={hideFlag}>
      <AutoDismissFlag
        icon={icon}
        appearance="normal"
        description={description}
        id="1"
        key="1"
        title={title}
      />
    </FlagGroup>
  )
}

export default FlagContainer
