import { User } from 'modules/users/users.types'

export enum ActionTypes {
  CREATE_CHECKLIST_PENDING = 'LOADING/CREATE_CHECKLIST_PENDING',
  CREATE_CHECKLIST_ERROR = 'LOADING/CREATE_CHECKLIST_ERROR',
  CREATE_CHECKLIST_SUCCESS = 'LOADING/CREATE_CHECKLIST_SUCCESS',
  FETCH_CHECKLISTS_PENDING = 'LOADING/FETCH_CHECKLISTS_PENDING',
  FETCH_CHECKLISTS_ERROR = 'LOADING/FETCH_CHECKLISTS_ERROR',
  FETCH_CHECKLISTS_SUCCESS = 'LOADING/FETCH_CHECKLISTS_SUCCESS',
  UPDATE_CHECKLIST_PENDING = 'LOADING/UPDATE_CHECKLIST_PENDING',
  UPDATE_CHECKLIST_ERROR = 'LOADING/UPDATE_CHECKLIST_ERROR',
  UPDATE_CHECKLIST_SUCCESS = 'LOADING/UPDATE_CHECKLIST_SUCCESS',
  SAVE_CHECKLISTS = 'LOADING/SAVE_CHECKLISTS',
  DELETE_CHECKLISTS_PENDING = 'LOADING/DELETE_CHECKLISTS_PENDING',
  DELETE_CHECKLISTS_ERROR = 'LOADING/DELETE_CHECKLISTS_ERROR',
  DELETE_CHECKLISTS_SUCCESS = 'LOADING/DELETE_CHECKLISTS_SUCCESS',
}

export enum ChecklistTypes {
  Vehicle = 'Vehicle',
  Excavator = 'Excavator',
  Container = 'Container',
  Skip = 'Skip'
}

export enum Status {
  Started = 'started',
  Finished = 'finished',
  Cancelled = 'cancelled',
}

export enum Filter {
  All = 'all',
  Started = 'started',
  Finished = 'finished',
  Cancelled = 'cancelled',
}

export interface Checklist {
  _id: string
  checklistType: ChecklistTypes
  checklistData: []
  status: Status,
  vehiclePlate: string,
  containerNr: string,
  checkResult: boolean,
  checklistMessage: string,
  createdAt: Date
  updatedAt: Date
  finishedAt?: Date
  createdBy: User | null
}
export interface ChecklistUpdates {
  status: Status,
  vehiclePlate: string,
  containerNr: string,
  checklistMessage: string,
}

export interface State {
  isLoading: boolean,
  isLoadingDelete: boolean,
  checklists: {
    byId: {
      [id: string]: Checklist
    }
    allIds: string[]
  },
}

export interface CreateChecklistRequestData {
  checklistType: ChecklistTypes
  checklistData: Object
}

export interface CreateChecklistPendingAction {
  type: ActionTypes.CREATE_CHECKLIST_PENDING
}

export interface CreateChecklistErrorAction {
  type: ActionTypes.CREATE_CHECKLIST_ERROR
  error: Error
}

export interface CreateChecklistSuccessAction {
  type: ActionTypes.CREATE_CHECKLIST_SUCCESS
}

export interface FetchChecklistsPendingAction {
  type: ActionTypes.FETCH_CHECKLISTS_PENDING
}

export interface FetchChecklistsErrorAction {
  type: ActionTypes.FETCH_CHECKLISTS_ERROR
  error: Error
}

export interface FetchChecklistsSuccessAction {
  type: ActionTypes.FETCH_CHECKLISTS_SUCCESS
}

export interface UpdateChecklistPendingAction {
  type: ActionTypes.UPDATE_CHECKLIST_PENDING
}

export interface UpdateChecklistErrorAction {
  type: ActionTypes.UPDATE_CHECKLIST_ERROR
  error: Error
}

export interface UpdateChecklistSuccessAction {
  type: ActionTypes.UPDATE_CHECKLIST_SUCCESS
}

export interface SaveChecklistsAction {
  type: ActionTypes.SAVE_CHECKLISTS
  meta: {
    shouldReplace: boolean
  }
  payload: {
    checklists: Checklist[]
  }
}

export interface DeleteChecklistsPendingAction {
  type: ActionTypes.DELETE_CHECKLISTS_PENDING
}

export interface DeleteChecklistsErrorAction {
  type: ActionTypes.DELETE_CHECKLISTS_ERROR
  error: Error
}

export interface DeleteChecklistsSuccessAction {
  type: ActionTypes.DELETE_CHECKLISTS_SUCCESS
}

export type Action =
  | CreateChecklistPendingAction
  | CreateChecklistErrorAction
  | CreateChecklistSuccessAction
  | FetchChecklistsPendingAction
  | FetchChecklistsErrorAction
  | FetchChecklistsSuccessAction
  | UpdateChecklistPendingAction
  | UpdateChecklistErrorAction
  | UpdateChecklistSuccessAction
  | SaveChecklistsAction
  | DeleteChecklistsPendingAction
  | DeleteChecklistsErrorAction
  | DeleteChecklistsSuccessAction