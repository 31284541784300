import { Reducer, Dispatch } from 'redux'
import { createSelector } from 'reselect'

import { AppState, GenericThunk } from 'typescript/common.types'
import { vehicleSchema } from './vehicles.schemas'
import * as api from './vehicles.service'
import * as types from './vehicles.types'
import {
  State,
  Vehicle,
  ActionTypes,
  Action,
  CreateVehicleParameters,
} from './vehicles.types'

/**
 * Initial State
 */
const initialState: State = {
  vehicles: {
    byId: {},
    allIds: [],
  },
  isLoading: false,
  isLoadingEdit: false,
}

/**
 * Actions & Thunks
 */
const createVehiclePending = (): Action => ({
  type: ActionTypes.CREATE_VEHICLE_PENDING,
})
const createVehicleError = (error: Error): Action => ({
  type: ActionTypes.CREATE_VEHICLE_ERROR,
  error,
})
const createVehicleSuccess = (): Action => ({
  type: ActionTypes.CREATE_VEHICLE_SUCCESS,
})
const saveVehicles = (vehicles: Vehicle[], shouldReplace: boolean): Action => ({
  type: ActionTypes.SAVE_VEHICLES,
  meta: { shouldReplace },
  payload: { vehicles },
})

const createVehicle = (
  newVehicle: CreateVehicleParameters,
): GenericThunk<Promise<Vehicle | null>> => {
  return async (dispatch: Dispatch): Promise<Vehicle | null> => {
    dispatch(createVehiclePending())

    try {
      const { data: vehicle } = await api.createVehicle(newVehicle)

      vehicleSchema.validateSync(vehicle)
      dispatch(saveVehicles([vehicle], false))
      dispatch(createVehicleSuccess())

      return vehicle
    } catch (error) {
      if (error instanceof Error) {
        dispatch(createVehicleError(error))
      }
    }
    return null
  }
}

const editVehiclePending = (): Action => ({
  type: ActionTypes.EDIT_VEHICLE_PENDING,
})
const editVehicleError = (error: Error): Action => ({
  type: ActionTypes.EDIT_VEHICLE_ERROR,
  error,
})
const editVehicleSuccess = (): Action => ({
  type: ActionTypes.EDIT_VEHICLE_SUCCESS,
})
const editVehicle = (editVehicle: Vehicle): GenericThunk<Promise<Vehicle | null>> => {
  return async (dispatch: Dispatch): Promise<Vehicle | null> => {
    dispatch(editVehiclePending())

    try {
      const { data: vehicle } = await api.editVehicle(editVehicle)

      vehicleSchema.validateSync(vehicle)
      dispatch(saveVehicles([vehicle], true))
      dispatch(editVehicleSuccess())

      return vehicle
    } catch (error) {
      if (error instanceof Error) {
        dispatch(editVehicleError(error))
      }
    }
    return null
  }
}

const fetchVehiclesPending = (): Action => ({
  type: ActionTypes.FETCH_VEHICLES_PENDING,
})
const fetchVehiclesError = (error: Error): Action => ({
  type: ActionTypes.FETCH_VEHICLES_ERROR,
  error,
})
const fetchVehiclesSuccess = (): Action => ({
  type: ActionTypes.FETCH_VEHICLES_SUCCESS,
})

const fetchVehicles = (): GenericThunk<Promise<Vehicle[]>> => {
  return async (dispatch: Dispatch): Promise<Vehicle[]> => {
    dispatch(fetchVehiclesPending())

    try {
      const { data: vehicles } = await api.fetchVehicles()

      vehicles.forEach((vehicle) => vehicleSchema.validateSync(vehicle))

      dispatch(saveVehicles(vehicles, true))
      dispatch(fetchVehiclesSuccess())

      return vehicles
    } catch (error) {
      if (error instanceof Error) {
        dispatch(fetchVehiclesError(error))
      }
    }
    return []
  }
}

const deleteVehiclesPending = (): Action => ({
  type: ActionTypes.DELETE_VEHICLES_PENDING,
})
const deleteVehiclesError = (error: Error): Action => ({
  type: ActionTypes.DELETE_VEHICLES_ERROR,
  error,
})
const deleteVehiclesSuccess = (vehicleId: string): Action => ({
  type: ActionTypes.DELETE_VEHICLES_SUCCESS,
  payload: { vehicleId },
})

const deleteVehicles = (vehicleId: string): GenericThunk<Promise<boolean>> => {
  return async (dispatch: Dispatch): Promise<boolean> => {
    dispatch(deleteVehiclesPending())

    try {
      const { data: response } = await api.deleteVehicle(vehicleId)

      dispatch(deleteVehiclesSuccess(vehicleId))

      return response
    } catch (error) {
      if (error instanceof Error) {
        dispatch(deleteVehiclesError(error))
      }
    }
    return false
  }
}

/**
 * Selectors
 */
const selectIsLoading = (state: AppState): boolean => state.vehicles.isLoading
const selectIsLoadingEdit = (state: AppState): boolean => state.vehicles.isLoadingEdit

const selectVehicles = (state: AppState): Vehicle[] =>
  Object.values(state.vehicles.vehicles.byId)

const selectVehicleById = (id: string) =>
  createSelector(selectVehicles, (vehicles) => {
    const [vehicle] = vehicles.filter((vehicle) => vehicle._id === id)

    return vehicle
  })

/**
 * Reducer
 */
const reducer: Reducer<State, Action> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case ActionTypes.CREATE_VEHICLE_PENDING:
    case ActionTypes.FETCH_VEHICLES_PENDING:
    case ActionTypes.DELETE_VEHICLES_PENDING:
      return { ...state, isLoading: true }

    case ActionTypes.CREATE_VEHICLE_ERROR:
    case ActionTypes.FETCH_VEHICLES_ERROR:
    case ActionTypes.DELETE_VEHICLES_ERROR:
      return { ...state, isLoading: false }

    case ActionTypes.DELETE_VEHICLES_SUCCESS: {
      const { vehicleId } = action.payload
      const newById = { ...state.vehicles.byId }
      delete newById[vehicleId]

      return {
        ...state,
        vehicles: {
          byId: newById,
          allIds: Object.keys(newById),
        },
        isLoading: false,
      }
    }

    case ActionTypes.SAVE_VEHICLES: {
      const { vehicles } = action.payload

      const base = action.meta.shouldReplace ? {} : { ...state.vehicles.byId }

      const newById = vehicles.reduce((acc, vehicle) => {
        return { ...acc, [vehicle._id]: vehicle }
      }, base)

      return {
        ...state,
        isLoading: false,
        vehicles: {
          byId: newById,
          allIds: Object.keys(newById),
        },
      }
    }

    case ActionTypes.EDIT_VEHICLE_PENDING:
      return { ...state, isLoading: true, isLoadingEdit: true }

    case ActionTypes.EDIT_VEHICLE_ERROR:
      return { ...state, isLoading: false, isLoadingEdit: false }

    case ActionTypes.EDIT_VEHICLE_SUCCESS:
      return { ...state, isLoading: false, isLoadingEdit: false }

    default:
      return state
  }
}

export default {
  types,
  actions: {
    createVehicle,
    createVehiclePending,
    createVehicleError,
    createVehicleSuccess,
    editVehicle,
    editVehiclePending,
    editVehicleError,
    editVehicleSuccess,
    saveVehicles,
    fetchVehicles,
    fetchVehiclesPending,
    fetchVehiclesError,
    fetchVehiclesSuccess,
    deleteVehicles,
    deleteVehiclesPending,
    deleteVehiclesError,
    deleteVehiclesSuccess,
  },
  selectors: {
    selectIsLoading,
    selectIsLoadingEdit,
    selectVehicleById,
    selectVehicles,
  },
  reducer,
}
