interface KeyValue {
  [key: string]: string
}

export const WAGON_STATUS: KeyValue = {
  supplied: 'Beladung',
  finished: 'Abholbereit',
  collected: 'Abgeholt',
  deleted: 'Gelöscht',
}

export const WAGON_DAMAGED: KeyValue = {
  unchecked: 'Ungeprüft',
  none: 'Nicht Beschädigt',
  documented: 'Beschädigt',
}

export const USER_ROLE: KeyValue = {
  superadmin: 'Superadmin',
  admin: 'Admin',
  employee: 'Benutzer',
}

export const USER_PERMISSIONS: KeyValue = {
  usersViewPermission: 'Benutzerverwaltung',
  vehiclesViewPermission: 'Fahrzeugverwaltung',
  entrainmentViewPermission: 'Wagonverladung',
  controllingViewPermission: 'Abrechnungsprüfung',
  vehicleViewPermission: 'Fahrzeugprüfung',
  excavatorViewPermission: 'Baggerprüfung',
  containersViewPermission: 'Behälterverwaltung',
  containerViewPermission: 'Behälterprüfung',
  instrumentsViewPermission: 'Prüfmittelverwaltung',
}

export const DATE_TIME_FORMAT = 'DD.MM.YYYY, HH:mm'
export const DATE_FORMAT = 'DD.MM.YYYY'

export const CHECKLIST_STATUS: KeyValue = {
  started: 'In Durchführung',
  finished: 'Abgeschlossen',
  cancelled: 'Abgebrochen',
}
export const CONTAINER_TYPE: KeyValue = {
  container: 'Abrollcontainer',
  skip: 'Absetzmulde',
}
export const CHECKLIST_TYPE: KeyValue = {
  Container: 'Abrollcontainer',
  Skip: 'Absetzmulde',
}
